export const myDataProductLineCodingData = [
    {
        "id": "1",
        "code": "1",
        "name": "Ειδική Γραμμή Παρακρατούμενων Φόρων",
        "developerNotes": "Inactive-For future use",
        "isActive": "true"
    },
    {
        "id": "2",
        "code": "2",
        "name": "Γραμμή Τέλους με ΦΠΑ",
        "developerNotes": "Product Line with VAT ",
        "isActive": "true"
    },
    {
        "id": "3",
        "code": "3",
        "name": "Γραμμή Λοιπών Φόρων με ΦΠΑ",
        "developerNotes": "Product Line for taxes with VAT -Use this for taxes with VAT and when client is NOT from Greece for his VAT amount. More Details on sales-New.",
        "isActive": "true"
    },
    {
        "id": "4",
        "code": "4",
        "name": "Ειδική Γραμμή Χαρτοσήμου ",
        "developerNotes": "Inactive-For future use",
        "isActive": "true"
    },
    {
        "id": "5",
        "code": "5",
        "name": "Ειδική γραμμή κρατήσεων",
        "developerNotes": "Inactive-For future use",
        "isActive": "true"
    },
    {
        "id": "6",
        "code": "6",
        "name": "Δωροεπιταγή",
        "isActive": "true"
    },
    {
        "id": "7",
        "code": "7",
        "name": "Αρνητικό πρόσημο αξιών",
        "isActive": "true"
    }
]