export const characterizationType = [
    {
        "id": "1",
        "code": "E3_106",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Εμπορεύματα"
    },
    {
        "id": "2",
        "code": "E3_205",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Πρώτες ύλες και λοιπά υλικά"
    },
    {
        "id": "3",
        "code": "E3_210",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Προιόντα και παραγωγή σε εξέλιξη"
    },
    {
        "id": "4",
        "code": "E3_305",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Πρώτες ύλες και λοιπά υλικά"
    },
    {
        "id": "5",
        "code": "E3_310",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Προιόντα και παραγωγή σε εξέλιξη"
    },
    {
        "id": "6",
        "code": "E3_318",
        "description": "Ιδιοπαραγωγή παγίων -Αυτοπαραδόσεις- Καταστροφές αποθεμάτων/Έξοδα παραγωγής"
    },
    {
        "id": "7",
        "code": "E3_561_001",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές -Επιτηδευμάτων "
    },
    {
        "id": "8",
        "code": "E3_561_002",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Χονδρικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
    },
    {
        "id": "9",
        "code": "E3_561_003",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Λιανικές - Ιδιωτική Πελατεία "
    },
    {
        "id": "10",
        "code": "E3_561_004",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Λιανικές  βάσει άρθρου 39α παρ 5 του κώδικα ΦΠΑ (Ν2859/2000)"
    },
    {
        "id": "11",
        "code": "E3_561_005",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "12",
        "code": "E3_561_006",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "13",
        "code": "E3_561_007",
        "description": "Πωλήσεις αγαθών και υπηρεσιών Λοιπά"
    },
    {
        "id": "14",
        "code": "E3_562",
        "description": "Λοιπά συνήθη έσοδα"
    },
    {
        "id": "15",
        "code": "E3_563",
        "description": "Πιστωτικοί τόκοι και συναφή έσοδα"
    },
    {
        "id": "16",
        "code": "E3_564",
        "description": "Πιστωτικές συναλλαγματικές διαφορές "
    },
    {
        "id": "17",
        "code": "E3_565",
        "description": "Έσοδα συμμετοχών"
    },
    {
        "id": "18",
        "code": "E3_566",
        "description": "Κέρδη από διάθεση μη κυκλοφορούντων περιουσιακών στοιχείων"
    },
    {
        "id": "19",
        "code": "E3_567",
        "description": "Κέρδη από αναστροφή προβλέψεων και απομειώσεων"
    },
    {
        "id": "20",
        "code": "E3_568",
        "description": "Κέρδη απο επιμέτρηση στην εύλογη αξία "
    },
    {
        "id": "21",
        "code": "E3_570",
        "description": "Ασυνήθη έσοδα και κέρδη"
    },
    {
        "id": "22",
        "code": "E3_595",
        "description": "Έξοδα σε ιδιοπαραγωγή"
    },
    {
        "id": "23",
        "code": "E3_596",
        "description": "Επιδοτήσεις- Επιχορηγήσεις"
    },
    {
        "id": "24",
        "code": "E3_597",
        "description": "Επιδοτήσεις- Επιχορηγήσεις για επενδυτικούς σκοπούς - κάλυψη δαπανών"
    },
    {
        "id": "25",
        "code": "E3_880_001",
        "description": "Πωλήσεις παγίων Χονδρικές"
    },
    {
        "id": "26",
        "code": "E3_880_002",
        "description": "Πωλήσεις παγίων Λιανικές"
    },
    {
        "id": "27",
        "code": "E3_880_003",
        "description": "Πωλήσεις παγίων Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "28",
        "code": "E3_880_004",
        "description": "Πωλήσεις παγίων εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "29",
        "code": "E3_881_001",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Χονδρικές"
    },
    {
        "id": "30",
        "code": "E3_881_002",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Λιανικές"
    },
    {
        "id": "31",
        "code": "E3_881_003",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "32",
        "code": "E3_881_004",
        "description": "Πωλήσεις για λογ/σμο Τρίτων Εξωτερικού Τρίτες χώρες"
    },
    {
        "id": "100",
        "code": "",
        "description": "Δεν ενημερώνει Ε3",
    },
    {
        "id": "101",
        "code": "null",
        "description": "Δεν ενημερώνει Ε3",
    },
    // EXPENSES
    {
        "id": "1",
        "code": "E3_101",
        "description": "Εμπορεύματα έναρξης"
    },
    {
        "id": "2",
        "code": "E3_102_001",
        "description": "Αγορές Εμπορευμάτων χρήσης (καθαρό ποσό)/ Χονδρικές"
    },
    {
        "id": "3",
        "code": "E3_102_002",
        "description": "Αγορές Εμπορευμάτων χρήσης (καθαρό ποσό)/ Λιανικές"
    },
    {
        "id": "4",
        "code": "E3_102_003",
        "description": "Αγορές Εμπορευμάτων χρήσης (καθαρό ποσό)/ Αγαθών του άρθρου 39α παρ 5 &#10;του κώδικα ΦΠΑ (ν.2859/2000)"
    },
    {
        "id": "5",
        "code": "E3_102_004",
        "description": "Αγορές Εμπορευμάτων χρήσης (καθαρό ποσό)/ Εξωτερικού Ενδοκοινοτικές "
    },
    {
        "id": "6",
        "code": "E3_102_005",
        "description": "Αγορές Εμπορευμάτων χρήσης (καθαρό ποσό)/ Εξωτερικού Τρίτες χώρες"
    },
    {
        "id": "7",
        "code": "E3_102_006",
        "description": "Αγορές Εμπορευμάτων χρήσης (καθαρό ποσό)/ Λοιπά"
    },
    {
        "id": "8",
        "code": "E3_104",
        "description": "Εμπορεύματα λήξης"
    },
    {
        "id": "9",
        "code": "E3_201",
        "description": "Πρώτες ύλες και υλικά έναρξης/Παραγωγή"
    },
    {
        "id": "10",
        "code": "E3_202_001",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Χονδρικές"
    },
    {
        "id": "11",
        "code": "E3_202_002",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Λιανικές"
    },
    {
        "id": "12",
        "code": "E3_202_003",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "13",
        "code": "E3_202_004",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "14",
        "code": "E3_202_005",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Λοιπά"
    },
    {
        "id": "15",
        "code": "E3_204",
        "description": "Αποθέματα λήξης πρώτων υλών και υλικών / Παραγωγή"
    },
    {
        "id": "16",
        "code": "E3_207",
        "description": "Προιόντα και παραγωγή σε εξέλιξη έναρξης / Παραγωγή"
    },
    {
        "id": "17",
        "code": "E3_209",
        "description": "Προιόντα και παραγωγή σε εξέλιξη λήξης / Παραγωγή"
    },
    {
        "id": "18",
        "code": "E3_301",
        "description": "Πρώτες ύλες και υλικά έναρξης/ Αγροτική"
    },
    {
        "id": "19",
        "code": "E3_302_001",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Χονδρικές"
    },
    {
        "id": "20",
        "code": "E3_302_002",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Λιανικές"
    },
    {
        "id": "21",
        "code": "E3_302_003",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Εξωτερικου Ενδοκοινοτικές"
    },
    {
        "id": "22",
        "code": "E3_302_004",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Εξωτερικού Τρίτες χώρες"
    },
    {
        "id": "23",
        "code": "E3_302_005",
        "description": "Αγορές πρώτων υλών και υλικών χρήσης (καθαρό ποσό)/ Λοιπά"
    },
    {
        "id": "24",
        "code": "E3_304",
        "description": "Αποθέματα λήξης πρώτων υλών και υλικών / Αγροτική"
    },
    {
        "id": "25",
        "code": "E3_307",
        "description": "Προιόντα και παραγωγή σε εξέλιξη έναρξης / Αγροτική"
    },
    {
        "id": "26",
        "code": "E3_309",
        "description": "Προιόντα και παραγωγή σε εξέλιξη λήξης / Αγροτική"
    },
    {
        "id": "27",
        "code": "E3_312",
        "description": "Αποθέματα έναρξης (ζώων-φυτών)"
    },
    {
        "id": "28",
        "code": "E3_313_001",
        "description": "Αγορές ζώων-φυτών (καθαρό ποσό)/ Χονδρικές"
    },
    {
        "id": "29",
        "code": "E3_313_002",
        "description": "Αγορές ζώων-φυτών (καθαρό ποσό)/ Λιανικές"
    },
    {
        "id": "30",
        "code": "E3_313_003",
        "description": "Αγορές ζώων-φυτών (καθαρό ποσό)/ Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "31",
        "code": "E3_313_004",
        "description": "Αγορές ζώων-φυτών (καθαρό ποσό)/ Εξωτερικού Τρίτες χώρες"
    },
    {
        "id": "32",
        "code": "E3_313_005",
        "description": "Αγορές ζώων-φυτών (καθαρό ποσό)/ Λοιπά"
    },
    {
        "id": "33",
        "code": "E3_315",
        "description": "Αποθέματα τέλους (ζώων-φυτών) /Αγροτική"
    },
    {
        "id": "34",
        "code": "E3_581_001",
        "description": "Παροχές σε εργαζόμενους /Μικτές Αποδοχές"
    },
    {
        "id": "35",
        "code": "E3_581_002",
        "description": "Παροχές σε εργαζόμενους /Εργοδοτικές Εισφορές"
    },
    {
        "id": "36",
        "code": "E3_581_003",
        "description": "Παροχές σε εργαζόμενους /Λοιπές παροχές"
    },
    {
        "id": "37",
        "code": "E3_582",
        "description": "Ζημιές επιμέτρησης περιουσιακών στοιχείων"
    },
    {
        "id": "38",
        "code": "E3_583",
        "description": "Χρεωστικές συναλλαγματικές διαφορές"
    },
    {
        "id": "39",
        "code": "E3_584",
        "description": "Ζημιές από διάθεση-απόσυρση μη κυκλοφορούντων περιουσιακών στοιχείων"
    },
    {
        "id": "40",
        "code": "E3_585_001",
        "description": "Προμήθειες διαχείρισης ημεδαπής-αλλοδαπής (management fees)"
    },
    {
        "id": "41",
        "code": "E3_585_002",
        "description": "Δαπάνες από συνδεδεμένες επιχειρήσεις"
    },
    {
        "id": "42",
        "code": "E3_585_003",
        "description": "Δαπάνες από μη συνεργαζόμενα κράτη ή από κράτη με προνομιακό φορολογικό καθεστώς"
    },
    {
        "id": "43",
        "code": "E3_585_004",
        "description": "Δαπάνες για ενημερωτικές ημερίδες"
    },
    {
        "id": "44",
        "code": "E3_585_005",
        "description": "Έξοδα υποδοχής και φιλοξενίας"
    },
    {
        "id": "45",
        "code": "E3_585_006",
        "description": "Έξοδα ταξιδιών"
    },
    {
        "id": "46",
        "code": "E3_585_007",
        "description": "Ασφαλιστικές Εισφορές Αυτοαπασχολούμενων "
    },
    {
        "id": "47",
        "code": "E3_585_008",
        "description": "Έξοδα και προμηθειες παραγγελιοδόχου για λογαριασμό αγροτών"
    },
    {
        "id": "48",
        "code": "E3_585_009",
        "description": "Λοιπές αμοιβές για υπηρεσίες ημεδαπής"
    },
    {
        "id": "49",
        "code": "E3_585_010",
        "description": "Λοιπές αμοιβές για υπηρεσίες αλλοδαπής"
    },
    {
        "id": "50",
        "code": "E3_585_011",
        "description": "Ενέργεια"
    },
    {
        "id": "51",
        "code": "E3_585_012",
        "description": "Ύδρευση"
    },
    {
        "id": "52",
        "code": "E3_585_013",
        "description": "Τηλεπικοινωνίες"
    },
    {
        "id": "53",
        "code": "E3_585_014",
        "description": "Ενοίκια"
    },
    {
        "id": "54",
        "code": "E3_585_015",
        "description": "Διαφήμιση και προβολή"
    },
    {
        "id": "55",
        "code": "E3_585_016",
        "description": "Λοιπά έξοδα"
    },
    {
        "id": "56",
        "code": "E3_586",
        "description": "Χρεωστικοί τόκοι και συναφή έξοδα"
    },
    {
        "id": "57",
        "code": "E3_587",
        "description": "Αποσβέσεις"
    },
    {
        "id": "58",
        "code": "E3_588",
        "description": "Ασυνήθη έξοδα,ζημιές και πρόστιμα"
    },
    {
        "id": "59",
        "code": "E3_589",
        "description": "Προβλέψεις(εκτός από προβλέψεις για το προσωπικό)"
    },
    {
        "id": "60",
        "code": "E3_882_001",
        "description": "Αγορές ενσώματων παγίων χρήσης/Χονδρικές"
    },
    {
        "id": "61",
        "code": "E3_882_002",
        "description": "Αγορές ενσώματων παγίων χρήσης/Λιανικές"
    },
    {
        "id": "62",
        "code": "E3_882_003",
        "description": "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές "
    },
    {
        "id": "63",
        "code": "E3_882_004",
        "description": "Αγορές ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "64",
        "code": "E3_883_001",
        "description": "Αγορές μη ενσώματων παγίων χρήσης/Χονδρικές"
    },
    {
        "id": "65",
        "code": "E3_883_002",
        "description": "Αγορές μη ενσώματων παγίων χρήσης/Λιανικές"
    },
    {
        "id": "66",
        "code": "E3_883_003",
        "description": "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Ενδοκοινοτικές"
    },
    {
        "id": "67",
        "code": "E3_883_004",
        "description": "Αγορές μη ενσώματων παγίων χρήσης/Εξωτερικού Τρίτες Χώρες"
    },
    {
        "id": "68",
        "code": "VAT_361",
        "description": "Αγορές & δαπάνες στο εσωτερικό της χώρας"
    },
    {
        "id": "69",
        "code": "VAT_362",
        "description": "Αγορές & εισαγωγές επενδ. Αγαθών(πάγια)"
    },
    {
        "id": "70",
        "code": "VAT_363",
        "description": "Λοιπές εισαγωγές εκτός επενδ. Αγαθών (πάγια)"
    },
    {
        "id": "71",
        "code": "VAT_364",
        "description": "Ενδοκοινοτικές αποκτήσεις αγαθών"
    },
    {
        "id": "72",
        "code": "VAT_365",
        "description": "Ενδοκοινοτικές λήψεις υπηρεσιών άρθρ. 14.2.α"
    },
    {
        "id": "73",
        "code": "VAT_366",
        "description": "Λοιπές πράξεις λήπτη"
    },
    {
        "id": "73",
        "code": "E3_103",
        "description": "Απομείωση εμπορευμάτων"
    },
    {
        "id": "74",
        "code": "E3_203",
        "description": "Απομείωση πρώτων υλών και υλικών"
    },
    {
        "id": "75",
        "code": "E3_303",
        "description": "Απομείωση πρώτων υλών και υλικών"
    },
    {
        "id": "76",
        "code": "E3_208",
        "description": "Απομείωση προϊόντων και παραγωγής σε εξέλιξη"
    },
    {
        "id": "77",
        "code": "E3_308",
        "description": "Απομείωση προϊόντων και παραγωγής σε εξέλιξη"
    },
    {
        "id": "78",
        "code": "E3_314",
        "description": "Απομείωση ζώων - φυτών – εμπορευμάτων"
    },
    {
        "id": "79",
        "code": "E3_106",
        "description": "Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων"
    },
    {
        "id": "80",
        "code": "E3_205",
        "description": "Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων"
    },
    {
        "id": "81",
        "code": "E3_305",
        "description": "Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων"
    },
    {
        "id": "82",
        "code": "E3_210",
        "description": "Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων"
    },
    {
        "id": "83",
        "code": "E3_310",
        "description": "Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων"
    },
    {
        "id": "84",
        "code": "E3_318",
        "description": "Ιδιοπαραγωγή παγίων - Αυτοπαραδόσεις - Καταστροφές αποθεμάτων"
    },
    {
        "id": "85",
        "code": "E3_598_002",
        "description": "Αγορές αγαθών που υπάγονται σε ΕΦΚ"
    },
    {
        "id": "86",
        "code": "NOT_VAT_295",
        "description": "Μη συμμετοχή στο ΦΠΑ (έξοδα – εισροές Φ2)"
    },
    {
        "id": "100",
        "code": "",
        "description": "Δεν ενημερώνει Ε3",
    },
    {
        "id": "101",
        "code": "null",
        "description": "Δεν ενημερώνει Ε3",
    }
]
