export const otherTaxesData = [
    {
        id: "1",
        code: "1",
        myData: 1,
        name: "α1) ασφάλιστρα κλάδου πυρός 20%",
        tax: "20",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "2",
        code: "2",
        myData: 2,
        name: "α2) ασφάλιστρα κλάδου πυρός 20%",
        tax: "20",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "3",
        code: "3",
        myData: 3,
        name: "β)ασφάλιστρα κλάδου ζωής 4%",
        tax: "4",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "4",
        code: "4",
        myData: 4,
        name: "γ) ασφάλιστρα λοιπών κλάδων 15%",
        tax: "15",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "5",
        code: "5",
        myData: 5,
        name: "δ) απαλλασσόμενα φόρου ασφαλίστρων 0%",
        tax: "0",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "6",
        code: "6",
        myData: 6,
        name: "Ξενοδοχεία 1-2 αστέρων 0,50€",
        tax: "0.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "7",
        code: "7",
        myData: 7,
        name: "Ξενοδοχεία 3 αστέρων 1,50€",
        tax: "1.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "8",
        code: "8",
        myData: 8,
        name: "Ξενοδοχεία 4 αστέρων 3,00€",
        tax: "3",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "9",
        code: "9",
        myData: 9,
        name: "Ξενοδοχεία 5 αστέρων 4,00€",
        tax: "4",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "10",
        code: "10",
        myData: 10,
        name: "Ενοικιαζόμενα- Επιπλωμένα δωμάτια- διαμερίσματα 0,50€",
        tax: "0.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "11",
        code: "11",
        myData: 11,
        name: "Ειδικός φόρος στις διαφημίσεις που προβάλλονται απο την τηλεόραση (ΕΦΤΔ) 5%",
        tax: "5",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "12",
        code: "12",
        myData: 12,
        name: "3.1 Φόρος Πολυτελείας 10% επί της φορολογούμενης αξίας για τα ενδοικοινοτικώς αποκτούμενα και εισαγόμενα από τρίτες χώρες",
        tax: "10",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "13",
        code: "13",
        myData: 13,
        name: "3.2 Φόρος πολυτελείας 10% επί της τιμής πώλησης προ ΦΠΑ για τα εγχωρίως παραγόμενα είδη",
        tax: "10",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "14",
        code: "14",
        myData: 14,
        name: "Δικαίωμα του Δημοσίου στα εισιτήρια των καζίνο (80% επί του εισιτηρίου)",
        tax: "80",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "15",
        code: "15",
        myData: 15,
        name: "ασφάλιστρα κλάδου πυρός 20%",
        tax: "20",
        taxPer: "true",
        taxAmount: "false",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "16",
        code: "16",
        myData: 16,
        name: "Λοιποί Τελωνειακοί Δασμοί-Φόροι",
        tax: "0",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "17",
        code: "17",
        myData: 17,
        name: "Λοιποί Φόροι",
        tax: "0",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "18",
        code: "18",
        myData: 18,
        name: "Επιβαρύνσεις Λοιπών Φόρων",
        tax: "0",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "19",
        code: "19",
        myData: 19,
        name: "ΕΦΚ",
        tax: "0",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 1,
        vatIncluded : 3
    },
    {
        id: "20",
        code: "20",
        myData: 20,
        name: "Ξενοδοχεία 1-2 αστέρων 1,50€ (ανά Δωμ./Διαμ.)",
        tax: "1.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "21",
        code: "21",
        myData: 21,
        name: "Ξενοδοχεία 3 αστέρων 3,00€ (ανά Δωμ./Διαμ.)",
        tax: "3",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "22",
        code: "22",
        myData: 22,
        name: "Ξενοδοχεία 4 αστέρων 7,00€ (ανά Δωμ./Διαμ.)",
        tax: "7",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "23",
        code: "23",
        myData: 23,
        name: "Ξενοδοχεία 5 αστέρων 10,00€ (ανά Δωμ./Διαμ.)",
        tax: "10",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "24",
        code: "24",
        myData: 24,
        name: "Ενοικιαζόμενα επιπλωμένα δωμάτια – διαμερίσματα 1,50€ (ανά Δωμ./Διαμ.)",
        tax: "1.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "25",
        code: "25",
        myData: 25,
        name: "Ακίνητα βραχυχρόνιας μίσθωσης 1,50€",
        tax: "1.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "26",
        code: "26",
        myData: 26,
        name: "Ακίνητα βραχυχρόνιας μίσθωσης μονοκατοικίες άνω των 80 τ.μ. 10,00€",
        tax: "10",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "27",
        code: "27",
        myData: 27,
        name: "Αυτοεξυπηρετούμενα καταλύματα – τουριστικές επιπλωμένες επαύλεις (βίλες) 10,00€",
        tax: "10",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "28",
        code: "28",
        myData: 28,
        name: "Ακίνητα βραχυχρόνιας μίσθωσης 0,50€",
        tax: "0.5",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "29",
        code: "29",
        myData: 29,
        name: "Ακίνητα βραχυχρόνιας μίσθωσης μονοκατοικίες άνω των 80 τ.μ. 4,00€",
        tax: "4",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
    {
        id: "30",
        code: "30",
        myData: 30,
        name: "Αυτοεξυπηρετούμενα καταλύματα – τουριστικές επιπλωμένες επαύλεις (βίλες) 4,00€",
        tax: "4",
        taxPer: "false",
        taxAmount: "true",
        vatPercentage: 0,
        taxLevel: 2,
        vatIncluded : 3
    },
  ]

export const otherTaxesTaxLevel = [
    {
        "no": 1,
        "label": "Σε επίπεδο παραστατικού",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Σε επίπεδο γραμμής παραστατικού",
        "value": 2,
        "color": "#76AEB7"
    }
]

export const otherTaxesVatIncluded = [
    {
        "no": 1,
        "label": "Συμπεριλαμβάνει ΦΠΑ",
        "value": 1,
        "color": "#76AEB7"
    },
    {
        "no": 2,
        "label": "Δεν συμπεριλαμβάνει ΦΠΑ",
        "value": 2,
        "color": "#76AEB7"
    },
    {
        "no": 3,
        "label": "Εξαιρείται ΦΠΑ",
        "value": 3,
        "color": "#76AEB7"
    }
]
