import React, {useRef, useState} from "react";
import {Badge, Button, CloseButton, Stack} from "react-bootstrap";
import "./style.css"
import Dropdown from "../Dropdown";
import * as moment from "moment";

const DateHelper = ({dateFromFunction, dateToFunction, dateFromAndToFunction}) => {
    const [show, setShow] = useState(false);

    const monthOptions = [
        { label: "Ιανουάριος", value: "01" },
        { label: "Φεβρουάριος", value: "02" },
        { label: "Μάρτιος", value: "03" },
        { label: "Απρίλιος", value: "04" },
        { label: "Μάιος", value: "05" },
        { label: "Ιούνιος", value: "06" },
        { label: "Ιούλιος", value: "07" },
        { label: "Αύγουστος", value: "08" },
        { label: "Σεπτέμβριος", value: "09" },
        { label: "Οκτώβριος", value: "10" },
        { label: "Νοέμβριος", value: "11" },
        { label: "Δεκέμβριος", value: "12" },
    ];

    const yearOptions = []
    for (let year = 2021; year <= new Date().getFullYear(); year++) {
        yearOptions.push({ label: year, value: year });
    }

    const handleSelection = (type, special) => {
        let dateFrom, dateTo;
        if (type === "thisMonth") {
            dateFrom = moment().utc().startOf("month").toDate();
            dateTo = moment().utc().endOf("month").toDate();
        } else if (type === "lastMonth") {
            const someLastMonthDate = moment().startOf("month").subtract(1, "day");
            dateFrom = someLastMonthDate.utc().startOf("month").toDate();
            dateTo = someLastMonthDate.utc().endOf("month").toDate();
        } else if (type === "thisYear") {
            dateFrom = moment().utc().startOf("year").toDate();
            dateTo = moment().utc().endOf("year").toDate();
        } else if (type === "monthSelection") {
            dateFrom = moment(special, "MM", true).startOf("month").toDate();
            dateTo = moment(special, "MM", true).endOf("month").toDate();
        } else if (type === "yearSelection") {
            dateFrom = moment(special, "YYYY", true).startOf("year").toDate();
            dateTo = moment(special, "YYYY", true).endOf("year").toDate();
        }
        if (dateFromFunction instanceof Function) dateFromFunction(dateFrom);
        if (dateToFunction instanceof Function) dateToFunction(dateTo);
        if (dateFromAndToFunction instanceof Function) dateFromAndToFunction(dateFrom, dateTo);
        setShow(false);
    }

    return (
        <React.Fragment>
            <div style={{position: "relative"}}>
                <label></label>
                <br/>
                <Button style={{padding: "7px 15px", width: "100%"}} onClick={() => setShow(!show)}>{"Επιλογή Ημερομηνίας"}</Button>
                {show && (
                    <div tabIndex="0" className={"date-helper-container"}>
                        <CloseButton style={{position: "absolute", top: "5px", right: "5px"}} onClick={() => setShow(false)}/>
                        Ταχύα Φίλτρα
                        <Stack className={"mt-1"} gap={1}>
                            <Badge className={"badge-style"} bg={""} onClick={() => handleSelection("thisMonth")}>Τρέχον Μήνας</Badge>
                            {Number(moment().format("MM")) > 1 && <Badge className={"badge-style"} bg={""} onClick={() => handleSelection("lastMonth")}>Περασμένος Μήνας</Badge>}
                            <Badge className={"badge-style"} bg={""} onClick={() => handleSelection("thisYear")}>Τρέχον Έτος</Badge>
                        </Stack>
                        <Dropdown
                            label={"Επιλογή Μήνα"}
                            options={monthOptions}
                            className={"mt-1"}
                            onChange={(e) => handleSelection("monthSelection", e.value)}
                        />
                        <Dropdown
                            label={"Επιλογή Έτους"}
                            options={yearOptions}
                            className={"mt-1"}
                            onChange={(e) => handleSelection("yearSelection", e.value)}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default DateHelper;