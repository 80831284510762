import React, { Component } from 'react';
import { CFormSwitch } from '@coreui/react';

class FormSwitch extends Component {
    shouldComponentUpdate(nextProps) {
        console.log(this.props)
        return nextProps.checked !== this.props.checked;
    }

    componentDidUpdate(prevProps) {
        console.log('Did Update');
    }

    render() {
        const { key, id, checked, onChange, onAnimationEnd, className } = this.props;
        return (
            <CFormSwitch
                key={key}
                id={id}
                checked={checked}
                onChange={onChange}
                onAnimationEnd={onAnimationEnd}
                className={className}
            />
        );
    }
}

export default FormSwitch;