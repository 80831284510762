import React, {useEffect, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {BACKEND_URL} from "../../Helpers/Environment";
import axios from "axios";
import {toast} from "react-toastify";
import {getAccessLevel, isEmpty, transformDataForPrint} from "../../Helpers/Helpers";
import InvoiceView from "../../Components/invoiceView";
import {Modal, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {
    resetHtmlInvoice,
    resetInvoice,
    setHtmlInvoice,
    setInputIdentifier,
    setInvoice,
    setView,
    setViewType
} from "../../slices/myDataSearch_slice";
import {useNavigate, useParams} from "react-router-dom";
import FormSwitch from "../../Components/CFormSwitch";
import InvoicePrintTemplate from "../../Components/InvoicePrint/invoicePrint";
import {useReactToPrint} from "react-to-print";
import Dropdown from "../../Components/Dropdown";
import InvoicePrintA5 from "../../Components/InvoicePrint/invoicePrint-a5";
import JsonText from "../../Components/JsonText";
import InvoicePrintA5Double from "../../Components/InvoicePrint/invoicePrint-a5-double";
import {redirectTo} from "../../navigation";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ThermalPrint from "../../Components/InvoicePrint/thermal-print";

function MyDataSearch() {
    const [loading, setLoading] = useState(false);
    const [showScan, setShowScan] = useState(false);
    const [showCameraError, setShowCameraError] = useState(false);
    const view = useSelector((state) => state.myDataSearch.view);
    const viewType = useSelector((state) => state.myDataSearch.viewType);
    const { identifier } = useParams();
    const invoice = useSelector((state) => state.myDataSearch.invoice);
    const htmlInvoice = useSelector((state) => state.myDataSearch.htmlInvoice);
    const inputIdentifier = useSelector((state) => state.myDataSearch.inputIdentifier);
    const [originalJson, setOriginalJson] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const componentRef = useRef();
    const componentRefA5 = useRef();
    const componentRefDA5 = useRef();
    const componentRefThermal = useRef();
    const iframeRef = useRef();

    useEffect(() => {
        if (identifier && identifier?.length !== 0) {
            handleOnSearch();
            dispatch(setView("simple"));
        }
    }, [identifier])

    useEffect(() => {
        if (!isEmpty(invoice) && ["A4", "A5", "thermal"].includes(invoice.template)) {
            dispatch(setViewType(invoice.template));
        }
    }, [invoice]);

    useEffect(() => {
        if (htmlInvoice && view === "print") {
            const iframe = iframeRef.current;
            const doc = iframe?.contentDocument || iframe?.contentWindow.document;
            doc?.open();
            doc?.write(htmlInvoice);
        }
    }, [htmlInvoice, view])

    const handleOnSearch = () => {
        setLoading(true);
        if (isEmpty(identifier)) {
            toast.error("MARK cannot be empty.");
        } else {
            axios.post(`${BACKEND_URL}/api/public/mydatasearch`, {identifier: identifier}, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                setLoading(false);
                if (res.data.status === "200" && !isEmpty(res.data.data)) {
                    dispatch(setHtmlInvoice(res.data.htmlFile ?? null))
                    dispatch(setInvoice(res.data.data));
                    setOriginalJson(res.data.originalJson);
                } else {
                    dispatch(resetInvoice());
                    dispatch(resetHtmlInvoice());
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("Connection error.");
            })
        }
    }

    const handleOptionChange = (e, option) => {
        if (option === "view") {
            dispatch(setView(e.target.checked ? "print" : "simple"));
        } else if (option === "viewType") {
            dispatch(setViewType(e.value));
        }
    };

    const handleIdentifierChange = () => {
        if (inputIdentifier !== identifier) {
            navigate(`/${inputIdentifier}`, { state: {} });
        } else if (isEmpty(invoice)) {
            handleOnSearch();
        }
    }

    const handlePrint = useReactToPrint({
        content: () => {
            switch (viewType) {
                case "A4": return componentRef.current;
                case "A5": return componentRefA5.current;
                case "A5 double": return componentRefDA5.current;
                case "thermal": return componentRefThermal.current;
                default: return componentRef.current;
            }
        }
    });

    const handleIframePrint = () => {
        iframeRef.current.contentWindow?.print();
    }

    const handleOnHide = () => {
        setShowScan(false);
        setShowCameraError(false);
    }

    return (
        <Container fluid={"xl"} className={"mt-3"} style={{maxWidth: "1500px"}}>
            <Row>
                <Col md={3}></Col>
                <Col md={6}>
                    <span>MARK Παραστατικού</span>
                    <InputGroup className="mb-3">
                        <Form.Control
                            name={"inputIdentifier"}
                            placeholder="Εισάγετε Mark"
                            value={inputIdentifier}
                            onChange={(e) => dispatch(setInputIdentifier(e.target.value))}
                            onKeyDown={(e) => (e.key === "Enter") && handleIdentifierChange()}
                        />
                        <Button className={"btn-qr"} onClick={() => setShowScan(true)}>
                            QR scan
                        </Button>
                    </InputGroup>
                    <div className={"text-center"}>
                        <Button variant={"primary"} className={"btn-mydatasearch"} style={{padding: "15px 30px"}} disabled={loading || isEmpty(inputIdentifier)} onClick={() => handleIdentifierChange()}>
                            Αναζήτηση Παραστατικού {loading && <Spinner size={"sm"} variant={"dark"}/>}
                        </Button>
                    </div>
                </Col>
                <Col md={3}></Col>
            </Row>
            {!isEmpty(invoice) &&
                <React.Fragment>
                    <Row className={"mb-2 mt-2 mt-md-0"}>
                        <Col xs={12}>
                            <div className={"d-lg-flex justify-content-between align-items-center"}>
                                <div className={"d-flex align-items-center justify-content-between"} style={{height: "56px"}}>
                                    <div className={"d-flex align-items-center"}>
                                        <label className={"fw-semibold"}>Προβολή Εκτύπωσης</label>
                                        <FormSwitch
                                            id={"viewType"}
                                            key={Math.random()}
                                            onChange={(e) => handleOptionChange(e, "view")}
                                            checked={view === "print"}
                                            className={"mx-2"}
                                        />
                                    </div>
                                    <div hidden={view !== "print"} style={{width: "150px"}} className={"mx-lg-5"}>
                                        {isEmpty(htmlInvoice) && <Dropdown
                                            key={Math.random()}
                                            onChange={(e) => handleOptionChange(e, "viewType")}
                                            defaultValue={{label: viewType, value: viewType}}
                                            options={
                                                getAccessLevel() === "admin" ?
                                                    [{label: "A4", value: "A4"},
                                                    {label: "A5", value: "A5"},
                                                    {label: "A5 double", value: "A5 double"},
                                                    {label: "thermal", value: "thermal"},
                                                    {label: "Original Json", value: "Original Json"},]
                                                    :
                                                    [{label: "A4", value: "A4"},
                                                    {label: "A5", value: "A5"},
                                                    {label: "A5 double", value: "A5 double"},
                                                    {label: "thermal", value: "thermal"},]
                                            }
                                            className={"mb-0"}
                                        />}
                                    </div>
                                </div>
                                <Col md={4} className={"mt-4 mt-lg-0"} hidden={view !== "print"}>
                                    <div className={"d-flex align-items-center justify-content-between justify-content-lg-start"}>
                                        <Button className={"mr-0 d-block"} onClick={() => htmlInvoice ? handleIframePrint() : handlePrint()}>
                                            Εκτύπωση
                                        </Button>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                    {view === "simple" && <InvoiceView invoice={invoice}/>}
                    {view === "print" &&
                        <React.Fragment>
                            {htmlInvoice ?
                            <iframe ref={iframeRef} style={{width: "210mm", height: "297mm", display: "block", margin: "auto"}} />
                                :
                            <div className={"my-4"}>
                                {viewType === "A4" && <InvoicePrintTemplate ref={componentRef} data={transformDataForPrint(invoice, "A4")}/>}
                                {viewType === "A5" && <InvoicePrintA5 ref={componentRefA5} data={transformDataForPrint(invoice, "A5")}/>}
                                {viewType === "A5 double" && <InvoicePrintA5Double ref={componentRefDA5} data={transformDataForPrint(invoice, "A5D")}/>}
                                {viewType === "thermal" && <ThermalPrint ref={componentRefThermal} data={transformDataForPrint(invoice, "thermal")}/>}
                                {viewType === "Original Json" && <JsonText data={originalJson}/>}
                            </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>}
            <Modal show={showScan} centered={true} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Σάρωση QR παραστατικού</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"qr-modal"}>
                    {!showCameraError && <BarcodeScannerComponent
                        width={"100%"}
                        height={"100%"}
                        facingMode={"environment"}
                        delay={1000}
                        onError={(e) => setShowCameraError(true)}
                        onUpdate={(err, result) => {
                            try {
                                if (!result) return;
                                const url = new URL(result.getText());
                                if (!isEmpty(url)) {
                                    const id = url.pathname.split("/")[url.pathname.split("/").length - 1];
                                    setShowScan(false);
                                    redirectTo(`/${id}`);
                                    toast.success(`Βρέθηκε παραστατικό. ID:${id}`)
                                } else {
                                    toast.error("Μη έγκυρος κωδικός QR.");
                                }
                            } catch (err) {
                                console.log(err);
                                toast.error("Μη έγκυρος κωδικός QR.");
                                return false;
                            }
                        }}
                    />}
                    {showCameraError && <p style={{textAlign: "center"}}>Δε βρέθηκε συσκευή</p>}
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default MyDataSearch;
