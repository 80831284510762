import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    settingsView: 1,
}

export const adminSlice = createSlice({
    name: 'adminSlice',
    initialState: initialState,
    reducers: {
        setSettingsView: (state, action) => {
            state.settingsView = action.payload;
        },
    },
});

export const {
    setSettingsView
} = adminSlice.actions;

export default adminSlice.reducer;
