import React, {useEffect} from 'react';
import AdminSidebar from "../../Components/AdminSidebar/AdminSidebar";
import ApisPage from "./ApisPage";
import ManageCompaniesPage from "./ManageCompaniesPage";
import {useSelector} from "react-redux";
import {getAccessLevel, loginCheck} from "../../Helpers/Helpers";
import {redirectTo} from "../../navigation";

function Settings() {
    const settingsView = useSelector((state) => state.admin.settingsView);
    const isAdmin = getAccessLevel() === "admin";

    // ToDo: Make it dynamic with array of objects.
    const viewPages = [
        "Διαχείριση Διεπαφών",
        "Διαχείριση Συνεργατών"
    ];

    useEffect(() => {
        if(!loginCheck()) redirectTo("/connect/login?expired");
    }, []);

    return (isAdmin &&
        <React.Fragment>
            <div className={"settingsContainer"}>
                <AdminSidebar
                    viewPages={viewPages}
                    activeView={settingsView}
                />
                <div className={"rightOfSidebarContent"}>
                    <div hidden={settingsView !== 1}><ApisPage /></div>
                    <div hidden={settingsView !== 2}><ManageCompaniesPage /></div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Settings;
