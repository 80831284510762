import { createSlice } from '@reduxjs/toolkit';

const requestDataInitialState = {
    page: 1,
    id: "",
    companyName: "",
    companyVatNumber: "",
    companyDoy: "",
    companyCity: "",
    companyTk: "",
    companyEmail: "",
    companyPhoneNumber: "",
    companyActivity: "",
}

const companyDataInitialState = {
    _id: "",
    companyName: "",
    companySmallName: "",
    companyVatNumber: "",
    companyDoy: "",
    companyAddress: "",
    companyCity: "",
    companyTk: "",
    companyEmail: "",
    companyPhoneNumber: "",
    companyMobilePhoneNumber: "",
    companyActivity: "",
    gemh: "",
    representativeName: "",
    representativeSurname: "",
    representativeVatNumber: "",
    subsidiaries: [],
}

const initialState = {
    requestData: requestDataInitialState,
    oldRequestData: requestDataInitialState,
    companyData: companyDataInitialState,
    responseData: {
        data: [],
        totalPages: 0,
        totalItems: 0,
    },
    selectedInvoices: [],
    selectAll: false,
}

export const manageCompaniesSlice = createSlice({
    name: 'manageCompaniesSlice',
    initialState: initialState,
    reducers: {
        setRequestData: (state, action) => {
            state.requestData = action.payload;
        },
        setOldRequestData: (state, action) => {
            state.oldRequestData = action.payload;
        },
        setCompanyData: (state, action) => {
            state.companyData = {...companyDataInitialState, ...action.payload};
        },
        resetCompanyData: (state) => {
            state.companyData = companyDataInitialState;
        },
        setResponseData: (state, action) => {
            state.responseData = action.payload;
        },
    },
});

export const {
    setRequestData,
    setOldRequestData,
    setCompanyData,
    resetCompanyData,
    setResponseData,
} = manageCompaniesSlice.actions;

export default manageCompaniesSlice.reducer;
