// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar container */
.sidebar-container {
    position: relative;
    width: 200px;
    max-width: 100%;
    min-height: 100vh;
    transition: all 0.3s ease-in-out;
    display: none;
}

.sidebar {
    background-color: #333;
    color: white;
    height: 100%;
    width: 200px;
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 20px;
    z-index: 10;
}

.sidebar .menu {
    list-style-type: none;
    padding: 0;
}

.sidebar .menu li {
    padding: 10px 20px;
    text-align: left;
    border-bottom: 1px solid #444;
    cursor: pointer;
}

.sidebar .menu li {
    color: white;
    font-size: 18px;
}

.sidebar .menu .active {
    background-color: #4f8d96;
}

.sidebar .menu li a:hover {
    color: #4CAF50;
}

@media (min-width: 768px) {
    .sidebar-container {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/AdminSidebar/Sidebar.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":["/* Sidebar container */\r\n.sidebar-container {\r\n    position: relative;\r\n    width: 200px;\r\n    max-width: 100%;\r\n    min-height: 100vh;\r\n    transition: all 0.3s ease-in-out;\r\n    display: none;\r\n}\r\n\r\n.sidebar {\r\n    background-color: #333;\r\n    color: white;\r\n    height: 100%;\r\n    width: 200px;\r\n    /*position: fixed;*/\r\n    /*top: 0;*/\r\n    /*left: 0;*/\r\n    overflow-x: hidden;\r\n    transition: 0.3s;\r\n    padding-top: 20px;\r\n    z-index: 10;\r\n}\r\n\r\n.sidebar .menu {\r\n    list-style-type: none;\r\n    padding: 0;\r\n}\r\n\r\n.sidebar .menu li {\r\n    padding: 10px 20px;\r\n    text-align: left;\r\n    border-bottom: 1px solid #444;\r\n    cursor: pointer;\r\n}\r\n\r\n.sidebar .menu li {\r\n    color: white;\r\n    font-size: 18px;\r\n}\r\n\r\n.sidebar .menu .active {\r\n    background-color: #4f8d96;\r\n}\r\n\r\n.sidebar .menu li a:hover {\r\n    color: #4CAF50;\r\n}\r\n\r\n@media (min-width: 768px) {\r\n    .sidebar-container {\r\n        display: block;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
