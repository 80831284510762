import React, {useEffect, useState} from 'react';
import axios from "axios";
import {BACKEND_URL} from "../../Helpers/Environment";
import {compareDocs, isEmpty} from "../../Helpers/Helpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import {useDispatch, useSelector} from "react-redux";
import {
    resetCompanyData,
    setCompanyData,
    setOldRequestData,
    setRequestData,
    setResponseData,
} from "../../slices/Admin/manageCompanies_slice";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Dropdown from "../../Components/Dropdown";
import Button from "react-bootstrap/Button";
import {Modal, Spinner} from "react-bootstrap";
import {SearchTable} from "../../Components/SearchTable";
import {redirectTo} from "../../navigation";
import {toast} from 'react-toastify';
import {setRequestDataOnlyVatNumber} from "../../slices/searchInvoice_slice";
import EditableTable from "../../Components/EditableTable/EditableTable";

function ManageCompaniesPage() {
    const dispatch = useDispatch();
    const [companyACBoxData, setCompanyACBoxData] = useState();
    const [pageChanged, setPageChanged] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const requestData = useSelector((state) => state.manageCompanies.requestData);
    const oldRequestData = useSelector((state) => state.manageCompanies.oldRequestData);
    const companyData = useSelector((state) => state.manageCompanies.companyData);
    const responseData = useSelector((state) => state.manageCompanies.responseData);

    useEffect(() => {
        if (isEmpty(companyACBoxData)) {
            fetchCompanyData().then((data) => {
                if (data?.data?.status === "200") setCompanyACBoxData(data.data.data);
            });
        }
    }, [])

    useEffect(() => {
        if (pageChanged) {
            setPageChanged(false);
            fetchCompanies();
        }
    }, [pageChanged])

    const tableColumns = [
        {
            name: "",
            field: "aa",
            width: "3%",
        },{
            name: "Επωνυμία",
            field: "companyName",
            width: "22%",
        },{
            name: "Δραστηριότητα",
            field: "companyActivity",
            width: "20%",
        },{
            name: "Α.Φ.Μ.",
            field: "companyVatNumber",
            width: "6%",
        },{
            name: "Διεύθυνση",
            field: "companyAddress",
            width: "15%",
        },{
            name: "Πόλη",
            field: "companyCity",
            width: "8%",
        },{
            name: "Τ.Κ.",
            field: "companyTk",
            width: "4%",
        },{
            name: "Τηλέφωνο",
            field: "companyPhoneNumber",
            width: "7%",
        },{
            name: "Email",
            field: "companyEmail",
            width: "11%",
        },{
            name: "",
            field: "actions",
            width: "3%",
        },
    ];

    const subsidiaryColumns = [
        {
            name: "Branch ID",
            field: "branchId",
            type: "number",
            width: "5%",
        },{
            name: "Τηλέφωνο",
            field: "phoneNumber",
            width: "15%",
        },{
            name: "Οδός",
            field: "street",
            width: "20%",
        },{
            name: "Αριθμός",
            field: "number",
            width: "5%",
        },{
            name: "Πόλη",
            field: "city",
            width: "20%",
        },{
            name: "T.K.",
            field: "tk",
            width: "10%",
        },{
            name: "Δ.Ο.Υ.",
            field: "doy",
            width: "20%",
        }
    ];

    const accessLevelOptions = [{label: 'normal', value: 'normal'},{label: 'admin', value: 'admin'}];

    const fetchCompanyData = async (query) => {
        return await axios.get(`${BACKEND_URL}/api/public/get-companies`, {
            headers: { "Content-Type": "application/json" },
            params: isEmpty(query) ? null : { query: query },
        }).catch((err) => {
            console.error(err)
        })
    }

    const fetchCompanies = (force = false) => {
        setSearchLoading(true);
         axios.post(`${BACKEND_URL}/api/admin/get-companies`, force ? requestData : oldRequestData,{ headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if (force) {
                dispatch(setOldRequestData(requestData));
            }
            dispatch(setResponseData(res.data));
         }).catch((err) => {
            console.error(err);
        }).finally(() => setSearchLoading(false))
    }

    const renewCompanies = () => {
         axios.get(`${BACKEND_URL}/api/admin/renew-companies`).then((res) => {
            if (res.data.status === "200") toast.success("Η άντηλση εταιρειών ολοκληρώθηκε.");
            else toast.error("Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε αργότερα.");
         }).catch((err) => {
            console.error(err);
        }).finally(() => setSearchLoading(false))
    }

    const handleEnter = async (e) => {
        if (e.code === "Enter") fetchCompanies(true);
    }

    const handleOnChange = (e, type, name) => {
        if (type === "input") {
            dispatch(setRequestData({...requestData, [name] : e.target.value}));
        } else if (type === "editCompany") {
            if (name === "subsidiaries") {
                dispatch(setCompanyData({...companyData, subsidiaries: e}));
            } else {
                dispatch(setCompanyData({...companyData, [name] : e.target.value}));
            }
        }
    }

    const handlePageChange = (page) => {
        dispatch(setOldRequestData({...oldRequestData, page: page}));
        setPageChanged(true);
    }

    const editCompany = (e, selectedCompany) => {
        dispatch(setCompanyData(selectedCompany));
    }

    const sendUpdateCompany = () => {
        setUpdateLoading(true);
        axios.post(`${BACKEND_URL}/api/admin/update-company`, companyData,{ headers: { "Content-Type": "application/json" }
        }).then((res) => {
            if (res?.data.status === "200" && res.data.updatedCompany) {
                dispatch(resetCompanyData());
                const newData = responseData.data.map((el) => {
                    if (el._id === res.data.updatedCompany._id) return res.data.updatedCompany;
                    return el;
                });
                dispatch(setResponseData({...responseData, data: newData}));
                toast.success(`Εγινε ενημέρωση της εταιρείας με Α.Φ.Μ. ${res.data.updatedCompany.companyVatNumber}`);
            } else if (res?.data.status !== "200") {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.error(err);
        }).finally(() => setUpdateLoading(false))
    }

    const viewCompanyInvoices = (e, selectedCompany) => {
        dispatch(setRequestDataOnlyVatNumber(selectedCompany.companyVatNumber)); // Foreign slice
        redirectTo(`/connect/search-invoice`, {selectedCompany: selectedCompany});
    }

    const handleOnHide = () => {
        dispatch(resetCompanyData());
    }

    const fetchVatDetails = () => {
        setUpdateLoading(true);
        if (isEmpty(companyData.companyVatNumber)) return toast.error("Το Α.Φ.Μ. πρέπει να είναι συμπληρωμένο.");
        axios.post(`${BACKEND_URL}/api/admin/fetch-vat-details`, {vatNumber: companyData.companyVatNumber, onlyAADE: true},
            {
                headers: { "Content-Type": "application/json" }
            }).then((res) => {
                const result = res?.data?.data;
                if (isEmpty(result)) return toast.error(res?.data?.message ?? "Το Α.Φ.Μ. δεν βρέθηκε.");
                const companyNewData = {
                        ...companyData,
                        companyVatNumber: result.vatNumber,
                        companyName: result.name ?? "",
                        companySmallName: result.smallName ?? "",
                        companyDoy: result.doy ?? "",
                        companyAddress: result.address ?? "",
                        companyCity: result.city ?? "",
                        companyTk: result.tk ?? "",
                        companyActivity: result.activity ?? ""
                }
                const compareRes = compareDocs(companyData, companyNewData);
                dispatch(setCompanyData(companyNewData));
                if (compareRes) {
                    toast.success(`Έγινε ενημέρωση στοιχείων του Α.Φ.Μ. ${result.vatNumber}`);
                } else {
                    toast.info(`Τα στοιχεία του Α.Φ.Μ. ${result.vatNumber} είναι ήδη ενημερωμένα.`);
                }
            }).catch((err) => {
                console.error(err);
            }).finally(() => setUpdateLoading(false))
    }

    return (
        <React.Fragment>
            <Container>
                <Row className={"pt-4"} onKeyDown={(e) => handleEnter(e)}>
                    <Row>
                        <Col md={6}>
                            <span>Επωνυμία</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Επωνυμία"
                                    onChange={(e) => handleOnChange(e, "input", "companyName")}
                                    value={requestData.companyName}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <span style={{fontSize: "14px"}}>ΑΦΜ εταιρείας/νομικού εκ.</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Α.Φ.Μ."
                                    onChange={(e) => handleOnChange(e, "input", "companyVatNumber")}
                                    value={requestData.companyVatNumber}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <span>Πόλη</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Πόλη"
                                    onChange={(e) => handleOnChange(e, "input", "companyCity")}
                                    value={requestData.companyCity}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <span>Τ.Κ.</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Τ.Κ."
                                    onChange={(e) => handleOnChange(e, "input", "companyTk")}
                                    value={requestData.companyTk}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <span>Δ.Ο.Υ.</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Δ.Ο.Υ."
                                    onChange={(e) => handleOnChange(e, "input", "companyDoy")}
                                    value={requestData.companyDoy}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <span>Email</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Email"
                                    onChange={(e) => handleOnChange(e, "input", "companyEmail")}
                                    value={requestData.companyEmail}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <span>Τηλέφωνο</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Τηλέφωνο"
                                    onChange={(e) => handleOnChange(e, "input", "companyPhoneNumber")}
                                    value={requestData.companyPhoneNumber}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <span>Δραστηριότητα</span>
                            <InputGroup className="mb-3 w-100">
                                <Form.Control
                                    placeholder="Δραστηριότητα"
                                    onChange={(e) => handleOnChange(e, "input", "companyActivity")}
                                    value={requestData.companyActivity}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}></Col>
                        <Col md={2}>
                            <Button variant="primary" className={"w-100 my-4"} onClick={() => fetchCompanies(true)} disabled={false}>
                                Αναζήτηση {searchLoading && <Spinner size={"sm"} variant={"dark"}/>}
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Button variant="primary" className={"w-100 my-4 px-1"} onClick={() => renewCompanies()} disabled={false}>
                                Άντληση Εταιριών
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>

            <SearchTable
                responseData={responseData}
                tableColumns={tableColumns}
                activePage={oldRequestData.page}
                handlePageChange={handlePageChange}
                handleOnChange={handleOnChange}
                handleDoubleClickRow={editCompany}
                handleViewItem={viewCompanyInvoices}
                showTotals={false}
                isFluid={true}
                hasAA={true}
                perPage={20}
                minimumWidth={"1500px"}
            />

            <Modal size={'xl'} fullscreen={"md-down"} scrollable={true} show={!isEmpty(companyData._id)} centered={false} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Επεξεργασία Εταιρείας - {companyData.companyName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Επωνυμία</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyName")}
                                value={companyData.companyName}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Διακριτική Ονομασία</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companySmallName")}
                                value={companyData.companySmallName}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Α.Φ.Μ.</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyVatNumber")}
                                value={companyData.companyVatNumber}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Δ.Ο.Υ.</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyDoy")}
                                value={companyData.companyDoy}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Διεύθυνση</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyAddress")}
                                value={companyData.companyAddress}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Πόλη</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyCity")}
                                value={companyData.companyCity}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Τ.Κ.</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyTk")}
                                value={companyData.companyTk}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Email</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyEmail")}
                                value={companyData.companyEmail}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Τηλέφωνο</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyPhoneNumber")}
                                value={companyData.companyPhoneNumber}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Κινητό</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyMobilePhoneNumber")}
                                value={companyData.companyMobilePhoneNumber}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Δραστηριότητα</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "companyActivity")}
                                value={companyData.companyActivity}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Αρ. Γεμή</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "gemh")}
                                value={companyData.gemh}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Όνομα Νομικού Αντιπροσώπου</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "representativeName")}
                                value={companyData.representativeName}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Επώνυμο Νομικού Αντιπροσώπου</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "representativeSurname")}
                                value={companyData.representativeSurname}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Α.Φ.Μ. Νομικού Αντιπροσώπου</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "representativeVatNumber")}
                                value={companyData.representativeVatNumber}
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <span>Connector Password</span>
                        <InputGroup className="mb-3">
                            <Form.Control
                                onChange={(e) => handleOnChange(e, "editCompany", "connectorPassword")}
                                value={companyData.connectorPassword}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mt-2"}>
                    <Col md={6}>
                        <span>Πρόσβαση</span>
                        <InputGroup className="mb-3">
                            <Dropdown
                                onChange={(e) => handleOnChange(e, "editCompany", "accessLevel")}
                                options={accessLevelOptions}
                                value={accessLevelOptions.filter((el) => el.value === companyData.accessLevel) ?? "normal"}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className={"mb-5"}>
                    <EditableTable
                        tableName={"editCompany"}
                        fieldName={"subsidiaries"}
                        title={"Υποκαταστήματα"}
                        tableColumns={subsidiaryColumns}
                        tableData={companyData.subsidiaries}
                        onBlur={handleOnChange}
                        onDeleteRow={handleOnChange}
                        firstCellField={"branchId"}
                    />
                </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className={"mx-auto"} style={{display: "flex", gap: "20px", alignItems: "center"}}>
                        <Button variant="primary" style={{width: "250px"}} onClick={() => fetchVatDetails()} disabled={updateLoading}>
                            Άντληση Στοιχείων {updateLoading && <Spinner size={"sm"} variant={"dark"}/>}
                        </Button>
                        <Button variant="primary" style={{width: "250px"}} onClick={() => sendUpdateCompany()} disabled={updateLoading}>
                            Ενημέρωση {updateLoading && <Spinner size={"sm"} variant={"dark"}/>}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ManageCompaniesPage;
