import { configureStore } from '@reduxjs/toolkit';
import myDataSearchSlice from "./slices/myDataSearch_slice";
import searchInvoice_slice from "./slices/searchInvoice_slice";
import admin_slice from "./slices/Admin/admin_slice";
import manageCompanies_slice from "./slices/Admin/manageCompanies_slice";

const store = configureStore({
    reducer: {
        myDataSearch: myDataSearchSlice,
        searchInvoice: searchInvoice_slice,
        admin: admin_slice,
        manageCompanies: manageCompanies_slice,
    },
});

export default store;
