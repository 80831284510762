export const fuelData = [
    {
        id: "1",
        code: "10",
        name: "Benzine 95RON",
    },{
        id: "2",
        code: "11",
        name: "Benzine 95RON+",
    },{
        id: "3",
        code: "12",
        name: "Benzine 100RON",
    },{
        id: "4",
        code: "13",
        name: "Benzine LRP",
    },{
        id: "5",
        code: "14",
        name: "Βενζίνη αεροπλάνων",
    },{
        id: "6",
        code: "15",
        name: "Ειδικό καύσιμο αεριωθουμένων",
    },{
        id: "7",
        code: "20",
        name: "Diesel",
    },{
        id: "8",
        code: "21",
        name: "Diesel premium",
    },{
        id: "9",
        code: "30",
        name: "Diesel Heatnn",
    },{
        id: "10",
        code: "31",
        name: "Ειδικό καύσιμο αεριωθουμένων",
    },{
        id: "11",
        code: "32",
        name: "Diesel Heat premium",
    },{
        id: "12",
        code: "33",
        name: "Diesel Light",
    },{
        id: "13",
        code: "33",
        name: "Diesel άλλων χρήσεων",
    },{
        id: "14",
        code: "34",
        name: "Diesel ναυτιλίας",
    },{
        id: "15",
        code: "35",
        name: "Κηροζίνη JP1",
    },{
        id: "16",
        code: "36",
        name: "Κηροζίνη άλλων χρήσεων",
    },{
        id: "17",
        code: "37",
        name: "Μαζούτ",
    },{
        id: "18",
        code: "38",
        name: "Μαζούτ ναυτιλίας",
    },{
        id: "19",
        code: "40",
        name: "LPG (υγραέριο)",
    },{
        id: "20",
        code: "41",
        name: "Υγραέριο (LPG) και μεθάνιο βιομηχανικό/εμπορικό κινητήρων (χύδην)",
    },{
        id: "21",
        code: "42",
        name: "Υγραέριο (LPG) και μεθάνιο θέρμανσης και λοιπών χρήσεων (χύδην)",
    },{
        id: "22",
        code: "43",
        name: "Υγραέριο (LPG) και μεθάνιο βιομηχανικό/εμπορικό κινητήρων (σε φιάλες)",
    },{
        id: "23",
        code: "44",
        name: "Υγραέριο (LPG) και μεθάνιο θέρμανσης και λοιπών χρήσεων (σε φιάλες)",
    },{
        id: "24",
        code: "50",
        name: "CNG (πεπιεσμένο φυσικό αέριο)",
    },{
        id: "25",
        code: "60",
        name: "Αρωματικοί Υδρογονάνθρακες Δασμολογικής Κλάσης 2707",
    },{
        id: "26",
        code: "61",
        name: "Κυκλικοί Υδρογονάνθρακες Δασμολογικής Κλάσης 2902",
    },{
        id: "27",
        code: "70",
        name: "Ελαφρύ πετρέλαιο (WHITE SPIRIT)",
    },{
        id: "28",
        code: "71",
        name: "Ελαφριά λάδια",
    },{
        id: "29",
        code: "72",
        name: "Βιοντίζελ",
    },{
        id: "30",
        code: "999",
        name: "Λοιπές χρεώσεις υπηρεσιών",
    },
];