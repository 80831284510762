export const paymentTypesData = [
      {
          "id": "1",
          "code": "1",
          "name": "Επαγ. Λογαριασμός Πληρωμών Ημεδαπής",
      },
      {
          "id": "2",
          "code": "2",
          "name": "Επαγ. Λογαριασμός Πληρωμών Αλλοδαπής",
      },
      {
          "id": "3",
          "code": "3",
          "name": "Μετρητά",
      },
      {
          "id": "4",
          "code": "4",
          "name": "Επιταγή",
      },
      {
          "id": "5",
          "code": "5",
          "name": "Επί Πιστώσει",
      },
      {
          "id": "6",
          "code": "6",
          "name": "Web Banking",
      },
      {
          "id": "7",
          "code": "7",
          "name": "POS / e-POS",
      },
      {
          "id": "8",
          "code": "8",
          "name": "Άμεσες Πληρωμές IRIS",
      }
  ]