import React, {useRef, useState} from "react";
import Select from "react-select";

const Dropdown = (props) => {
  const colorStyles = {
    option: (styles, { data, isSelected, isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
                ? "#76AEB7"
                : isFocused
                    ? "#fff"
                    : null,
        color: isDisabled
            ? "#ccc"
            : isSelected
                ? "#fff"
                    ? "white"
                    : "black"
                : "#76AEB7",
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": {
          color: "white",
          backgroundColor: "#76AEB7",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor:
              !isDisabled && (isSelected ? "#76AEB7" : "#76AEB7"),
        },
      };
    },
    menuPortal: base => ({ ...base, zIndex: "9999 !important" }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#76AEB7",
      borderRadius: 0,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#76AEB7",
      borderRadius: 0,
      ":hover": {
        backgroundColor: "#49868f",
        color: "white",
      },
    }),
  };

  let classes = props.classes ? props.classes : props.className ? props.className : 'mb-3 w-100';
  let defaultValue = props.defaultValue ?? null;
  const dropdownRef = useRef(null);

  const scrollToBottom = () => {
    if(!dropdownRef.current.children[0].className.includes("disabled") && props.autoscroll !== false) {
      dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <React.Fragment>
      <div className={classes} ref={dropdownRef} onClick={scrollToBottom}>
        { props.label &&
          <label htmlFor={props.id} className="mr-1">
            {props.label}
            {props.required && <span className="text-danger"> *</span>}
          </label>
        }

        {
          <Select
            name = {props.name ? props.name : ''}
            id={props.id}
            closeMenuOnSelect={true}
            classNamePrefix={`${props.multiSelect === true ? "MULTI_SELECT_DO_NOT_TOUCH " : ""}scrollable-dropdown`}
            placeholder={props.placeholder}
            value={props.value ?? defaultValue ?? null}
            defaultValue={ defaultValue }
            isMulti={props.multiSelect}
            options={props.options}
            styles={colorStyles}
            isSearchable={props.isSearchable}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            inputValue={props.inputValue}
            isDisabled={props.disabled}
            menuPlacement={props.position ? props.position : 'bottom'}
            noOptionsMessage={() =>  "Δεν υπάρχουν επιλογές για αυτό το πεδίο"}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#76AEB7',
                primary: '#76AEB7',
              },
            })}
            menuPosition="fixed"
          />
        }
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
