import {invoiceTypesData} from "../_data/mydata/invoiceTypes";
import {measurementUnitsData} from "../_data/mydata/measurementUnits";
import {paymentTypesData} from "../_data/mydata/paymentTypes";
import {vatCategoryData} from "../_data/mydata/vatCategory";
import {vatZeroPerData} from "../_data/mydata/vatZeroPer";
import {characterizationType} from "../_data/mydata/characterizationType";
import {characterizationCategory} from "../_data/mydata/characterizationCategory";
import {movePurposeData} from "../_data/mydata/movePurpose";
import QR from 'qrcode-base64';
import {BACKEND_URL} from "./Environment";
import moment from "moment";
import {get as _get, isEqual as _isEqual} from "lodash";
import {fuelData} from "../_data/mydata/fuelCodes";
import {feesData} from "../_data/mydata/fees";
import {otherTaxesData} from "../_data/mydata/otherTaxes";
import {withHoldingTaxesData} from "../_data/mydata/withHoldingTaxes";
import {myDataProductLineCodingData} from "../_data/mydata/myDataProductLineCoding";

export const isEmpty = (o) => {
    return o === null || o === undefined || String(o).trim() === "" || String(o).trim() === "{}";
}

export const getArrayLastItem = (data) => {
    if (data && data.length > 0) {
        return data[data.length - 1];
    } else {
        return '';
    }
}

export const sortArrayObjectByValue = (data, field = 'id') => {
    if (data && data.length > 0) {
        return data.sort((a, b) => {
            return (a[field] > b[field]) ? 1 : -1;
        });
    } else {
        return data;
    }
}

export const checkIsISODateFormat = (date) =>  {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) {
        return false;
    }
    const d = new Date(date);
    return d.toISOString() === date;
}
export const getISOStringDateToDate = (date) => {
    let newDate = date;

    if (date !== '') {
        let isISODate = checkIsISODateFormat(date);
        if (isISODate) {
            let datetime = new Date(date);
            newDate = datetime.toLocaleDateString('en-GB');
        }
    }

    return newDate;
}

export function sanitizeHTML(html) {
    return html?.replace(/<\/?[^>]+(>|$)/g, '') ?? ""; // Remove tags
}

export const compareDocs = (oldDoc, newDoc) => {
    const changes = {};
    const excludedFields = ["created_at", "update_at", "id", "__v"];

    try {
        const compareArrays = (oldArray, newArray, path) => {
            if (newArray.length > oldArray.length) {
                newArray.forEach((element, index) => {
                    const found_element = oldArray.filter((old_element) => {
                        if (old_element.id && element.id) return old_element.id === element.id;
                        return old_element._id?.toString() === element._id?.toString();
                    });
                    delete element._id;
                    if (found_element.length > 0) {
                        delete found_element[0]._id;
                        compareValues(found_element[0], element, `${path}[${index}]`);
                    } else {
                        changes[`${path}[${index}]`] = {oldValue: undefined, newValue: element};
                    }
                });
            } else {
                oldArray.forEach((element, index) => {
                    const found_element = newArray.filter((new_element) => {
                        if (new_element.id && element.id) return new_element.id === element.id;
                        return new_element._id?.toString() === element._id?.toString();
                    });
                    delete element._id;
                    if (found_element.length > 0) {
                        delete found_element[0]._id;
                        compareValues(element, found_element[0], `${path}[${index}]`);
                    } else {
                        changes[`${path}[${index}]`] = {oldValue: element, newValue: undefined};
                    }
                });
            }
        };
        const compareValues = (oldVal, newVal, path) => {
            if (Array.isArray(newVal) && Array.isArray(oldVal)) {
                compareArrays(oldVal, newVal, path);
            } else if (newVal && oldVal && typeof newVal === 'object' && typeof oldVal === 'object') {
                const allKeys = new Set([...Object.keys(newVal), ...Object.keys(oldVal)].filter((field) => !excludedFields.includes(field)));
                allKeys.forEach(key => {
                    compareValues(_get(oldVal, key), _get(newVal, key), `${path}.${key}`);
                });
            } else {
                // We check the null value to catch cases were undefined becomes null
                if (oldVal === undefined && newVal === null) return;
                if (!_isEqual(oldVal, newVal)) {
                    changes[path] = {oldValue: oldVal, newValue: newVal};
                }
            }
        };
        const rootKeys = new Set([...Object.keys(newDoc), ...Object.keys(oldDoc)].filter((field) => !excludedFields.includes(field)));
        rootKeys.forEach(key => {
            compareValues(_get(oldDoc, key), _get(newDoc, key), key);
        });
        return Object.keys(changes).length > 0 ? JSON.stringify(changes) : null;
    } catch (err) {
        return null;
    }
}

// =================================

export const getToken = () => {
    if (localStorage.getItem("token")) {
        return localStorage.getItem("token");
    } else {
        return null;
    }
}

export const getCompany = () => {
    if (localStorage.getItem("company")) {
        return JSON.parse(localStorage.getItem("company"));
    } else {
        return null;
    }
}

export const loginCheck = () => {
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const expireDate = moment(localStorage.getItem("expire_date"), "ddd MMM DD YYYY HH:mm:ss [GMT]Z");
    const hasExpired = !(expireDate.isValid() && moment().isBefore(expireDate));
    if (hasExpired) localStorage.clear();
    return (token && company && !hasExpired);
}

export const getAccessLevel = () => {
    if (getCompany()) {
        if (getCompany()?.accessLevel === "admin") {
            return "admin";
        }
    }
    return "normal";
}

export const getCurrencyFormat = (amount, currencyCode = "EUR") => {
    try {
        // Case if the amount is not a number or empty
        if (isNaN(amount) || amount === '') {
            if (!amount?.toString().includes("%") || !amount?.toString().includes("€")) {
                amount = 0;
            }
        }
        amount = parseFloat(amount).toLocaleString('el-GR', {style:'currency', currency:currencyCode});
    } catch (e) {
        console.error(e);
        amount = parseFloat(amount).toLocaleString('el-GR', {style:'currency', currency:"EUR"});
    }
        return amount;
}

export const getMeasurementUnit = (code) => {
    const object = measurementUnitsData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getMeasurementUnitShortHand = (code) => {
    const object = measurementUnitsData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].shortName;
    return "";
}

export const getPaymentType = (code) => {
    const object = paymentTypesData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getVatCategory = (code) => {
    const object = vatCategoryData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return `${object[0].percentage}%`;
    return "";
}

export const getVatExemption = (code) => {
    const object = vatZeroPerData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].description;
    return "";
}

export const getClassificationType = (code) => {
    const object =  characterizationType.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].description;
    return "";
}

export const getClassificationCategory = (code) => {
    const object =  characterizationCategory.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].description;
    return "";
}

export const getMovePurpose = (code) => {
    const object =  movePurposeData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getFeesCategory = (code) => {
    const object =  feesData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getWithheldCategory = (code) => {
    const object =  withHoldingTaxesData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getOtherTaxesCategory = (code) => {
    const object =  otherTaxesData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getRecType = (code) => {
    const object =  myDataProductLineCodingData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getTaxType = (code) => {
    switch (code?.toString()) {
        case "1": return "Παρακρατούμενος Φόρος";
        case "2": return "Τέλη";
        case "3": return "Λοιποί Φόροι"
        case "4": return "Χαρτόσημο";
        case "5": return "Κρατήσεις"
        default: return "";
    }
}

export const getStampCategory = (code) => {
    switch (code?.toString()) {
        case "1": return "1,20%";
        case "2": return "2,40%";
        case "3": return "3,60%"
        case "4": return "Λοιπές περιπτώσεις";
        default: return "";
    }
}

export const getInvoiceType = (code) => {
    const object = invoiceTypesData.filter((el) => el.myCodeData === code?.toString().trim());
    if (object.length !== 0) return `${object[0].myCodeData} ${object[0].name}`;
    return "";
}

export const getInvoiceTypeShort = (code) => {
    const object = invoiceTypesData.filter((el) => el.myCodeData === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getInvoiceSpecialType = (code) => {
    switch (code?.toString()) {
        case "1": return "Επιδοτήσεις – Επιχορηγήσεις";
        case "2": return "Έσοδα Λιανικής Ξενοδοχείων";
        case "3": return "Λογιστική Εγγραφή"
        case "4": return "Tax Free";
        case "5": return "Σύνθετες συναλλαγές ημεδαπής αλλοδαπής";
        case "6": return "Δικαιούχοι του άρθρου 3 της υπό στοιχεία 139818 ΕΞ2022/28.09.2022 (Β’5083) κοινής υπουργικής απόφασης";
        case "7": return "Αγορά αγροτικών αγαθών υπηρεσιών Άρθρο 41 του Κώδικα ΦΠΑ";
        case "8": return "Έσοδα Λιανικών ΦΗΜ ΑΑΔΕ_1";
        case "9": return "Έσοδα Λιανικών ΦΗΜ ΑΑΔΕ_2";
        case "10": return "Έσοδα Λιανικών ΦΗΜ Επιχείρησης Απόκλιση";
        case "11": return "Επίδομα Θέρμανσης";
        case "12": return "Συναλλαγές εστίασης";
        default: return "";
    }
}

export const getFuelName = (code) => {
    const object = fuelData.filter((el) => el.code === code?.toString().trim());
    if (object.length !== 0) return object[0].name;
    return "";
}

export const getTaxCategory = (typeCode, categoryCode) => {
    switch (typeCode?.toString()) {
        case "1": return getWithheldCategory(categoryCode);
        case "2": return getFeesCategory(categoryCode);
        case "3": return getOtherTaxesCategory(categoryCode);
        case "4": return getStampCategory(categoryCode);
    }
}

export const transformDataForPrint = (invoice, printType) => {
    if (isEmpty(invoice)) return null;
    let detailsBreakpoint;
    switch (printType) {
        case "A4": {
            detailsBreakpoint = invoice.peppolDetails?.length > 0 ? 6 : 12;
            break;
        }
        case "A5": {
            detailsBreakpoint = 8;
            break;
        }
        case "A5D": {
            detailsBreakpoint = 4;
            break;
        }
        default: {
            detailsBreakpoint = 0; // NO Breakpoint
        }
    }
    const invoiceCurrency = invoice.invoiceHeader.currency ?? "EUR";
    let vatTable = {};
    let transformedData = {
        TEST_ENVIRONMENT: invoice.testEnvironment,
        TOTAL_PAGES: [],
        TOO_MANY_CUSTOMER_DETAILS: false,
        ENABLE_PEPPOL: !isEmpty(invoice.peppolDetails),
        ISSUER_LOGO: invoice.logo,

        UID: invoice.uid,
        MARK: invoice.mark,
        AUTHCODE: invoice.authCode,
        TRANSMISSION_FAILURE: invoice.transmissionFailure,
        QR_CODE: buildQRcode(`${BACKEND_URL}/${invoice.uid}`),

        ISSUER_NAME: invoice.issuer?.companyName,
        ISSUER_SMALL_NAME: invoice.issuer?.companySmallName,
        ISSUER_SUBJECTFIELD: invoice.issuer?.companyActivity,
        ISSUER_ADDRESS: invoice.issuer?.companyAddress,
        ISSUER_CITY: invoice.issuer?.companyCity,
        ISSUER_TK: invoice.issuer?.companyTk,
        ISSUER_VAT: invoice.issuer?.vatNumber,
        ISSUER_DOY: invoice.issuer?.companyDoy,
        ISSUER_PHONE: invoice.issuer?.companyPhoneNumber,
        ISSUER_GEMH: invoice.issuer?.gemh,
        ISSUER_EMAIL: invoice.issuer?.companyEmail,
        ISSUER_WEBPAGE: null,
        BRANCH: invoice.issuer?.branch,

        INVOICE_TYPE: `${getInvoiceTypeShort(invoice.invoiceHeader.invoiceType)}${invoice.invoiceHeader.totalCancelDeliveryOrders && invoice.invoiceHeader.invoiceType === "8.6" ? "(Ακυρωτικό)" : ""}`,
        INVOICE_SPECIAL_TYPE: getInvoiceSpecialType(invoice.invoiceHeader.specialInvoiceCategory),
        SERIES: invoice.invoiceHeader.series,
        NUMBER: invoice.invoiceHeader.aa,
        INVOICE_DATE: invoice.invoiceHeader.issueDate,
        INVOICE_TIME: invoice.invoiceHeader.time,
        CORRELATION: invoice.invoiceHeader.correlatedInvoices.concat(invoice.invoiceHeader.multipleConnectedMarks ?? []).join(","),
        PURPOSE: getMovePurpose(invoice.invoiceHeader.movePurpose),
        DISPATCH_TIME: (invoice.invoiceHeader.dispatchDate || invoice.invoiceHeader.dispatchTime) ? (invoice.invoiceHeader.dispatchDate ?? "") + " " + (invoice.invoiceHeader.dispatchTime ?? "") : null,
        VEHICLE_NUMBER: invoice.invoiceHeader.vehicleNumber,
        SELF_PRICING: invoice.invoiceHeader.selfPricing ? "Ναι" : null,
        TABLE_AA: invoice.invoiceHeader.tableAA,
        LOADING_ADDRESS: invoice.invoiceHeader.loadingAddress,
        DELIVERY_ADDRESS: invoice.invoiceHeader.deliveryAddress,
        START_SHIPPING_BRANCH: invoice.invoiceHeader.startShippingBranch,
        COMPLETE_SHIPPING_ADDRESS: invoice.invoiceHeader.completeShippingBranch,

        LOCATION_NAME: invoice.counterpart?.locationName,
        LOCATION_ADDRESS: invoice.counterpart?.locationAddress,
        LOCATION_CITY: invoice.counterpart?.locationCity,
        LOCATION_ZIP: invoice.counterpart?.locationZipCode,

        // CUSTOMER_CODE: invoice.counterpart.customerCode,
        CUSTOMER_NAME: invoice.counterpart?.name,
        // CUSTOMER_COMPANY: invoice.counterpart.
        CUSTOMER_ACTIVITY: invoice.counterpart?.customerActivity,
        CUSTOMER_EMAIL: invoice.counterpart?.email,
        CUSTOMER_PHONE: invoice.counterpart?.phone,
        CUSTOMER_VAT: invoice.counterpart?.vatNumber,
        CUSTOMER_DOY: invoice.counterpart?.customerDoy,
        CUSTOMER_ADDRESS: invoice.counterpart?.address,
        CUSTOMER_CITY: invoice.counterpart?.city,
        CUSTOMER_ZIP: invoice.counterpart?.postalCode,

        // SALER_NAME: "asdasd",
        // SALER_COMPANY_NAME: "asdasd",
        // SALER_EMAIL: "asdasd",
        // SALER_GEMH: "asdasd",
        // SALER_VAT: "sdaasd",
        // TAX_REPRESENTATIVE:
        // TAX_REPRESENTATIVE_VAT:

        PAYMENT_METHOD: !isEmpty(invoice.paymentMethods) && getPaymentType(invoice.paymentMethods[0].type),

        TRANSACTION_DETAILS: (invoice.paymentMethods?.length > 0 && isEmpty(invoice.peppolDetails)) ? invoice.paymentMethods
            .filter((el) => el.type !== 5)
            .slice(0, 2)
            .map((el, idx) => {
                return ({
                    ...el,
                    amountTotalString: el.amountTotalString ? getCurrencyFormat(el.amountTotalString, invoiceCurrency) : null,
                    tipAmountString: el.tipAmountString ? getCurrencyFormat(el.tipAmountString, invoiceCurrency) : null,
                    amount: el.amount ? getCurrencyFormat(el.amount, invoiceCurrency) : null,
                    paymentTypeName: getPaymentType(el.type)
                })
            }) : null,
        PRODUCT_DETAILS: invoice.invoiceDetails?.length > 0 ? [invoice.invoiceDetails.map((el, idx) => {
            const taxesValue = Number(el.otherTaxesAmount ?? 0) + Number(el.feesAmount ?? 0) + Number(el.stampDutyAmount ?? 0) - Number(el.withheldAmount ?? 0) - Number(el.deductionsAmount ?? 0);
            const total = Number(el.netValue) + Number(el.vatAmount) + taxesValue - Number(el.discount ?? 0);
            if (!vatTable[el.vatCategory]) {
                vatTable[el.vatCategory] = {
                    vatPercentage: getVatCategory(el.vatCategory),
                    amountNoVat: el.netValue + taxesValue,
                    vatAmount: el.vatAmount,
                    totalAmount: total
                }
            } else {
                vatTable[el.vatCategory]["amountNoVat"] += el.netValue + taxesValue;
                vatTable[el.vatCategory]["vatAmount"] += el.vatAmount;
                vatTable[el.vatCategory]["totalAmount"] += total;
            }
            let measurementUnit = el.measurementUnit;
            let quantity = el.quantity;
            if (isEmpty(measurementUnit)) {
                measurementUnit = el.otherMeasurementUnitTitle;
                quantity = el.otherMeasurementUnitQuantity;
            }
            return {
                CODE: el.code,
                PRODUCT_DESCRIPTION: el.name,
                QTY: measurementUnit ? `${quantity ?? ""} ${quantity ? getMeasurementUnitShortHand(measurementUnit) : ""}` : "1 Υπ.",
                PRICE_QTY: el.quantity ? getCurrencyFormat((((el.netValue * 1000) / el.quantity) / 1000).toFixed(2), invoiceCurrency) : getCurrencyFormat(0, invoiceCurrency),
                VAT_ID: el.vatCategory,
                TAXES_VALUE: taxesValue, // !!!!!!!!!!!!!!!!!!
                CPV_CODE: el.peppolCPV,
                FUEL_NAME: getFuelName(el.fuelCode),
                QTY15: el.quantity15,
                PEPPOL_MEASUREMENT_UNIT: el.peppolMeasurementUnit,
                VAT: getVatCategory(el.vatCategory),
                VATEXPCAT: getVatExemption(el.vatExemptionCategory),
                DISCOUNT: getCurrencyFormat(el.discount, invoiceCurrency),
                PRICE_NOVAT: getCurrencyFormat(el.netValue, invoiceCurrency),
                VAT_PRICE: getCurrencyFormat(el.vatAmount, invoiceCurrency),
                OTHER_TAXES: getCurrencyFormat(taxesValue.toFixed(2), invoiceCurrency),
                REC_TYPE: getRecType(el.recType),
                TOTAL: getCurrencyFormat(total.toFixed(2), invoiceCurrency),
            }
        })] : [],
        VAT_TABLE: Array.from(Object.values(vatTable)).map((el) => {
            return {
                ...el,
                amountNoVat: getCurrencyFormat(el.amountNoVat.toFixed(2), invoiceCurrency),
                vatAmount: getCurrencyFormat(el.vatAmount.toFixed(2), invoiceCurrency),
                totalAmount: getCurrencyFormat(el.totalAmount.toFixed(2), invoiceCurrency)
            }
        }),
        TAX_TABLE: invoice.taxesTotals?.length > 0 ? invoice.taxesTotals.map((el, idx) => {
            return {
                TAX_PERCENT: el.taxCategory,
                TAX_CODE: el.taxType,
                NET_VALUE: el.underlyingValue,
                TAX_VALUE: el.taxAmount,
                // OTHER_TAXES:
                // TOTAL_VALUE:
            }
        }) : null,
        VATEXPCAT: invoice.vatExemptionCategory ? invoice.invoiceDetails.map((el) => {
            return {
                vat: el.vatExemptionCategory
                // description:
            }
        }) : null,
        TOTAL_QTY: invoice.invoiceDetails?.reduce((sum, el) => sum + (el.quantity ?? 0), 0),
        TOTAL_WITHOUT_DISCOUNT: getCurrencyFormat(invoice.invoiceSummary.totalNetValue.toFixed(2), invoiceCurrency),
        TOTAL_DISCOUNT: getCurrencyFormat(invoice.invoiceDetails?.reduce((sum, el) => sum + (el.discount ?? 0), 0), invoiceCurrency),
        TOTAL_NOVAT: getCurrencyFormat(invoice.invoiceSummary.totalNetValue.toFixed(2), invoiceCurrency),
        TOTAL_VAT: getCurrencyFormat(invoice.invoiceSummary.totalVatAmount.toFixed(2), invoiceCurrency),
        TOTAL_DEDUCTIONS: getCurrencyFormat(invoice.invoiceSummary.totalDeductionsAmount.toFixed(2), invoiceCurrency),
        TOTAL_WITHHOLD: getCurrencyFormat(invoice.invoiceSummary.totalWithheldAmount.toFixed(2), invoiceCurrency),
        TOTAL_STAMP_DUTY: getCurrencyFormat(invoice.invoiceSummary.totalStampDutyAmount.toFixed(2), invoiceCurrency),
        TOTAL_OTHER_TAXES: getCurrencyFormat(invoice.invoiceSummary.totalOtherTaxesAmount.toFixed(2), invoiceCurrency),
        TOTAL_FEES: getCurrencyFormat(invoice.invoiceSummary.totalFeesAmount.toFixed(2), invoiceCurrency),
        TOTAL_TAXES_AMOUNT: getCurrencyFormat(
            (Number(invoice.invoiceSummary.totalOtherTaxesAmount) +
                Number(invoice.invoiceSummary.totalStampDutyAmount) +
                Number(invoice.invoiceSummary.totalFeesAmount)).toFixed(2),
            invoiceCurrency
        ),
        WITHHOLD_CATEGORY: getWithheldCategory(invoice.taxesTotals?.filter((el) => el.taxType === 1)[0]?.taxCategory),
        FEES_CATEGORY: getWithheldCategory(invoice.taxesTotals?.filter((el) => el.taxType === 2)[0]?.taxCategory),
        OTHER_TAX_CATEGORY: getWithheldCategory(invoice.taxesTotals?.filter((el) => el.taxType === 3)[0]?.taxCategory),
        STAMP_CATEGORY: getWithheldCategory(invoice.taxesTotals?.filter((el) => el.taxType === 4)[0]?.taxCategory),
        PRICE_WITHVAT: getCurrencyFormat(invoice.invoiceSummary.totalGrossValue.toFixed(2), invoiceCurrency),
        TOTAL_PRICE: getCurrencyFormat((Number(invoice.invoiceSummary.totalGrossValue)
            + Number(invoice.invoiceSummary.totalOtherTaxesAmount)
            + Number(invoice.invoiceSummary.totalStampDutyAmount)
            + Number(invoice.invoiceSummary.totalFeesAmount)
            - Number(invoice.invoiceSummary.totalWithheldAmount)
            - Number(invoice.invoiceSummary.totalDeductionsAmount))
            .toFixed(2), invoiceCurrency),

        // OLD_BALANCE: "1000 €",
        // NEW_BALANCE: "1000 €",
        INVOICE_NOTES: invoice.invoiceHeader.notes,

        PEPPOL: !isEmpty(invoice.peppolDetails) ? {
            DOCUMENT_REFERENCE: invoice.peppolDetails?.invoiceDocumentReference,
            PROJECT_REFERENCE: invoice.peppolDetails?.projectReference,
            PARTY_IDENTIFICATION: invoice.peppolDetails?.partyIdentification,
            CONTRACT_DOCUMENT_REFERENCE: invoice.peppolDetails?.contractDocumentReference,
            BUYER_REFERENCE: invoice.peppolDetails?.buyerReference,
            DUE_DATE: invoice.peppolDetails?.dueDate,
        } : null
    };
    // transformedData.PRODUCT_DETAILS[0].push(transformedData.PRODUCT_DETAILS[0][0])
    // transformedData.TRANSACTION_DETAILS.push(transformedData.TRANSACTION_DETAILS[0])
    // transformedData.TOTAL_TAXES.FEES = 1
    // transformedData.TOTAL_TAXES.STAMP_DUTY = 2
    // transformedData.TOTAL_TAXES.OTHER_TAXES = 3
    // transformedData.TOTAL_TAXES.AMOUNT = "6,00 €"
    // transformedData.TOTAL_DEDUCTIONS = "2,00 €"
    // transformedData.TOTAL_WITHHOLD = "2,00 €"

    // Split details by breakpoints so they get pushed to new page
    let arr = [];

    if (detailsBreakpoint && detailsBreakpoint > 0) {
        for (let i = 0; i < transformedData.PRODUCT_DETAILS[0].length; i += detailsBreakpoint) {
            const chunk = transformedData.PRODUCT_DETAILS[0].slice(i, i + detailsBreakpoint);
            arr.push(chunk);
            transformedData.TOTAL_PAGES.push(i);
        }
        transformedData.PRODUCT_DETAILS = arr;
    }
    if (transformedData.TOTAL_PAGES.length === 0) transformedData.TOTAL_PAGES.push(1);
    // console.log(transformedData);
    return transformedData;
}

export const buildQRcode = (data) => {
    return QR.drawImg(data, {
        typeNumber: 4,
        errorCorrectLevel: 'M',
        size: 150
    });
}