import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    invoice: null,
    htmlInvoice: null,
    inputIdentifier: "",
    view: "simple",
    viewType: "A4",
}

export const myDataSearchSlice = createSlice({
    name: 'myDataSearch',
    initialState: initialState,
    reducers: {
        setInvoice: (state, action) => {
            state.invoice = action.payload;
        },
        resetInvoice: (state) => {
            state.invoice = initialState.invoice;
        },
        setHtmlInvoice: (state, action) => {
            state.htmlInvoice = action.payload;
        },
        resetHtmlInvoice: (state) => {
            state.htmlInvoice = initialState.htmlInvoice;
        },
        setInputIdentifier: (state, action) => {
            state.inputIdentifier = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setViewType: (state, action) => {
            state.viewType = action.payload;
        }
    },
});

export const {
    setInvoice,
    resetInvoice,
    setHtmlInvoice,
    resetHtmlInvoice,
    setInputIdentifier,
    setView,
    setViewType,
 } = myDataSearchSlice.actions;

export default myDataSearchSlice.reducer;
