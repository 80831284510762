import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {fieldsToFormat, myDataFields} from "../myDataFields";
import {
    getClassificationCategory,
    getClassificationType,
    getCurrencyFormat, getEntityType, getFeesCategory, getFuelName,
    getInvoiceType,
    getMeasurementUnit,
    getMovePurpose, getOtherTaxesCategory,
    getPaymentType, getRecType, getStampCategory, getTaxCategory,
    getTaxType,
    getVatCategory,
    getVatExemption, getWithheldCategory, sanitizeHTML
} from "../Helpers/Helpers";

function InvoiceView(data) {
    // const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState(data);
    const cards = ["issuer", "invoiceHeader", "counterpart", "otherCorrelatedEntities", "invoiceDetails", "paymentMethods", "taxesTotals", "invoiceSummary", "peppolDetails"];

    const cardTitles = {
        issuer: "Στοιχεία Εκδότη",
        counterpart: "Στοιχεία Πελάτη",
        otherCorrelatedEntities: "Λοιπές Συσχετιζόμενες Οντότητες",
        invoiceHeader: "Στοιχεία Παραστατικού",
        invoiceSummary: "Τελικά Σύνολα",
        invoiceDetails: "Στοιχεία Ειδών",
        paymentMethods: "Τρόποι Πληρωμής",
        taxesTotals: "Σύνολα Φόρων",
        peppolDetails: "Πληροφορίες B2G",
    }

    useEffect(() => {
        setInvoice(data.invoice);
    }, [data])

    return(
        <React.Fragment>
            {/*MYDATA*/}
            <Row className={"g-3 mb-2"}>
                <Col lg={7}></Col>
                <Col lg={5} key={"myData-info"}>
                    <Card className={"primary-card myData-card"}>
                        <Card.Title className={"p-2 text-center"}>Πληροφορίες MyData</Card.Title>
                        <Card.Body>
                            <Card.Text>
                                {invoice["transmissionFailure"] && <div className={"text-center mb-2 text-danger"}>
                                    <span className={"fw-semibold text-break"}>Αποτυχία Αποστολής</span>
                                    {invoice["transmissionFailure"].toString() === "1" ?
                                        <div>Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1</div>
                                            :
                                        <div>Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2</div>
                                    }
                                </div>}
                                {invoice["testEnvironment"] && <div className={"text-center mb-2 text-danger"}>
                                    <span className={"fw-semibold text-break"}>Άκυρο παραστατικό</span>
                                        <div>Αυτή η απόδειξη είναι άκυρη και εκδίδεται μόνο για λόγους δοκιμής της εφαρμογής</div>
                                </div>}
                                <div className={"d-flex justify-content-between mb-2"}>
                                    <span>UID</span><span className={"fw-semibold invoiceValue text-break"}>{invoice["uid"]}</span>
                                </div>
                                {invoice["mark"] && <div className={"d-flex justify-content-between mb-2"}>
                                    <span>ΜΑΡΚ</span><span className={"fw-semibold invoiceValue text-break"}>{invoice["mark"]}</span>
                                </div>}
                                {invoice["authCode"] && <div className={"d-flex justify-content-between mb-2"}>
                                    <span>Auth Code</span><span className={"fw-semibold invoiceValue text-break"}>{invoice["authCode"]}</span>
                                </div>}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/*ISSUER HEADER SUMMARY*/}
            <Row xs={1} md={invoice["counterpart"] ? 3 : 2} className={"g-3 mb-4"}>
                {cards.map((card, idx) => (
                    ((card === "issuer" || card === "invoiceHeader" || card === "counterpart") && invoice[card]) &&
                    <React.Fragment>
                        <Col key={idx}>
                            <Card className={"primary-card"}>
                                <Card.Title className={"p-2 text-center"}>{cardTitles[card]}</Card.Title>
                                <Card.Body>
                                    <Card.Text>
                                        {Object.keys(invoice[card]).map((invoiceField, idx) => (
                                            myDataFields[invoiceField] &&
                                            <div className={"d-flex justify-content-between mb-2 content"}>
                                                <span>{myDataFields[invoiceField]}</span>
                                                <span className={"fw-semibold invoiceValue"}>
                                                    {
                                                        (invoiceField === "invoiceType") ? getInvoiceType(invoice[card]["invoiceType"])
                                                        :
                                                        (invoiceField === "movePurpose") ? getMovePurpose(invoice[card]["movePurpose"])
                                                        :
                                                        (invoiceField === "notes") ? sanitizeHTML(invoice[card]["notes"])
                                                        :
                                                        Array.isArray(invoice[card][invoiceField]) ? invoice[card][invoiceField].join(",")
                                                        :
                                                        invoice[card][invoiceField]
                                                    }
                                                </span>
                                            </div>
                                        ))}
                                        {(card === "counterpart" && invoice["invoiceHeader"]["otherCorrelatedEntities"]) && (
                                            <Card className={"primary-card correlated-card"}>
                                                <Card.Title className={"p-2 text-center"}>{cardTitles["otherCorrelatedEntities"]}</Card.Title>
                                                <Card.Body style={{padding: "10px"}}>
                                                    <Card.Text>
                                                        {(invoice["invoiceHeader"]["otherCorrelatedEntities"]?.length > 0) &&
                                                            invoice["invoiceHeader"]["otherCorrelatedEntities"].map((entity, index) => (
                                                                <Card style={{padding: "5px", marginBottom: "5px"}} className={"alt-card correlated-card"} key={index}>
                                                                    <div className="d-flex justify-content-between mb-2 content">
                                                                        <span>Τύπος Οντότητας:</span>
                                                                        <span className="fw-semibold invoiceValue">{getEntityType(entity.type)}</span>
                                                                    </div>
                                                                    {Object.keys(entity.entityData).map((entityField, idx) => {
                                                                        const value = entity.entityData[entityField];
                                                                        return typeof value !== 'object' ? (
                                                                            <div key={idx} className="d-flex justify-content-between mb-2 content">
                                                                                <span>{myDataFields[entityField]}</span>
                                                                                <span className="fw-semibold invoiceValue">{entityField === "branch" ? (String(value) === "0" ? "Κεντρικό" : `Υποκατάστημα ${data.invoice[0].issuer.branch}`) : value}</span>
                                                                            </div>
                                                                        ) : (
                                                                            <div key={idx} className="mb-2">
                                                                                {Object.keys(value).map((subKey) => {
                                                                                    if (subKey === "number") return;
                                                                                    return (
                                                                                        <div key={subKey} className="d-flex justify-content-between content">
                                                                                        <span>{myDataFields[subKey] || subKey}</span>
                                                                                        <span className="fw-semibold invoiceValue">{subKey === "street" ? `${value[subKey]} ${value["number"]}` :value[subKey]}</span>
                                                                                        </div>)
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Card>
                                                            ))}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </React.Fragment>
                ))}
            </Row>
            {/*DETAILS*/}
            <Row xs={1} className={"g-1 mb-4"}>
                {(invoice["invoiceDetails"]?.length > 0) &&
                <React.Fragment>
                    <Col key={Math.random()}>
                        <Card className={"primary-card"}>
                            <Card.Title className={"p-2 text-center"}>{cardTitles["invoiceDetails"]}</Card.Title>
                            <Card.Body>
                                <Row xs={1} md={invoice["invoiceDetails"].length > 2 ? 4 : 2} className={"g-2 mb-2"}>
                                {invoice["invoiceDetails"]?.map((detail, idx) => (
                                    <Col key={"detail"+idx}>
                                        <Card className={"alt-card"}>
                                            <Card.Title className={"p-1 text-center"}>{sanitizeHTML(detail["name"])}</Card.Title>
                                            <Card.Body>
                                                <Card.Text>
                                                {Object.keys(detail)?.map((invoiceField, idx) => (
                                                    (invoiceField !== "name" && myDataFields[invoiceField]) &&
                                                    <div className={"d-flex justify-content-between mb-1 content"}>
                                                        <span>{myDataFields[invoiceField]}</span>
                                                        {invoiceField === "measurementUnit" ?
                                                            <span className={"fw-semibold invoiceValue"}>{detail["measurementUnit"].toString() !== "7" ? getMeasurementUnit(detail["measurementUnit"]) : detail["otherMeasurementUnitTitle"]}</span>
                                                            :
                                                        invoiceField === "vatCategory" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getVatCategory(detail["vatCategory"])}</span>
                                                            :
                                                        invoiceField === "vatExemptionCategory" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getVatExemption(detail["vatExemptionCategory"])}</span>
                                                            :
                                                        invoiceField === "recType" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getRecType(detail["recType"])}</span>
                                                            :
                                                        invoiceField === "withheldPercentCategory" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getWithheldCategory(detail["withheldPercentCategory"])}</span>
                                                            :
                                                        invoiceField === "stampDutyPercentCategory" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getStampCategory(detail["stampDutyPercentCategory"])}</span>
                                                            :
                                                        invoiceField === "feesPercentCategory" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getFeesCategory(detail["feesPercentCategory"])}</span>
                                                            :
                                                        invoiceField === "otherTaxesPercentCategory" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getOtherTaxesCategory(detail["otherTaxesPercentCategory"])}</span>
                                                            :
                                                        ((invoiceField === "incomeClassification" || invoiceField === "expensesClassification")) ?
                                                        <div className={"flex-fill classification"}>{detail[invoiceField].map((classification) => (
                                                            Object.keys(classification)?.map((el) => (
                                                                <div className={"d-flex justify-content-between"}>
                                                                    <span>{myDataFields[el]}:</span>
                                                                    <span className={"fw-semibold invoiceValue"}>{
                                                                        el === "classificationType" ? getClassificationType(classification[el])
                                                                            :
                                                                        el === "classificationCategory" ? getClassificationCategory(classification[el])
                                                                            :
                                                                        (fieldsToFormat.includes(el) ? getCurrencyFormat(classification[el]) : classification[el])
                                                                    }</span>
                                                                </div>
                                                            ))
                                                        ))}</div>
                                                            :
                                                        invoiceField === "fuelCode" ?
                                                            <span className={"fw-semibold invoiceValue"}>{getFuelName(detail["fuelCode"])}</span>
                                                            :
                                                        <span className={"fw-semibold invoiceValue"}>
                                                            {Array.isArray(detail[invoiceField]) ?
                                                                detail[invoiceField].join(",")
                                                                :
                                                                (fieldsToFormat.includes(invoiceField) ? getCurrencyFormat(detail[invoiceField]) : detail[invoiceField])
                                                            }
                                                        </span>}
                                                    </div>
                                                ))}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </React.Fragment>}
            </Row>
            {/*PAYMENT METHODS*/}
            <Row xs={1} md={2} className={"g-3 mb-4"}>
                {(invoice["paymentMethods"]?.length > 0) &&
                    <React.Fragment>
                        <Col key={Math.random()}>
                            <Card className={"primary-card"}>
                                <Card.Title className={"p-2 text-center"}>{cardTitles["paymentMethods"]}</Card.Title>
                                <Card.Body>
                                    {invoice["paymentMethods"]?.map((method, idx) => (
                                        <Row xs={1} className="g-1 mb-2">
                                            <Col key={"payMethod"+idx}>
                                                <Card className={"paymentMethod-card"}>
                                                    <Card.Body>
                                                        <Card.Text>
                                                            {Object.keys(method)?.map((methodField, idx) => (
                                                            myDataFields[methodField] &&
                                                            <div className={"d-flex justify-content-between mb-1 content"}>
                                                                <span>{myDataFields[methodField]}</span>
                                                                <span className={"fw-semibold invoiceValue"}>
                                                                {methodField === "type" ?
                                                                getPaymentType(method[methodField])
                                                                    :
                                                                (fieldsToFormat.includes(methodField) ? getCurrencyFormat(method[methodField]) : method[methodField])
                                                                }
                                                                </span>
                                                            </div>
                                                            ))}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </React.Fragment>}
                {/*TAXES TOTALS*/}
                {(invoice["taxesTotals"]?.length > 0) &&
                    <React.Fragment>
                        <Col key={Math.random()}>
                            <Card className={"primary-card"}>
                                <Card.Title className={"p-2 text-center"}>{cardTitles["taxesTotals"]}</Card.Title>
                                <Card.Body>
                                    {invoice["taxesTotals"]?.map((tax, idx) => (
                                        <Row xs={1} className="g-1 mb-2">
                                            <Col key={"payMethod"+idx}>
                                                <Card className={"taxesTotals-card"}>
                                                    <Card.Body>
                                                        <Card.Text>
                                                            {Object.keys(tax)?.map((taxField, idx) => (
                                                            myDataFields[taxField] &&
                                                            <div className={"d-flex justify-content-between mb-1 content"}>
                                                                <span>{myDataFields[taxField]}</span>
                                                                <span className={"fw-semibold invoiceValue"}>
                                                                {taxField === "taxType" ?
                                                                getTaxType(tax[taxField])
                                                                    :
                                                                taxField === "taxCategory" ?
                                                                getTaxCategory(tax["taxType"], tax["taxCategory"])
                                                                    :
                                                                (fieldsToFormat.includes(taxField) ? getCurrencyFormat(tax[taxField]) : tax[taxField])
                                                                }
                                                                </span>
                                                            </div>
                                                            ))}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    ))}
                                </Card.Body>
                            </Card>
                        </Col>
                    </React.Fragment>}
                {/*SUMMARY*/}
                {cards.map((card, idx) => (
                    ((card === "invoiceSummary" || card === "extra" || card === "peppolDetails") && invoice[card]) &&
                    <React.Fragment>
                        <Col key={idx}>
                            <Card className={"primary-card"}>
                                <Card.Title className={"p-2 text-center"}>{cardTitles[card]}</Card.Title>
                                <Card.Body>
                                    <Card.Text>
                                        {
                                        Object.keys(invoice[card]).map((invoiceField, idx) => (
                                            myDataFields[invoiceField] &&
                                            <div className={"d-flex justify-content-between mb-2 content"}>
                                                <span>{myDataFields[invoiceField]}</span>
                                                <span className={"fw-semibold invoiceValue"}>
                                                {
                                                    invoiceField === "taxType" ?
                                                    getTaxType(invoice[card][invoiceField])
                                                    :
                                                    Array.isArray(invoice[card][invoiceField]) ?
                                                        invoice[card][invoiceField].join(",")
                                                    :
                                                    (fieldsToFormat.includes(invoiceField) ? getCurrencyFormat(invoice[card][invoiceField]) : invoice[card][invoiceField])
                                                }
                                                </span>
                                            </div>
                                            ))}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </React.Fragment>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default InvoiceView;
