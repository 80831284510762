import {getCurrencyFormat, isEmpty} from "../Helpers/Helpers";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-js-pagination";
import {invoiceTypesData} from "../_data/mydata/invoiceTypes";
import {fieldsToFormat} from "../myDataFields";
import Container from "react-bootstrap/Container";
import {ReactComponent as SelectIcon} from '../Components/icons/select.svg';

export function SearchTable(props) {
    const {
        responseData,
        tableColumns,
        activePage,
        handlePageChange,
        selectAll = false,
        handleOnChange,
        handleRowSelection,
        selectionIdentifier = "",
        handleViewItem,
        handleDoubleClickRow,
        selectedItems = [],
        showTotals = false,
        isFluid = true,
        hasAA = false,
        perPage = 20,
        minimumWidth = "1000px",
    } = props;
    const [isMobile] = useState(window.matchMedia('(max-width: 768px)').matches);
    const [tapCount, setTapCount] = useState(0);

    const handleDoubleTap = (e, data) => {
        setTapCount((prev) => prev + 1);

        setTimeout(() => {
            setTapCount(0);
        }, 300);

        if (tapCount === 1) {
            if (handleDoubleClickRow) handleDoubleClickRow(e, data);
        }
    }

    return (
        <Container fluid={isFluid}>
            {!isEmpty(responseData.data) && (
                <React.Fragment>
                    <Row className={"mt-3"}>
                        <Col md={12} className="d-flex justify-content-center justify-content-md-end">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={responseData.totalItems}
                                pageRangeDisplayed={5}
                                onChange={(page) => handlePageChange(page)}
                                itemClass="page-item"
                                disabledClass="disabled"
                                activeClass="active"
                                linkClass="page-link"
                                prevPageText={isMobile ? "<" : "Προηγούμενη"}
                                nextPageText={isMobile ? ">" : "Επόμενη"}
                                firstPageText={isMobile ? "<<" : "Πρώτη"}
                                lastPageText={isMobile ? ">>" : "Τελευταία"}
                            />
                        </Col>
                    </Row>
                    <div className={"invoicesTableContainer"} style={{minWidth: minimumWidth}}>
                        <table className={"invoicesTable mb-3"} style={{width: "100%", tableLayout: "fixed", textAlign: "center", wordBreak: "break-word"}}>
                            <colgroup>
                                {tableColumns.map((col, idx) => (
                                    <col span={1} key={`col${idx}`} style={{width: `${col.width}`}}></col>
                                ))}
                            </colgroup>
                            <thead>
                            <tr>
                                {tableColumns.map((col, idx) =>
                                    col.field === "checkbox" ?
                                        (
                                            <th key={`header${idx}`}>
                                                <div style={{
                                                    margin: "8px auto",
                                                    verticalAlign: "middle",
                                                    width: "20px",
                                                    height: "20px"
                                                }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={(e) => handleOnChange(e, "checkbox", "selectAll")}
                                                        style={{width: "100%", height: "100%"}}
                                                        className={"custom-checkbox"}
                                                    />
                                                </div>
                                            </th>
                                        ) : (
                                            <th key={`header${idx}`}>{col.name}</th>
                                        ))}
                            </tr>
                            </thead>
                            <tbody>
                            {responseData.data.map((row, rIdx) => (
                                <tr key={`trow${rIdx}`}
                                    onClick={(e) => handleRowSelection && handleRowSelection(e, selectionIdentifier ? row[selectionIdentifier] : row)}
                                    onDoubleClick={(e) => handleDoubleClickRow && handleDoubleClickRow(e, selectionIdentifier ? row[selectionIdentifier] : row)}
                                    onTouchEnd={(e) => handleDoubleClickRow && handleDoubleTap(e, selectionIdentifier ? row[selectionIdentifier] : row)}
                                    className={selectedItems.includes(row[selectionIdentifier ?? ""]) ? "selected-row" : [1, 2].includes(Number(row["transmissionFailure"])) ? "trans-fail" : rIdx % 2 === 1 ? "even-row" : ""}>
                                    {tableColumns.map((col, cIdx) => {
                                        if (col.field === "checkbox") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`} className="data-checkbox">
                                                    <div style={{margin: "8px auto", verticalAlign: "middle", width: "20px", height: "20px" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(row[selectionIdentifier ?? ""])}
                                                            style={{width: "100%", height: "100%"}}
                                                            className={"custom-checkbox"}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </td>
                                            )
                                        }
                                        if (col.field === "aa") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}>
                                                    {perPage * (activePage - 1) + rIdx + 1}
                                                </td>
                                            )
                                        }
                                        if (col.field === "invoiceType") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}
                                                    className="data-invoice-type">{row[col.field]} {invoiceTypesData.find((el) => el.myCodeData === row[col.field])?.name}</td>
                                            )
                                        } else if (col.field === "InvoiceUid") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}
                                                    className="data-uid">{row[col.field]}</td>
                                            )
                                        } else if (col.field === "InvoiceDate") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}>{row["InvoiceDate"]}</td>
                                            )
                                        } else if (fieldsToFormat.includes(col.field)) {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}>{getCurrencyFormat(row[col.field])}</td>
                                            )
                                        } else if (col.field === "actions") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`} className="tableActions">
                                                    <div
                                                        className={"d-inline-flex align-items-center justify-content-center tableActions"}>
                                                    <span className="p-2 d-inline-flex tableActions"
                                                          onClick={(e) => handleViewItem(e, selectionIdentifier ? row[selectionIdentifier] : row)}>
                                                        <SelectIcon width={22} height={22}/>
                                                    </span>
                                                    </div>
                                                </td>
                                            )
                                        } else if (col.field === "transmissionFailure") {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}>{row[col.field] === 1 ? 'Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1' : row[col.field] === 2 ? 'Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2' : ''}</td>);
                                        } else {
                                            return (
                                                <td key={`tdkey${rIdx}${cIdx}`}>{row[col.field]}</td>
                                            )
                                        }
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        { showTotals &&
                        <table className={"invoicesTotals"}>
                            <thead>
                            <tr>
                                <th key={`TotalsHeader`} style={{backgroundColor: "unset", border: "unset"}}> Γενικά Σύνολα:</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td key={`totalItemsK`} style={{fontWeight: "700", backgroundColor: "#E9ECEF"}}>Συνολικός αριθμός παραστατικών</td>
                                <td key={`totalItemsV`}>{parseFloat(responseData.totalItems).toLocaleString('el-GR')}</td>
                            </tr>
                            {responseData.totals && tableColumns.map((col, idx) => (
                                col.totalField && <tr>
                                    <td key={`totalsK-${idx}`} style={{fontWeight: "700", backgroundColor: "#E9ECEF"}}>{col.name}</td>
                                    <td key={`totalsV-${idx}`}>{getCurrencyFormat(responseData.totals[col.totalField])}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>}
                    </div>
                </React.Fragment>
            )}
        </Container>
    )
}
