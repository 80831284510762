import React, {useEffect, useRef, useState} from "react";
import "./EditableTable.css"
import {isEmpty} from "../../Helpers/Helpers";

const EditableTable = (props) => {
    const {
        tableName,
        fieldName,
        title,
        tableColumns=[],
        tableData=[],
        onBlur,
        onChange,
        onAddRow,
        onDeleteRow,
        firstCellField,
    } = props;
    const [data, setData] = useState(tableData.map((el, idx) => ({...el, id: idx})));
    const [editingCell, setEditingCell] = useState(null);
    const firstCellRef = useRef(null);

    useEffect(() => {
        setData(tableData.map((el, idx) => ({...el, id: idx})));
    }, [tableData]);

    const handleChange = (id, field, value) => {
        if (field === "branchId" && value === "0") return;
        setData((prevData) =>
            prevData.map((row) =>{
                    return row.id === id ? { ...row, [field]: value } : row
                }
            )
        );
    };

    const handleCellClick = (id, field) => {
        setEditingCell({ id, field });
    };

    const handleBlur = () => {
        setEditingCell(null);
        if (onBlur) {
            onBlur(data, tableName, fieldName);
        }
    };

    const handleAddRow = (fromEdit) => {
        const newRow = { id: data.length, ...Object.fromEntries(tableColumns.map((el) => [el.field, ""])) };
        setData((prevData) => [...prevData, newRow]);
        if (fromEdit) {
            const id = newRow.id;
            setEditingCell({ id: id, field: firstCellField });
            setTimeout(() => {
                firstCellRef.current?.focus();
            }, 0);
        }
    };

    const handleDeleteRow = (id) => {
        const newData = data.filter((row) => row.id !== id).map((el, idx) => ({...el, id: idx}));
        setData(newData);
        if (onDeleteRow) {
            onDeleteRow(newData, tableName, fieldName);
        }
    };

    const handleKeyDown = (e) => {
        if (e.code === "Enter") {
            // if(Object.entries(data[data.length - 1]).filter(([key]) => key !== "id").every(([prop, value]) => value === "")) return;
            handleAddRow(true);
        }
    }

    return (
        <div className={"editable-table"} onKeyDown={(e) => handleKeyDown(e)}>
            <div style={{fontSize: "20px", fontWeight: "600", textDecoration: "underline", display: "flex", alignItems: "center", gap: "15px"}}>
                <div>{title}</div>
                <button className={"add-row-btn ml-5"} onClick={() => handleAddRow()}><span>+</span></button>
            </div>
            <table style={{width: "100%"}}>
                <thead>
                <tr>
                {tableColumns.map((column, idx) => (
                        <th key={`header-${idx}`} style={{width: column.width, textAlign: "center", fontSize: "14px"}}>{column.name}</th>
                ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, idx) => (
                    <tr key={`${row}-${idx}`}>
                        {tableColumns.map((column, cellIdx) => (
                            <td key={`data-${idx}-${column.field}`} style={{width: column.width, textAlign: "center"}}
                                className={editingCell?.id === idx && editingCell?.field === column.field ? "selected" : ""}
                                onClick={() => handleCellClick(idx, column.field)}>
                                {editingCell?.id === idx && editingCell?.field === column.field ? (
                                    <input
                                        ref={idx === data.length - 1 && cellIdx === 0 ? firstCellRef : null}
                                        type={column.type ?? "text"}
                                        value={row[column.field]}
                                        onChange={(e) =>
                                            handleChange(idx, column.field, e.target.value)
                                        }
                                        onBlur={handleBlur}
                                        style={{
                                            width: "100%",
                                            boxSizing: "border-box",
                                            border: "none",
                                            outline: "none",
                                            textAlign: "center"
                                        }}
                                        autoFocus
                                    />
                                ) : (
                                    row[column.field]
                                )}
                            </td>))}
                        <td style={{width: "5%"}} className={"remove-row-td"}>
                            <button className={"remove-row-btn"} onClick={() => handleDeleteRow(idx)}><span>-</span>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default EditableTable;
