export const measurementUnitsData = [
    {
        "id": "1",
        "code": "1",
        "name": "Τεμάχια",
        "shortName": "Τεμ.",
        "isActive": "true"
    },
    {
        "id": "2",
        "code": "2",
        "name": "Κιλά",
        "shortName": "Κιλά",
        "isActive": "true"
    },
    {
        "id": "3",
        "code": "3",
        "name": "Λίτρα",
        "shortName": "Λιτ.",
        "isActive": "true"
    },
    {
        "id": "4",
        "code": "4",
        "name": "Μέτρα",
        "shortName": "Μέτρα",
        "isActive": "true"
    },
    {
        "id": "5",
        "code": "5",
        "name": "Τετραγωνικά Μέτρα",
        "shortName": "Τ.Μ.",
        "isActive": "true"
    },
    {
        "id": "6",
        "code": "6",
        "name": "Κυβικά Μέτρα",
        "shortName": "Κ.Μ.",
        "isActive": "true"
    }
]