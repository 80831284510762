import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {BACKEND_URL} from "../../Helpers/Environment";
import axios from "axios";
import {toast} from "react-toastify";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {isEmpty} from "../../Helpers/Helpers";

function ApisPage() {
    const [apis, setAPIs] = useState([]);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const defaultAPI = {
        name: "",
        APIalias: "",
        apiMethod: "POST",
        url: ""
    }
    const [newAPI, setNewAPI] = useState({...defaultAPI});

    const [editAPI, setEditAPI] = useState({...defaultAPI});

    useEffect(() => {
        fetchAPIs();
    }, [])

    useEffect(() => {
        if (show === true) {
            setNewAPI({...defaultAPI});
        }
    }, [show]);

    const fetchAPIs = () => {
        axios.get(`${BACKEND_URL}/api/admin/get-apis`, {
            headers: { "Content-Type": "application/json" },
            params: {},
        }).then((res) => {
            if (res.data.status === "200") {
                setAPIs(res.data.data);
            } else {
                toast.error(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleOnChangeNew = (e, type, name) => {
        if (type === "input" || type === "dropdown") {
            setNewAPI({...newAPI, [name] : e.target.value});
        }
    }

    const handleOnChangeEdit = (e, type, name) => {
        if (type === "input" || type === "dropdown") {
            setEditAPI({...editAPI, [name] : e.target.value});
        }
    }

    const newAPIValid = () => {
        return !isEmpty(newAPI.name) && !isEmpty(newAPI.APIalias) && !isEmpty(newAPI.url);
    }

    const editAPIValid = () => {
        return !isEmpty(editAPI.name) && !isEmpty(editAPI.APIalias) && !isEmpty(editAPI.url);
    }

    const createNewAPI = () => {
        if (!newAPIValid()) {
            toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία.");
        } else {
            axios.post(`${BACKEND_URL}/api/admin/new-api`, {...newAPI}, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.data.status === "200") {
                    toast.success("Το API δημιουργήθηκε.");
                    setShow(false);
                    fetchAPIs();
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    const editAnAPI = () => {
        if (!editAPIValid()) {
            toast.error("Παρακαλώ συμπληρώστε όλα τα πεδία.");
        } else {
            axios.post(`${BACKEND_URL}/api/admin/update-api`, {...editAPI}, {
                headers: { "Content-Type": "application/json" },
            }).then((res) => {
                if (res.data.status === "200") {
                    toast.success("Το API αποθηκεύτηκε.");
                    setShowEdit(false);
                    fetchAPIs();
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Σφάλμα κατά την αποστολή αιτήματος.");
            })
        }
    }

    return (
        <React.Fragment>
            <Container>
                <Row className={"mt-3"}>
                    <Col md={12}>
                        <Button className={"ml-3"} onClick={() => setShow(true)}>Προσθήκη νέου API</Button>
                    </Col>
                </Row>
                {apis.length > 0 && (
                    <Row md={2} className={"mt-3"}>
                        {apis.map((api) => (
                            <Col className={"mb-4"}>
                                <div style={{border: "2px solid #6ea2a9", borderRadius: "5px", padding: "8px"}}>
                                    <div className={"mb-2"}>
                                        <strong>Όνομα:</strong> {api["name"]}<br/>
                                        <strong>APIalias:</strong> {api["APIalias"]}<br/>
                                        <strong>URL:</strong> {api["url"]}<br/>
                                        <strong>Τύπος Κλήσης:</strong> {api["apiMethod"]}<br/>
                                    </div>
                                    <Button variant={"primary"} style={{cursor: "pointer", padding: "10px 20px"}} onClick={() => {
                                        setEditAPI(api);
                                        setShowEdit(true);
                                    }}>Τροποποίηση</Button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Δημιουργία νέου API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"mb-1"}>Όνομα</div>
                    <InputGroup className="mb-1">
                        <Form.Control
                            placeholder="Εισάγετε Όνομα"
                            value={newAPI.name}
                            onChange={(e) => handleOnChangeNew(e, "input", "name")}
                        />
                    </InputGroup>
                    <div className={"mb-1"}>APIalias</div>
                    <InputGroup className="mb-1">
                        <Form.Control
                            placeholder="Εισάγετε APIalias"
                            value={newAPI.APIalias}
                            onChange={(e) => handleOnChangeNew(e, "input", "APIalias")}
                        />
                    </InputGroup>
                    <div className={"mb-1"}>URL</div>
                    <InputGroup className="mb-1">
                        <Form.Control
                            placeholder="Εισάγετε URL"
                            value={newAPI.url}
                            onChange={(e) => handleOnChangeNew(e, "input", "url")}
                        />
                    </InputGroup>
                    <div className={"mb-1"}>Τύπος Κλήσης</div>
                    <Form.Select onChange={(e) => handleOnChangeNew(e, "dropdown", "apiMethod")} value={newAPI.apiMethod} className={"mb-3"}>
                        <option>POST</option>
                        <option>GET</option>
                    </Form.Select>
                    {/*<Alert variant={"secondary"}>Διαθέσιμα wildcards για το URL: {"{MARK}"}, {"{UID}"}</Alert>*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => createNewAPI()} disabled={!newAPIValid()}>Αποθήκευση</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEdit} onHide={() => setShowEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Επεξεργασία API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"mb-1"}>Όνομα</div>
                    <InputGroup className="mb-1">
                        <Form.Control
                            placeholder="Εισάγετε Όνομα"
                            value={editAPI.name}
                            onChange={(e) => handleOnChangeEdit(e, "input", "name")}
                        />
                    </InputGroup>
                    <div className={"mb-1"}>APIalias</div>
                    <InputGroup className="mb-1">
                        <Form.Control
                            placeholder="Εισάγετε APIalias"
                            value={editAPI.APIalias}
                            onChange={(e) => handleOnChangeEdit(e, "input", "APIalias")}
                        />
                    </InputGroup>
                    <div className={"mb-1"}>URL</div>
                    <InputGroup className="mb-1">
                        <Form.Control
                            placeholder="Εισάγετε URL"
                            value={editAPI.url}
                            onChange={(e) => handleOnChangeEdit(e, "input", "url")}
                        />
                    </InputGroup>
                    <div className={"mb-1"}>Τύπος Κλήσης</div>
                    <Form.Select onChange={(e) => handleOnChangeEdit(e, "dropdown", "apiMethod")} value={editAPI.apiMethod} className={"mb-3"}>
                        <option>POST</option>
                        <option>GET</option>
                    </Form.Select>
                    {/*<Alert variant={"secondary"}>Διαθέσιμα wildcards για το URL: {"{MARK}"}, {"{UID}"}</Alert>*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => editAnAPI()} disabled={!editAPIValid()}>Αποθήκευση</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ApisPage;
