import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import {getCompany, getToken} from "./Helpers/Helpers";
import axios from "axios";
import {redirectTo} from "./navigation";

axios.interceptors.request.use(
    (request) => {
        const token = getToken();
        const company = getCompany();
        if (token) request.headers['Authorization'] = 'Bearer ' + encodeURIComponent(token)
        if (company) request.headers["Company"] = company._id;

        return request;
    },
    (error) => {
        Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        if(response?.data?.status === "401") {
            localStorage.clear();
            redirectTo("/connect/login");
            return response;
        } else {
            return response;
        }
    }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)