let navigate = null;

export const setNavigate = (nav) => {
    navigate = nav;
};

export const redirectTo = (path, state = {}) => {
    if (navigate) {
        navigate(path, { state: state });
    } else {
        console.error("Navigate function is not set.");
    }
};