import React from "react";
import {epayLogoBase64, vivaLogoBase64} from "./logos";
import {isEmpty, sanitizeHTML} from "../../Helpers/Helpers";

const InvoicePrintA5 = React.forwardRef((props, ref) => {
    require("./invoicePrint-a5.css");

    let templateData = [];
    if (!props.data) return(<div></div>);
    templateData = props.data;

    let showWaterMark = templateData.TEST_ENVIRONMENT;

    const marginzero = {
        margin: "0",
    }
    const transactionDetailsBlock = {
        border: "1px solid #555",
        padding: "2px 5px",
        borderRadius: "8px",
        fontSize: "8px",
        overflow: "hidden",
        overflowWrap: "break-word",
        textAlign: "center",
        fontWeight: "500",
    }

    const balanceContainer = {
        width: "100%",
        fontSize: "10px",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #555",
        borderRadius: "8px",
        padding: "2px",
        fontWeight: "bold",
        textAlign: "center",
    }

    const totalPagesA = templateData.TOTAL_PAGES !== undefined ? templateData.TOTAL_PAGES : [];

    return(
        <React.Fragment>
            <div style={marginzero} className={"a5-print-container"} ref={ref}>
                { totalPagesA.length > 0 && totalPagesA.map((currentPage1,index) => (
                    <React.Fragment>
                        <div className="sales-print-template-show a5-page-container" style={{marginTop: "20px"}}>
                            <div className="a5-receipt a5-invoice-box">
                                <div className="a5-top_table">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tr className="top">
                                            <td>
                                                <table className="a5-header_table">
                                                    <tr>
                                                        <td className="a5-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5-issuer_name a5-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5-issuer_subjectField a5-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5-issuer_address a5-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5-issuer_address a5-skin">{templateData.BRANCH !== "" && templateData.BRANCH !== "Κεντρικό" ? "Υποκατάστημα: " : ""}{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5-issuer_vat a5-skin">ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5-issuer_vat a5-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <div className="a5-main_info">
                                        <table cellPadding="0" cellSpacing="0">
                                            <tr>
                                                <table className="a5-main_info_table">
                                                    <tr className="a5-heading">
                                                        <td className="a5-p">ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</td>
                                                        <td className="a5-p">ΣΕΙΡΑ</td>
                                                        <td className="a5-p">ΑΡΙΘΜΟΣ</td>
                                                        <td className="a5-p">ΗΜΕΡ/ΝΙΑ</td>
                                                        <td className="a5-p">ΩΡΑ</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="a5-invoice_type">{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                        <td><span className="a5-invoice_number">{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                                        <td><span className="a5-invoice_number">{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                        <td><span className="a5-invoice_date">{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                        <td><span className="a5-invoice_time">{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                    </tr>
                                                </table>
                                            </tr>
                                        </table>
                                    </div>
                                    <table className="a5-information_table" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                        <tr>
                                            <td className="a5-information_td_left">
                                                <div className="a5-information left">
                                                    <table className="a5-information_customer">
                                                        <tr>
                                                            <td className="a5-information_customer_td a5-skin a5-bold"><span> ΟΝΟΜΑΤΕΠΩΝΥΜΟ</span></td>
                                                            <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_NAME}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-skin a5-bold"><span> ΕΠΑΓΓΕΛΜΑ</span></td>
                                                            <td className="a5-info_value"><span>: </span><span className="a5-counterparty_activity">ΙΔΙΩΤΗΣ</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="5a5-skin a5-bold"><span> EMAIL ΠΕΛΑΤΗ</span></td>
                                                            <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_EMAIL}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-skin a5-bold"><span> ΔΙΕΥΘΥΝΣΗ</span></td>
                                                            <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.CUSTOMER_ADDRESS}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5-skin a5-bold"><span> ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</span></td>
                                                            <td className="a5-info_value"><span>: </span><span className="a5-counterparty_name">{templateData.PAYMENT_METHOD}</span></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="a5-product_container">
                                    <table className="a5-product_table" cellPadding="0" cellSpacing="0">
                                        <tr className="a5-heading">
                                            <td className="a5-code_head_td a5-p">ΚΩΔΙΚΟΣ</td>
                                            <td className="a5-description_head_td a5-p">ΠΕΡΙΓΡΑΦΗ</td>
                                            <td className="a5-quantity_head_td a5-p">ΠΟΣ.</td>
                                            <td className="a5-up_head_td a5-p">ΤΙΜΗ ΜΟΝΑΔΑΣ</td>
                                            <td className="a5-vat_head_td a5-p">ΦΠΑ %</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΕΚΠΤΩΣΗ</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΤΙΜΗ ΠΡΟ ΦΠΑ</td>
                                            <td className="a5-pricenovat_head_td a5-p">ΑΞΙΑ ΦΠΑ</td>
                                            <td className="a5-whtax_head_td a5-p">ΦΟΡΟΙ/ΤΕΛΗ</td>
                                            <td className="a5-price_head_td a5-p">ΤΕΛΙΚΗ ΑΞΙΑ</td>
                                        </tr>
                                        {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) => (
                                            <tr className="a5-products" key={Math.random()}>
                                                <td><span className="a5-item_code">{product.CODE}</span></td>
                                                <td><span className="a5-item_description">{product.PRODUCT_DESCRIPTION}</span></td>
                                                <td><span className="a5-item_quantity">{product.QTY}</span></td>
                                                <td><span className="a5-item_unit_price">{product.PRICE_QTY}</span></td>
                                                <td><span className="a5-item_vat">{product.VAT}</span></td>
                                                <td><span className="a5-item_discount">{product.DISCOUNT}</span></td>
                                                <td><span className="a5-item_price_no_vat">{product.PRICE_NOVAT}</span>
                                                </td>
                                                <td><span className="a5-item_vat_price">{product.VAT_PRICE}</span></td>
                                                <td><span className="a5-item_other_taxes">{product.OTHER_TAXES}</span>
                                                </td>
                                                <td><span className="a5-item_total_price">{product.TOTAL}</span></td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                                <div className="a5-footer_container">
                                    <table className="a5-footer_table" style={{tableLayout: "fixed", width: "100%"}}>
                                        <tbody>
                                        <tr>
                                            <td className="a5-vat_total_td">
                                                {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                                    <div style={balanceContainer}>
                                                        <div>
                                                            <div style={{margin: "auto"}}>Προηγ. Υπόλοιπο</div>
                                                            <div style={{
                                                                margin: "auto",
                                                                fontWeight: "normal"
                                                            }}>{templateData.OLD_BALANCE}</div>
                                                        </div>
                                                        <div>
                                                            <div style={{margin: "auto"}}>Νέο Υπόλοιπο</div>
                                                            <div style={{
                                                                margin: "auto",
                                                                fontWeight: "normal"
                                                            }}>{templateData.NEW_BALANCE}</div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="a5-per_vat_totals_container">
                                                    <table className="a5-totals_per_vat">
                                                        <tr style={{lineHeight: 1}}>
                                                            <th>Συντ.<br/>ΦΠΑ</th>
                                                            <th>Καθαρή<br/>Αξία</th>
                                                            <th>Αξία<br/>ΦΠΑ</th>
                                                            {/*<th>Φόροι/<br/>Τέλη</th>*/}
                                                            <th>Τελική<br/>Αξία</th>
                                                        </tr>
                                                        {!isEmpty(templateData.VAT_TABLE) && index + 1 === totalPagesA.length && templateData.VAT_TABLE.map((el) => (
                                                            <tr key={Math.random()}>
                                                                <td>{el.vatPercentage}</td>
                                                                <td>{el.amountNoVat}</td>
                                                                <td>{el.vatAmount}</td>
                                                                {/*<td>{el.OTHER_TAXES}</td>*/}
                                                                <td>{el.totalAmount}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </div>
                                                <div className="a5-cont_notation">
                                                    {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => (
                                                        <React.Fragment>
                                                            <span className="skin bold">ΑΠΑΛΛΑΓΗ ΑΠΟ ΤΟ Φ.Π.Α. :</span>
                                                            <div className="a5-cont_notation_inner">
                                                                <span>{reason.vat} {reason.description} <br/></span>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                    <span className="a5-skin a5-bold">ΠΑΡΑΤΗΡΗΣΕΙΣ: {templateData.INVOICE_NOTES && <span>{sanitizeHTML(templateData.INVOICE_NOTES)}</span>}</span>
                                                </div>
                                            </td>
                                            {((!isEmpty(templateData.TRANSACTION_DETAILS) && index + 1 === totalPagesA.length) && (
                                                <td className={"a5-information_td_middle"}>
                                                    {templateData.TRANSACTION_DETAILS.map((transaction, idx) => {
                                                        return (false) ? (
                                                            // {(transaction.signature) ? (
                                                            <div style={{
                                                                ...transactionDetailsBlock,
                                                                width: "100%",
                                                                fontSize: "8px",
                                                                height: "140px"
                                                            }}>
                                                                {transaction.isEuronet === "true" && (
                                                                    <React.Fragment>
                                                                        <img style={{margin: "0", width: "20mm"}}
                                                                             className={"mb-2"}
                                                                             src={epayLogoBase64}
                                                                             alt={"ePay Logo"}/>
                                                                        <br/>
                                                                        <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                                    </React.Fragment>
                                                                )}
                                                                {transaction.isEuronet === "viva" && (
                                                                    <React.Fragment>
                                                                        <img style={{margin: "0", width: "15mm"}}
                                                                             src={vivaLogoBase64}
                                                                             alt={"Viva Logo"}/>
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                                <table className={"transactionDetailsTable"}
                                                                       style={{
                                                                           width: "100%",
                                                                           tableLayout: "fixed",
                                                                           fontSize: "8px"
                                                                       }} cellSpacing={0} cellPadding={0}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                        <td>{transaction.transactionDate}</td>
                                                                    </tr>
                                                                    {transaction.tid && <tr>
                                                                        <td>TID/MID: {transaction.tid} / {transaction.merchantId ?? ""}</td>
                                                                    </tr>}
                                                                    <tr>
                                                                        <td>ΑΡ.
                                                                            ΣΥΝ.: {transaction.transactionNumber}</td>
                                                                        <td>ΕΓΚΡΙΣΗ: {transaction.authorizationCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>RRN.:</td>
                                                                        <td>{transaction.rnn}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΜΕΣΩ:
                                                                            {transaction.isEuronet === "true" && `EPAY`}
                                                                            {transaction.isEuronet === "viva" && `VIVA`}
                                                                        </td>
                                                                        <td>{transaction.cardType} {transaction.aid} {transaction.panCard} - {transaction.finalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΠΟΣΟ:</td>
                                                                        <td>{transaction.amountTotalString} €</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                        <td>{transaction.tipAmountString} €</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                        <td style={{wordBreak: "break-all"}}>{transaction.signature}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                                                        <td>{transaction.transactionId}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <div style={{
                                                                ...transactionDetailsBlock,
                                                                width: "100%",
                                                                height: "140px"
                                                            }}>
                                                                <table className={"transactionDetailsTable"}
                                                                       style={{
                                                                           width: "100%",
                                                                           tableLayout: "fixed"
                                                                       }}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                        <td>{transaction?.paymentTypeName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΗΜ/ΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                        <td>{transaction?.transactionDate ?? `${templateData.INVOICE_DATE} ${templateData.INVOICE_TIME}`}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>ΠΟΣΟ</td>
                                                                        <td>{transaction?.amount}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )
                                                    })}
                                                </td>
                                            ))}
                                            <td className="a5-total_td_block">
                                                <div className="a5-count_totals_container">
                                                    <span>ΣΥΝΟΛΟ ΤΕΜΑΧΙΩΝ: </span> <span
                                                    className="a5-count_total_prods"> {index + 1 === totalPagesA.length ? templateData.TOTAL_QTY : ''}</span>
                                                </div>
                                                <div className="a5-total_container">
                                                    <div className="a5-totals">
                                                        <table className="a5-totals_table">
                                                            <tr>
                                                                <td className="a5-text-left a5-p">ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</td>
                                                                <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_NOVAT : ''}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="a5-text-left a5-p">Φ.Π.Α.</td>
                                                                <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_VAT : ''}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="a5-text-left a5-p">ΤΕΛΙΚΟ ΣΥΝΟΛΟ</td>
                                                                <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                                </td>
                                                            </tr>
                                                            {templateData.TOTAL_WITHHOLD && (
                                                                <tr>
                                                                    <td className="a5-text-left a5-p">ΣΥΝ. ΠΑΡΑΚ. ΦΟΡΩΝ
                                                                    </td>
                                                                    <td className="a5-text-right a5-p">
                                                                <span
                                                                    className="a5-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_WITHHOLD : ''}</span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr className="a5-blank_row a5-bordered">
                                                                <td className="text-left">&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="a5-text-left a5-finalprice a5-p">ΠΛΗΡΩΤΕΟ
                                                                    ΠΟΣΟ
                                                                </td>
                                                                <td className="a5-text-right a5-p">
                                                                    <span
                                                                        className="a5-totalpayment">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className={"mb-0 bottom-container"}>
                                                {((templateData.TRANSMISSION_FAILURE && (templateData.TRANSMISSION_FAILURE === "1" || templateData.TRANSMISSION_FAILURE === "2") && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                    <div>
                                                        <span className="a5-sender_sign">www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                                        <br/>
                                                        <span className="a5-sender_sign">Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                                    </div>
                                                }
                                                {/*<br/>*/}
                                                {index + 1 === totalPagesA.length && (
                                                    <div className="a5-mydata_sign">
                                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                            <span className="a5-uid_sign">Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                            <span className="a5-uid_sign">Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                                        {(templateData.TRANSMISSION_FAILURE === "3" && templateData.MYDATADESTINATION === "MyData") && (
                                                            <span>Απώλεια Διασύνδεσης ERP – Α.Α.Δ.Ε. - Transmission Failure_3<br/></span>
                                                        )}
                                                        {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                                            <span
                                                                className="a5-uid_sign"> UID: {templateData.UID}</span>}
                                                        {templateData.MARK && templateData.MARK !== "" &&
                                                            <span> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                                                        {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                                            <span
                                                                className="a5-uid_sign"><br/> AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                                                    </div>
                                                )}
                                            </td>
                                            {templateData.UID !== "" && (
                                                <td className="a5-cont_signs">
                                                    <img src={templateData.QR_CODE} alt="Qr code"
                                                         className="a5-product-qr-code-img"/>
                                                </td>
                                            )}
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="a5-paging">Σελίδα: {index + 1} από {totalPagesA.length}</div>
                                </div>
                                {showWaterMark && <div className="a5-cancel">ΑΚΥΡΟ</div>}
                            </div>
                        </div>
                        {index + 1 !== totalPagesA.length && (
                            <div className="page-break"></div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    )
})

export default InvoicePrintA5;
