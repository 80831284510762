export const vatCategoryData = [
    {
        "id": "1",
        "code": "1",
        "description": "ΦΠΑ Συντελεστής 24%",
        "percentage": "24",
        "reducedVat": "17",
        "deactivated": "false",
        "defaultValue": "true"
    },
    {
        "id": "2",
        "code": "2",
        "description": "ΦΠΑ Συντελεστής 13%",
        "percentage": "13",
        "reducedVat": "9",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "3",
        "code": "3",
        "description": "ΦΠΑ Συντελεστής 6%",
        "percentage": "6",
        "reducedVat": "4",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "4",
        "code": "4",
        "description": "ΦΠΑ Συντελεστής 17%",
        "percentage": "17",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "5",
        "code": "5",
        "description": "ΦΠΑ Συντελεστής 9%",
        "percentage": "9",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "6",
        "code": "6",
        "description": "ΦΠΑ Συντελεστής 4%",
        "percentage": "4",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "7",
        "code": "7",
        "description": "Άνευ Φ.Π.Α. (με 0%)",
        "percentage": "0",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "8",
        "code": "8",
        "description": "Εγγραφές χωρίς ΦΠΑ π.χ μισθοδοσία,αποσβέσεις",
        "percentage": "0",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "9",
        "code": "9",
        "description": "ΦΠΑ συντελεστής 3% (αρ.31 ν.5057/2023)",
        "percentage": "3",
        "deactivated": "false",
        "defaultValue": "false"
    },
    {
        "id": "10",
        "code": "10",
        "description": "ΦΠΑ συντελεστής 4% (αρ.31 ν.5057/2023)",
        "percentage": "4",
        "deactivated": "false",
        "defaultValue": "false"
    }
]