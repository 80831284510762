export const myDataFields = {
    vatNumber: "Α.Φ.Μ.",
    country: "Χώρα",
    branch: "Εγκατάσταση",
    name: "Όνομα",
    address: "Διεύθυνση",
    street: "Οδός",
    postalCode: "Τ.Κ.",
    city: "Πόλη",
    locationName: "Τοποθεσία Αναχώρησης",
    locationAddress: "Τοποθεσία Άφιξης",
    locationCity: "Πόλη Άφιξης",
    locationZipCode: "Τ.Κ. Άφιξης",
    locationVatNumber: "Α.Φ.Μ. Παραλήπτη",
    locationCompanyName: "Επωνυμία Παραλήπτη",
    series: "Σειρά",
    aa: "Αριθμός",
    issueDate: "Ημ/νία Έδκοσης",
    dispatchDate: "Ημ/νία Αποστολής",
    dispatchTime: "Ώρα Αποστολής",
    invoiceType: "Είδος Παραστατικού",
    currency: "Νόμισμα",
    exchangeRate: "Συνάλλαγμα",
    correlatedInvoices: "Σχετικά παραστατικά",
    selfPricing: "Αυτοτιμολόγηση",
    fuelInvoice: "Τιμή Καυσίμου",
    vehicleNumber: "Αριθμός Οχήματος",
    movePurpose: "Σκοπός Διακίνησης",
    specialInvoiceCategory: "Ειδική Κατηγορία Τιμολογίου",
    thirdPartyCollection: "",
    isDeliveryNote: "Δελτίο Αποστολής",
    loadingAddress: "Τόπος Φόρτωσης",
    deliveryAddress: "Τόπος Παράδοσης",
    startShippingBranch: "Κατάστημα Αποστολής",
    completeShippingBranch: "Κατάστημα παραλαβής",
    tableAA: "Αριθμός Τραπεζιού",
    multipleConnectedMarks: "Σχετικά ΜΑΡΚ",
    totalCancelDeliveryOrders: "Σύνολο Ακυρωθέντων Παραγγελιών",
    code: "Κωδικός",
    peppolCPV: "CPV(peppol)",
    peppolMeasurementUnit: "Μονάδα Μέτησης peppol",
    lineNumber: "Αριθμός Γραμμής",
    recType: "Είδος Γραμμής",
    measurementUnit: "Μονάδα Μέτρησης",
    quantity: "Ποσότητα",
    quantity15: "Ποσότητα στους 15 βαθμούς Κελισίου",
    otherMeasurementUnitQuantity: "Ποσότητα",
    invoiceDetailType: "Τύπος Είδους",
    fuelCode: "Κωδικός Καυσίμων",
    netValue: "Καθαρή Αξία",
    vatCategory: "Κατηγορία Φ.Π.Α.",
    vatAmount: "Φ.Π.Α.",
    vatExemptionCategory: "Κατηγορία Εξαίρεσης Φ.Π.Α.",
    withheldPercentCategory: "Κατηγορία Παρακράτησης",
    withheldAmount: "Παρακράτηση",
    stampDutyPercentCategory: "Κατηγορία Χαρτοσήμου",
    stampDutyAmount: "Ποσό Χαρτοσήμου",
    feesPercentCategory: "Κατηγορία Τελών",
    feesAmount: "Ποσό Τελών",
    otherTaxesPercentCategory: "Κατηγορία Λοιπών Φόρων",
    otherTaxesAmount: "Ποσό Λοιπών Φόρων",
    deductionsAmount: "Ποσό Κρατήσεων",
    discount: "Έκπτωση",
    lineComments: "Σχόλια",
    totalGrossValue: "Συνολική Αξία",
    totalNetValue: "Σύνολο Καθαρής Αξίας",
    totalVatAmount: "Σύνολο Φ.Π.Α.",
    totalDeductionsAmount: "Σύνολο Κρατήσεων",
    totalWithheldAmount: "Σύνολο Παρακρατήσεων",
    totalFeesAmount: "Συνολικά Τέλη",
    totalOtherTaxesAmount: "Σύνολο Λοιπών Φόρων",
    totalStampDutyAmount: "Σύνολο Χαρτοσήμου",
    notes: "Σημειώσεις",
    phone: "Τηλέφωνο",
    email: "Email",
    time: "Ώρα",
    companyName: "Επωνυμία",
    companySmallName: "Διακριτικός Τίτλος",
    companyDoy: "Δ.Ο.Υ.",
    companyAddress: "Διεύθυνση",
    companyCity: "Πόλη",
    companyTk: "Τ.Κ.",
    companyEmail: "Email",
    companyPhoneNumber: "Τηλέφωνο",
    companyMobilePhoneNumber: "Κινητό",
    companyActivity: "Δραστηριότητα",
    gemh: "Αριθμός ΓΕΜΗ",
    representativeName: "Όνομα Εκπροσώπου",
    representativeSurname: "Επώνυμο Εκπροσώπου",
    representativeVatNumber: "Α.Φ.Μ. Εκπροσώπου",
    paymentMethodDetails: "Τρόπος Πληρωμής",
    amount: "Ποσό",
    type: "Τύπος",
    transactionId: "Αναγνωριστικό Συναλλαγής",
    transactionNumber: "Αριθμός Συναλλαγής",
    tipAmount: "Φιλοδώρημα",
    transactionDate: "Ημ/νία Συναλλαγής",
    tid: "Αναγνωριστικό Τερματικού",
    signature: "Ψηφιακή Υπογραφή",
    merchantId: "Merchant ID",
    bankId: "Bank ID",
    cardType: "Τύπος Κάρτας",
    panCard: "Αριθμός Κάρτας",
    rnn: "Retrieval Reference Number",
    authorizationCode: "authorization ID",
    aid: "Application ID",
    incomeClassification: "Έσοδο",
    expensesClassification: "Έξοδο",
    classificationCategory: "Κατηγορία",
    classificationType: "Τύπος",
    taxType: "Είδος Φόρου",
    taxCategory: "Κατηγορία Φόρου",
    underlyingValue: "Υποκείμενη Αξία",
    taxAmount: "Ποσό Φόρου",
    contractDocumentReference: "ΑΔΑΜ",
    projectReference: "Στοιχείο Δρομολόγησης",
    buyerReference: "Στοιχείο Αναφοράς Αγοραστή",
    partyIdentification: "Κωδικός Αναθέτουσας Αρχής",
    invoiceDocumentReference: "Αναφορά Εγγράφου",
    purchaseOrderReference: "Αναγνωριστικό Εντολής Αγοράς",
    salesOrderReference: "Στοιχείο Αναφοράς Εντολής Πώλησης",
    dueDate: "Ημερομηνία Δρομόλογησης",
}

export const fieldsToFormat = [
    "totalWithheldAmount",
    "totalGrossValue",
    "totalNetValue",
    "totalValue",
    "totalFeesAmount",
    "totalOtherTaxesAmount",
    "totalVatValue",
    "totalVatAmount",
    "totalStampDutyAmount",
    "totalDeductionsAmount",
    "taxAmount",
    "vatAmount",
    "netValue",
    "amount",
    "tipAmount",
]