import React from 'react';
import './Sidebar.css';
import {useDispatch} from "react-redux";
import {setSettingsView} from "../../slices/Admin/admin_slice";

const AdminSidebar = (props) => {

    const dispatch = useDispatch();
    const viewPages = props.viewPages ?? [];
    const activeView = props.activeView ?? 1;

    const handleViewChange = (view) => {
            dispatch(setSettingsView(view));
    }

    return (
        <div className={`sidebar-container open`}>
            <div className="sidebar">
                <ul className="menu">
                    {viewPages.map((page, idx) => (
                        <li onClick={() => handleViewChange(idx + 1)} className={(idx + 1 === activeView) ? "active" : "inactive"}>{page}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdminSidebar;
