import {BlobReader, BlobWriter, TextReader, ZipWriter} from '@zip.js/zip.js';
import axios from "axios";
import {BACKEND_URL} from "./Environment";
import {toast} from "react-toastify";
import {getCompany, getInvoiceTypeShort, isEmpty, transformDataForPrint} from "./Helpers";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

export const exportSelectedInvoices = async (selectedInvoices, printRef, setPrintData, setProgress, isCanceledRef) => {
    try {
        isCanceledRef.current = false;
        const zipWriter = new ZipWriter(new BlobWriter("application/zip"));
        let index = 0;
        for (const identifier of selectedInvoices) {
            if (isCanceledRef.current) break;
            let resData;
            let htmlInvoice;
            await axios.post(`${BACKEND_URL}/api/public/mydatasearch`,
                {identifier: identifier},
                {
                    headers: {"Content-Type": "application/json"},
                }).then((res) => {
                if (res.data.status === "200" && !isEmpty(res.data.data)) {
                    resData = res.data.data;
                    htmlInvoice = res.data.htmlFile;
                } else {
                    toast.error(res.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Connection error.");
            });
            index++;
            if (isEmpty(resData)) continue;
            setPrintData(transformDataForPrint(resData, "A4"));

            await new Promise((resolve) => setTimeout(resolve, 100));
            if (!printRef.current) break;

            printRef.current.style.display = "block";
            if (htmlInvoice) {

                // ToDo: export printed iFrame
                const filename = `${getInvoiceTypeShort(resData.invoiceHeader.invoiceType)}-${isEmpty(resData.invoiceHeader.series) ? "" : resData.invoiceHeader.series + "-"}${resData.invoiceHeader.aa}-${resData.invoiceHeader.issueDate?.replaceAll("/", "_")}.html`;
                await zipWriter.add(filename, new TextReader(htmlInvoice), {password: `${getCompany()?.companyVatNumber ?? ""}`});
                continue;
            }
            printRef.current.querySelector(".a4-page-container").style.border = "0";

            const canvas = await html2canvas(printRef.current, {scale: 3});
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = Math.floor((canvas.height * imgWidth) / canvas.width);
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            const blob = pdf.output("blob");
            const filename = `${getInvoiceTypeShort(resData.invoiceHeader.invoiceType)}-${isEmpty(resData.invoiceHeader.series) ? "" : resData.invoiceHeader.series + "-"}${resData.invoiceHeader.aa}-${resData.invoiceHeader.issueDate?.replaceAll("/", "_")}.pdf`;
            if (selectedInvoices.length === 1) return pdf.save(filename);
            await zipWriter.add(filename, new BlobReader(blob), {password: `${getCompany()?.companyVatNumber ?? ""}`});
            setProgress(Math.ceil((index / selectedInvoices.length) * 100));
        }
        if (zipWriter.files.size === 0) return;
        zipWriter.close().then((blob) => {
            // Create a link to trigger download, then remove it
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "invoices.zip";
            link.click();
            URL.revokeObjectURL(link.href);
        });
    } catch (e) {
        console.error(e);
    } finally {
        setProgress(0);
        if (printRef.current) printRef.current.style.display = "none";
    }
}

export const exportFilteredInvoices = async (data, printRef, setPrintData, setProgress, isCanceledRef) => {
    isCanceledRef.current = false;
    if (isEmpty(data)) return;
    const zipWriter = new ZipWriter(new BlobWriter("application/zip"));

    let index = 0;
    for (let invoiceData of data) {
        try {
            if (isCanceledRef.current) break;
            setPrintData(transformDataForPrint(invoiceData, "A4"));

            await new Promise((resolve) => setTimeout(resolve, 100));

            if (!printRef.current) break;
            printRef.current.style.display = "block";
            if (invoiceData.htmlInvoice) {
                // ToDo: export printed iFrame
                const filename = `${getInvoiceTypeShort(invoiceData.invoiceHeader.invoiceType)}-${isEmpty(invoiceData.invoiceHeader.series) ? "" : invoiceData.invoiceHeader.series + "-"}${invoiceData.invoiceHeader.aa}-${invoiceData.invoiceHeader.issueDate?.replaceAll("/", "_")}.html`;
                await zipWriter.add(filename, new TextReader(invoiceData.htmlInvoice), {password: `${getCompany()?.companyVatNumber ?? ""}`});
                continue;
            }
            printRef.current.querySelector(".a4-page-container").style.border = "0";

            const canvas = await html2canvas(printRef.current, {scale: 3});
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = Math.floor((canvas.height * imgWidth) / canvas.width);
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            const blob = pdf.output("blob");
            const filename = `${getInvoiceTypeShort(invoiceData.invoiceHeader.invoiceType)}-${isEmpty(invoiceData.invoiceHeader.series) ? "" : invoiceData.invoiceHeader.series + "-"}${invoiceData.invoiceHeader.aa}-${invoiceData.invoiceHeader.issueDate?.replaceAll("/", "_")}.pdf`;
            // if (data.length === 1) return pdf.save(filename);
            await zipWriter.add(filename, new BlobReader(blob), {password: `${getCompany()?.companyVatNumber ?? ""}`});
        } catch (e) {
            console.error(e);
        }
        index++;
        setProgress(Math.ceil((index / data.length) * 100));
    }
    zipWriter.close().then((blob) => {
        // Create a link to trigger download, then remove it
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "invoices.zip";
        link.click();
        URL.revokeObjectURL(link.href);
    });
    setProgress(0);
    if (printRef.current) printRef.current.style.display = "none";
}

export const exportExcelInvoices = async (filterData) => {
    await axios.post(`${BACKEND_URL}/api/public/export-excel`, filterData, { headers: {"Content-Type": "application/json"}, responseType: "blob" })
        .then(res => {
            if (isEmpty(res) || isEmpty(res.data)) return toast.error(res?.data.message ?? "Connection error.");
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'invoices.xlsx';
            link.click();
            link.remove();
            URL.revokeObjectURL(url);
    }).catch((err) => {
        console.log(err);
        toast.error("Connection error.");
    });
}
