import React from "react";
import {useState} from "react";

function JsonText(data) {
    const json = JSON.stringify(data, null, 2);
    return (
        <React.Fragment>
            <div>
                <pre style={{backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '5px'}}>
                    {json}
              </pre>
            </div>
        </React.Fragment>
    )
}

export default JsonText;