import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";

function NoPage() {
    useEffect(() => {
        window.location.href = "/"
    }, [])

    return (
        <Container>
        </Container>
    )
}

export default NoPage;