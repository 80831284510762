import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {isEmpty} from "../../Helpers/Helpers";

const DateBox = (props) => {
    const [date, setDate] = useState(new Date());
    let selectedValue = null;

    if (props.selected && props.selected === 'today') {
        selectedValue = date;
    } else if (props.selected && props.selected !== 'today') {
        selectedValue = props.selected;
    }

    const handleOnChange = (e) => {
        if (e === null || e === undefined || e === "") {
            setDate(e)
            props.onChange && props.onChange(e)
        } else {
            setDate(e)
            props.onChange && props.onChange(e)
        }
    }

    const handleOnBlur = () => {
        if (isEmpty(date) || !moment(date).isValid()) {
            //toast.error("Invalid date.");
            props.onChange && props.onChange("");
        }
    }

    return (
        <div className={`input-container ${props.classes ? props.classes : ""}`}>
            {props.label && (
                <label htmlFor={props.label} className="input-label mb-2">
                    {props.label}{" "}
                    {props.required && <span className="text-danger">*</span>}
                </label>
            )}
            <div className="input-wrapper">
                <DatePicker
                    name={props.name ? props.name : ""}
                    dateFormat={props.dateFormat ? props.dateFormat : ["dd-MM-yyyy", "dd/MM/yyyy", "ddMMyyyy", "ddMMyy"]}
                    maxDate={props.maxDate ? props.maxDate : new Date("31-12-2030")}
                    minDate={props.minDate ? props.minDate : new Date("01-01-2010")}
                    selected={selectedValue}
                    placeholderText={props.placeholder}
                    readOnly={props.allowOlderDate !== true ? props.readOnly : ""}
                    autoComplete="off"
                    showMonthYearPicker={props.showMonthYearPicker}
                    showMonthYearDropdown={false}
                    onChange={(e) => handleOnChange(e)}
                    onBlur={(e) => handleOnBlur(e)}
                    className={"form-control " + props.classes}
                    disabled={props?.disabled}
                    wrapperClassName={"datepicker-full-width"}
                />
            </div>
        </div>
    )
}

export default DateBox;
