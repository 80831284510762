// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    width: 100%;
    border: 2px solid #6ea2a9;
    background-color: #F9F9F9;
    border-radius: 8px;
    margin-top: 50px;
}

.login-title {
    text-align: center;
    font-size: 22px;
    background-color: #4f8d96;
    color: #f9f9f9;
    padding: 6px 0;
}

.login-content {
    padding: 30px;
}

.login-input {
    margin: 0 !important;
}

.login-icon {
    border: 1px solid #4f8d96 !important;
    outline: 1px solid #4f8d96 !important;
    background-color: #4f8d96 !important;
    color: #FFFFFF !important;
}

.login-button {
    padding: 20px 80px !important;
}

.input-container {
    position: relative;
}

.eye-icon {
    position: absolute;
    height: 22px;
    display: block;
    right: 20px;
    top: 20%;
    cursor: pointer;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/login.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,oCAAoC;IACpC,qCAAqC;IACrC,oCAAoC;IACpC,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,WAAW;IACX,QAAQ;IACR,eAAe;IACf,WAAW;AACf","sourcesContent":[".login-container {\r\n    width: 100%;\r\n    border: 2px solid #6ea2a9;\r\n    background-color: #F9F9F9;\r\n    border-radius: 8px;\r\n    margin-top: 50px;\r\n}\r\n\r\n.login-title {\r\n    text-align: center;\r\n    font-size: 22px;\r\n    background-color: #4f8d96;\r\n    color: #f9f9f9;\r\n    padding: 6px 0;\r\n}\r\n\r\n.login-content {\r\n    padding: 30px;\r\n}\r\n\r\n.login-input {\r\n    margin: 0 !important;\r\n}\r\n\r\n.login-icon {\r\n    border: 1px solid #4f8d96 !important;\r\n    outline: 1px solid #4f8d96 !important;\r\n    background-color: #4f8d96 !important;\r\n    color: #FFFFFF !important;\r\n}\r\n\r\n.login-button {\r\n    padding: 20px 80px !important;\r\n}\r\n\r\n.input-container {\r\n    position: relative;\r\n}\r\n\r\n.eye-icon {\r\n    position: absolute;\r\n    height: 22px;\r\n    display: block;\r\n    right: 20px;\r\n    top: 20%;\r\n    cursor: pointer;\r\n    z-index: 10;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
