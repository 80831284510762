import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from "moment";

const requestDataInitialState = {
    page: 1,
    identifier: "",
    date_from: moment().subtract(1, "month").toString(),
    date_to: moment().toString(),
    number_from: "",
    number_to: "",
    total_from: "",
    total_to: "",
    fail_code: [],
    invoiceType: [],
    companyVatNumber: "",
}

const initialState = {
    requestData: requestDataInitialState,
    oldRequestData: requestDataInitialState,
    responseData: {
        data: [],
        totals: null,
        totalPages: 0,
        totalItems: 0,
    },
    selectedInvoices: [],
    selectAll: false,
    companyACBoxDisplay: "",
}

export const searchInvoice = createSlice({
    name: 'searchInvoice',
    initialState: initialState,
    reducers: {
        setRequestData: (state, action) => {
            state.requestData = action.payload;
        },
        setRequestDataOnlyVatNumber: (state, action) => {
            state.requestData = {...requestDataInitialState, companyVatNumber: action.payload};
        },
        setOldRequestData: (state, action) => {
            state.oldRequestData = action.payload;
        },
        setResponseData: (state, action) => {
            state.responseData = action.payload;
        },
        addInvoiceSelection: (state, action) => {
            state.selectedInvoices.push(action.payload);
        },
        removeInvoiceSelection: (state, action) => {
            state.selectedInvoices = state.selectedInvoices.filter((el) => el !== action.payload);
        },
        setSelectedInvoices: (state, action) => {
            state.selectedInvoices = action.payload;
        },
        setSelectAll: (state, action) => {
            state.selectAll = action.payload;
        },
        setCompanyACBoxDisplay: (state, action) => {
            state.companyACBoxDisplay = action.payload;
        },
    },
});

export const {
    setRequestData,
    setRequestDataOnlyVatNumber,
    setOldRequestData,
    setResponseData,
    addInvoiceSelection,
    removeInvoiceSelection,
    setSelectedInvoices,
    setSelectAll,
    setCompanyACBoxDisplay,
} = searchInvoice.actions;

export default searchInvoice.reducer;
