import React from "react";
import {isEmpty, sanitizeHTML} from "../../Helpers/Helpers";
import {epayLogoBase64, vivaLogoBase64} from "./logos";

const SalesPrintA5DoubleGreek = React.forwardRef((props, ref) => {
    let templateData = [];
    require("./invoicePrint-a5-double.css");
    if (props.data) {
        templateData = props.data;
    }
    let showWaterMark = templateData.TEST_ENVIRONMENT;

    const marginzero = {
        margin: "0"
    }
    const transactionDetailsBlock = {
        border: "1px solid #555",
        padding: "2px 5px",
        borderRadius: "8px",
        fontSize: "8px",
        overflow: "hidden",
        overflowWrap: "break-word",
        textAlign: "center",
        fontWeight: "500",
    }

    const balanceContainer = {
        width: "100%",
        fontSize: "10px",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #555",
        borderRadius: "8px",
        padding: "2px",
        fontWeight: "bold",
        textAlign: "center",
    }

    const totalPagesA = templateData.TOTAL_PAGES !== undefined ? templateData.TOTAL_PAGES : [];

    return(
        <React.Fragment>
            <div style={marginzero} className={"a5d-print-container"} ref={ref}>
                { totalPagesA.length > 0 && totalPagesA.map((currentPage1,index) => (
                    <div className="sales-print-template-show a5d-page-container">
                        <div className="a5d-receipt-double a5d-invoice-box">
                            <div className="a5d-top_table">
                                <div className="a5d-paging">Σελίδα: {index + 1} από {totalPagesA.length}</div>
                                <table cellPadding="0" cellSpacing="0">
                                    <tr className="top">
                                        <td>
                                            <table className="a5d-header_table">
                                                <tr>
                                                    <td className="a5d-issuer_container">
                                                        {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5d-issuer_name a5d-skin">{templateData.ISSUER_NAME}</span>}
                                                        {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5d-issuer_subjectField a5d-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                        {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5d-issuer_address a5d-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                        {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5d-issuer_address a5d-skin">{templateData.BRANCH !== "" && templateData.BRANCH !== "Κεντρικό" ? "Υποκατάστημα: " : ""}{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                                        {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5d-issuer_vat a5d-skin">ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                        {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                            <p><span className="a5d-issuer_vat a5d-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <div className="a5d-main_info">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <table className="a5d-main_info_table">
                                                <tr className="a5d-heading">
                                                    <td className="a5d-p">ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</td>
                                                    <td className="a5d-p">ΣΕΙΡΑ</td>
                                                    <td className="a5d-p">ΑΡΙΘΜΟΣ</td>
                                                    <td className="a5d-p">ΗΜΕΡ/ΝΙΑ</td>
                                                    <td className="a5d-p">ΩΡΑ</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="a5d-invoice_type">{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                    <td><span className="a5d-invoice_number">{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                                    <td><span className="a5d-invoice_number">{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                    <td><span className="a5d-invoice_date">{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                    <td><span className="a5d-invoice_time">{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </div>
                                <table className="a5d-information_table" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="a5d-information_td_left">
                                            <div className="a5d-information left">
                                                <table className="a5d-information_customer">
                                                    <tr>
                                                        <td className="a5d-information_customer_td a5d-skin a5d-bold"><span> ΟΝΟΜΑΤΕΠΩΝΥΜΟ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.CUSTOMER_NAME}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> ΕΠΑΓΓΕΛΜΑ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_activity">ΙΔΙΩΤΗΣ</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> EMAIL ΠΕΛΑΤΗ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.CUSTOMER_EMAIL}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> ΔΙΕΥΘΥΝΣΗ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.CUSTOMER_ADDRESS}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.PAYMENT_METHOD}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="a5d-product_container">
                                <table className="a5d-product_table" cellPadding="0" cellSpacing="0">
                                    <tr className="a5d-heading">
                                        <td className="a5d-description_head_td a5d-p">ΚΩΔΙΚΟΣ</td>
                                        <td className="a5d-description_head_td a5d-p">ΠΕΡΙΓΡΑΦΗ</td>
                                        <td className="a5d-quantity_head_td a5d-p">ΠΟΣΟΤΗΤΑ</td>
                                        <td className="a5d-up_head_td a5d-p">ΤΙΜΗ ΜΟΝΑΔΑΣ</td>
                                        <td className="a5d-vat_head_td a5d-p">ΦΠΑ %</td>
                                        <td className="a5d-pricenovat_head_td a5d-p">ΕΚΠΤΩΣΗ</td>
                                        <td className="a5d-pricenovat_head_td a5d-p">ΤΙΜΗ ΠΡΟ ΦΠΑ</td>
                                        <td className="a5d-pricenovat_head_td a5d-p">ΑΞΙΑ ΦΠΑ</td>
                                        <td className="a5d-whtax_head_td a5d-p">ΦΟΡΟΙ/ΤΕΛΗ</td>
                                        <td className="a5d-price_head_td a5d-p">ΤΕΛΙΚΗ ΑΞΙΑ</td>
                                    </tr>
                                    {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) => (
                                        <tr className="a5d-products" key={Math.random()}>
                                            <td><span className="a5d-item_code">{product.CODE}</span></td>
                                            <td><span
                                                className="a5d-item_description">{product.PRODUCT_DESCRIPTION}</span>
                                            </td>
                                            <td><span className="a5d-item_quantity">{product.QTY}</span></td>
                                            <td><span className="a5d-item_unit_price">{product.PRICE_QTY}</span></td>
                                            <td><span className="a5d-item_vat">{product.VAT}{product?.DONT_SHOW_PERCENTAGE === true ? "" : "%"}</span></td>
                                            <td><span className="a5d-item_discount">{product.DISCOUNT}</span></td>
                                            <td><span className="a5d-item_price_no_vat">{product.PRICE_NOVAT}</span>
                                            </td>
                                            <td><span className="a5d-item_vat_price">{product.VAT_PRICE}</span></td>
                                            <td><span className="a5d-item_other_taxes">{product.OTHER_TAXES}</span>
                                            </td>
                                            <td><span className="a5d-item_total_price">{product.TOTAL}</span></td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <div className="a5d-footer_container">
                                <table className="a5d-footer_table" style={{tableLayout: "fixed", width: "100%"}}>
                                    <tbody>
                                    <tr>
                                        <td className="a5d-vat_total_td">
                                            {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                                <div style={balanceContainer}>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Προηγ. Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.OLD_BALANCE}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Νέο Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.NEW_BALANCE}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="a5d-per_vat_totals_container">
                                                <table className="a5d-totals_per_vat">
                                                    <tr>
                                                        <th>Συντ. ΦΠΑ</th>
                                                        <th>Καθαρή Αξία</th>
                                                        <th>Αξία ΦΠΑ</th>
                                                        <th>Τελική Αξία</th>
                                                    </tr>
                                                    {!isEmpty(templateData.VAT_TABLE) && index + 1 === totalPagesA.length && templateData.VAT_TABLE.map((el) => {
                                                        return <tr key={Math.random()}>
                                                            <td className={"text-center"}>{el.vatPercentage}</td>
                                                            <td className={"text-center"}>{el.amountNoVat}</td>
                                                            <td className={"text-center"}>{el.vatAmount}</td>
                                                            {/*<td>{el.OTHER_TAXES}</td>*/}
                                                            <td className={"text-center"}>{el.totalAmount}</td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                            <div className="a5d-cont_notation">
                                                {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => (
                                                    <>
                                                        <span className="skin bold">ΑΠΑΛΛΑΓΗ ΑΠΟ ΤΟ Φ.Π.Α. :</span>
                                                        <div className="a5d-cont_notation_inner">
                                                            <span>{reason.vat} {reason.description} <br/></span>
                                                        </div>
                                                    </>
                                                ))}
                                                <span className="a5d-skin a5d-bold">ΠΑΡΑΤΗΡΗΣΕΙΣ: {templateData.INVOICE_NOTES && <span>{sanitizeHTML(templateData.INVOICE_NOTES)}</span>}</span>
                                            </div>
                                        </td>
                                        {templateData.UID !== "" && templateData.MYDATADESTINATION === "provider" && (
                                            <td>
                                                <div className="a5d-cont_signs">
                                                    <img src={templateData.QR_CODE} alt="Qr code"
                                                         className="a5d-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {templateData.UID !== "" && templateData.MYDATADESTINATION !== "provider" && templateData.MYDATAQRURL && (
                                            <td>
                                                <div className="a5d-cont_signs">
                                                    <img src={templateData.QR_CODE} alt="Qr code"
                                                         className="a5d-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {((templateData.TRANSACTION_DETAILS && index + 1 === totalPagesA.length) && (
                                            <td>
                                                {(false) ? (
                                                // {(templateData.TRANSACTION_DETAILS[0]?.signature) ? (
                                                    <div style={{...transactionDetailsBlock, width: "100%", fontSize: "8px"}}>
                                                        {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "true" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "15mm"}} src={epayLogoBase64} alt={"ePay Logo"}/>
                                                                <br/>
                                                                <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                            </React.Fragment>
                                                        )}
                                                        {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "viva" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "10mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                            </React.Fragment>
                                                        )}
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed", fontSize: "8px"}} cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                            <tr>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.transactionDate}</td>
                                                                <td>TID/MID: {templateData.TRANSACTION_DETAILS[0]?.terminalId} / {templateData.TRANSACTION_DETAILS[0]?.merchantId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΑΡ.ΣΥΝ.: {templateData.TRANSACTION_DETAILS[0]?.transactionNumber}</td>
                                                                <td>ΕΓΚΡΙΣΗ: {templateData.TRANSACTION_DETAILS[0]?.authorizationCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RRN.: {templateData.TRANSACTION_DETAILS[0]?.rnn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΕΣΩ:
                                                                    {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "true" && ` EPAY`}
                                                                    {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "viva" && ` VIVA`}
                                                                </td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.cardType} {templateData.TRANSACTION_DETAILS[0]?.aid} {templateData.TRANSACTION_DETAILS[0]?.panCard} - {templateData.TRANSACTION_DETAILS[0]?.finalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ: {templateData.TRANSACTION_DETAILS[0]?.amountTotalString}</td>
                                                                <td>ΦΙΛΟΔΩΡΗΜΑ:{templateData.TRANSACTION_DETAILS[0]?.tipAmountString}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{verticalAlign: "middle"}}>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                <td style={{wordBreak: "break-all", fontSize: "6px"}}>{templateData.TRANSACTION_DETAILS[0]?.signature}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}>ΤΑΥΤ.ΠΛΗΡΩΜΗΣ: {templateData.TRANSACTION_DETAILS[0]?.transactionId}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "100%",
                                                    }}>
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.paymentTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΗΜ/ΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.transactionDate ?? `${templateData.INVOICE_DATE} ${templateData.INVOICE_TIME}`}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.amount}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </td>
                                        ))}
                                        <td className="a5d-total_td_block">
                                            <div className="a5d-count_totals_container">
                                                <span>ΣΥΝΟΛΟ ΤΕΜΑΧΙΩΝ: </span> <span
                                                className="a5d-count_total_prods"> {index + 1 === totalPagesA.length ? templateData.TOTAL_QTY : ''}</span>
                                            </div>
                                            <div className="a5d-total_container">
                                                <div className="a5d-totals">
                                                    <table className="a5d-totals_table">
                                                        <tr>
                                                            <td className="a5d-text-left a5d-p">ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_NOVAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5d-text-left a5d-p">Φ.Π.Α.</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_VAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5d-text-left a5d-p">ΤΕΛΙΚΟ ΣΥΝΟΛΟ</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                        {templateData.TOTAL_WITHHOLD && (
                                                            <tr>
                                                                <td className="a5d-text-left a5d-p">ΣΥΝΟΛΟ ΠΑΡΑΚΡΑΤΗΣΕΩΝ</td>
                                                                <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_WITHHOLD : ''}</span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="a5d-blank_row a5d-bordered" style={{borderTop: "2px solid", borderBottom: "2px solid"}}>
                                                        </tr>
                                                        <tr>
                                                        <td className="a5d-text-left a5d-finalprice a5d-p">ΠΛΗΡΩΤΕΟ ΠΟΣΟ</td>
                                                        <td className="a5d-text-right a5d-p">
                                                            <span
                                                                className="a5d-totalpayment">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                        </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="a5d-sender_sign">
                                    <span >www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                    <br/>
                                    <span >Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                </div>
                                {index + 1 === totalPagesA.length && (
                                    <div className="a5d-mydata_sign">
                                        {(templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1") &&
                                            <span className="a5d-uid_sign">Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                        {(templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2") &&
                                            <span className="a5d-uid_sign">Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                        {(templateData.TRANSMISSION_FAILURE === "3" && templateData.MYDATADESTINATION === "MyData") && (
                                            <span>Απώλεια Διασύνδεσης ERP – Α.Α.Δ.Ε. - Transmission Failure_3<br/></span>
                                        )}
                                        {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                            <span className="a5d-uid_sign"> UID: {templateData.UID}</span>}
                                        {templateData.MARK && templateData.MARK !== "" &&
                                            <span className="a5d-uid_sign"> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                                        {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                            <span className="a5d-uid_sign"><br/> AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                                    </div>
                                )}
                            </div>
                            {showWaterMark && <div className="a5d-cancel">ΑΚΥΡΟ</div>}
                        </div>

                        <div className="a5d-receipt-double a5d-invoice-box a5d-double-clone">
                            <div className="a5d-top_table">
                                <div className="a5d-paging">Σελίδα: {index + 1} από {totalPagesA.length}</div>
                                <table cellPadding="0" cellSpacing="0">
                                    <tr className="top">
                                        <td>
                                            <table className="a5d-header_table">
                                                <tr>
                                                    {templateData.BRANCH === "" && (
                                                        <td className="a5d-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5d-issuer_name a5d-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5d-issuer_subjectField a5d-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5d-issuer_address a5d-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5d-issuer_address a5d-skin">{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}, ΔΟΥ: {templateData.ISSUER_DOY}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5d-issuer_vat a5d-skin">ΑΦΜ: {templateData.ISSUER_VAT}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5d-issuer_vat a5d-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    )}
                                                    {templateData.BRANCH !== "" && (
                                                        <td className="a5d-issuer_container">
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" && <span className="a5d-issuer_name a5d-skin">{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" && <p><span className="a5d-issuer_subjectField a5d-skin">{templateData.ISSUER_SMALL_NAME}</span></p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" && <p><span className="a5d-issuer_subjectField a5d-skin">{templateData.ISSUER_SUBJECTFIELD}</span></p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" && <p><span className="a5d-issuer_address a5d-skin">Υποκατάστημα: {templateData.BRANCH?.address}, {templateData.BRANCH?.city}, {templateData.BRANCH?.postalCode}</span></p>}
                                                            {templateData.ISSUER_PHONE && templateData.ISSUER_PHONE !== "" && <p><span className="a5d-issuer_address a5d-skin">ΤΗΛΕΦΩΝΟ: {templateData.BRANCH?.phone}</span></p>}
                                                            {templateData.ISSUER_DOY && templateData.ISSUER_DOY !== "" && <p><span className="a5d-issuer_address a5d-skin">ΔΟΥ: {templateData.BRANCH?.doy}</span></p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" && <p><span className="a5d-issuer_address a5d-skin">ΑΦΜ: {templateData.ISSUER_VAT}</span></p>}
                                                            {templateData.ISSUER_GEMH && templateData.ISSUER_GEMH !== "" && <p><span className="a5d-issuer_address a5d-skin">ΓΕΜΗ: {templateData.ISSUER_GEMH}</span></p>}
                                                            {templateData.ISSUER_EMAIL && templateData.ISSUER_EMAIL !== "" && <p><span className="a5d-issuer_address a5d-skin">EMAIL: {templateData.ISSUER_EMAIL}</span></p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p><span className="a5d-issuer_vat a5d-skin">Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span></p>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <div className="a5d-main_info">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tr>
                                            <table className="a5d-main_info_table">
                                                <tr className="a5d-heading">
                                                    <td className="a5d-p">ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</td>
                                                    <td className="a5d-p">ΣΕΙΡΑ</td>
                                                    <td className="a5d-p">ΑΡΙΘΜΟΣ</td>
                                                    <td className="a5d-p">ΗΜΕΡ/ΝΙΑ</td>
                                                    <td className="a5d-p">ΩΡΑ</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="a5d-invoice_type">{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span></td>
                                                    <td><span className="a5d-invoice_number">{templateData.SERIES ? templateData.SERIES : '-'}</span></td>
                                                    <td><span className="a5d-invoice_number">{templateData.NUMBER ? templateData.NUMBER : '-'}</span></td>
                                                    <td><span className="a5d-invoice_date">{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span></td>
                                                    <td><span className="a5d-invoice_time">{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span></td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </div>
                                <table className="a5d-information_table" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                    <tr>
                                        <td className="a5d-information_td_left">
                                            <div className="a5d-information left">
                                                <table className="a5d-information_customer">
                                                    <tr>
                                                        <td className="a5d-information_customer_td a5d-skin a5d-bold"><span> ΟΝΟΜΑΤΕΠΩΝΥΜΟ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.CUSTOMER_NAME}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> ΕΠΑΓΓΕΛΜΑ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_activity">ΙΔΙΩΤΗΣ</span></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> EMAIL ΠΕΛΑΤΗ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.CUSTOMER_EMAIL}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> ΔΙΕΥΘΥΝΣΗ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.CUSTOMER_ADDRESS}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="a5d-skin a5d-bold"><span> ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</span></td>
                                                        <td className="a5d-info_value"><span>: </span><span className="a5d-counterparty_name">{templateData.PAYMENT_METHOD}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="a5d-product_container">
                                <table className="a5d-product_table" cellPadding="0" cellSpacing="0">
                                    <tr className="a5d-heading">
                                        <td className="a5d-description_head_td a5d-p">ΚΩΔΙΚΟΣ</td>
                                        <td className="a5d-description_head_td a5d-p">ΠΕΡΙΓΡΑΦΗ</td>
                                        <td className="a5d-quantity_head_td a5d-p">ΠΟΣΟΤΗΤΑ</td>
                                        <td className="a5d-up_head_td a5d-p">ΤΙΜΗ ΜΟΝΑΔΑΣ</td>
                                        <td className="a5d-vat_head_td a5d-p">ΦΠΑ %</td>
                                        <td className="a5d-pricenovat_head_td a5d-p">ΕΚΠΤΩΣΗ</td>
                                        <td className="a5d-pricenovat_head_td a5d-p">ΤΙΜΗ ΠΡΟ ΦΠΑ</td>
                                        <td className="a5d-pricenovat_head_td a5d-p">ΑΞΙΑ ΦΠΑ</td>
                                        <td className="a5d-whtax_head_td a5d-p">ΦΟΡΟΙ/ΤΕΛΗ</td>
                                        <td className="a5d-price_head_td a5d-p">ΤΕΛΙΚΗ ΑΞΙΑ</td>
                                    </tr>
                                    {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) => (
                                        <tr className="a5d-products" key={Math.random()}>
                                            <td><span className="a5d-item_code">{product.CODE}</span></td>
                                            <td><span
                                                className="a5d-item_description">{product.PRODUCT_DESCRIPTION}</span>
                                            </td>
                                            <td><span className="a5d-item_quantity">{product.QTY}</span></td>
                                            <td><span className="a5d-item_unit_price">{product.PRICE_QTY}</span></td>
                                            <td><span className="a5d-item_vat">{product.VAT}{product?.DONT_SHOW_PERCENTAGE === true ? "" : "%"}</span></td>
                                            <td><span className="a5d-item_discount">{product.DISCOUNT}</span></td>
                                            <td><span className="a5d-item_price_no_vat">{product.PRICE_NOVAT}</span>
                                            </td>
                                            <td><span className="a5d-item_vat_price">{product.VAT_PRICE}</span></td>
                                            <td><span className="a5d-item_other_taxes">{product.OTHER_TAXES}</span>
                                            </td>
                                            <td><span className="a5d-item_total_price">{product.TOTAL}</span></td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <div className="a5d-footer_container">
                                <table className="a5d-footer_table" style={{tableLayout: "fixed", width: "100%"}}>
                                    <tbody>
                                    <tr>
                                        <td className="a5d-vat_total_td">
                                            {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                                <div style={balanceContainer}>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Προηγ. Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.OLD_BALANCE}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{margin: "auto"}}>Νέο Υπόλοιπο</div>
                                                        <div style={{margin: "auto", fontWeight: "normal"}}>{templateData.NEW_BALANCE}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="a5d-per_vat_totals_container">
                                                <table className="a5d-totals_per_vat">
                                                    <tr>
                                                        <th>Συντ. ΦΠΑ</th>
                                                        <th>Καθαρή Αξία</th>
                                                        <th>Αξία ΦΠΑ</th>
                                                        <th>Τελική Αξία</th>
                                                    </tr>
                                                    {!isEmpty(templateData.VAT_TABLE) && index + 1 === totalPagesA.length && templateData.VAT_TABLE.map((el) => {
                                                        return <tr key={Math.random()}>
                                                            <td className={"text-center"}>{el.vatPercentage}</td>
                                                            <td className={"text-center"}>{el.amountNoVat}</td>
                                                            <td className={"text-center"}>{el.vatAmount}</td>
                                                            {/*<td>{el.OTHER_TAXES}</td>*/}
                                                            <td className={"text-center"}>{el.totalAmount}</td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                            <div className="a5d-cont_notation">
                                                {templateData.VATEXPCAT && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => (
                                                    <>
                                                        <span className="skin bold">ΑΠΑΛΛΑΓΗ ΑΠΟ ΤΟ Φ.Π.Α. :</span>
                                                        <div className="a5d-cont_notation_inner">
                                                            <span>{reason.vat} {reason.description} <br/></span>
                                                        </div>
                                                    </>
                                                ))}
                                                <span
                                                    className="a5d-skin a5d-bold">ΠΑΡΑΤΗΡΗΣΕΙΣ: {templateData.INVOICE_NOTES && <span>{sanitizeHTML(templateData.INVOICE_NOTES)}</span>}</span>
                                            </div>
                                        </td>
                                        {templateData.UID !== "" && templateData.MYDATADESTINATION === "provider" && (
                                            <td>
                                                <div className="a5d-cont_signs">
                                                    <img src={templateData.QR_CODE} alt="Qr code"
                                                         className="a5d-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {templateData.UID !== "" && templateData.MYDATADESTINATION !== "provider" && templateData.MYDATAQRURL && (
                                            <td>
                                                <div className="a5d-cont_signs">
                                                    <img src={templateData.QR_CODE} alt="Qr code"
                                                         className="a5d-product-qr-code-img"/>
                                                </div>
                                            </td>
                                        )}
                                        {((templateData.TRANSACTION_DETAILS && index + 1 === totalPagesA.length) && (
                                            <td>
                                                {(false) ? (
                                                // {(templateData.TRANSACTION_DETAILS[0]?.signature) ? (
                                                    <div style={{...transactionDetailsBlock, width: "100%", fontSize: "8px"}}>
                                                        {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "true" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "20mm"}} className={"mb-2"}
                                                                     src={epayLogoBase64} alt={"ePay Logo"}/>
                                                                <br/>
                                                                <span style={{fontSize: "7px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                            </React.Fragment>
                                                        )}
                                                        {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "viva" && (
                                                            <React.Fragment>
                                                                <img style={{margin: "0", width: "15mm"}} src={vivaLogoBase64} alt={"Viva Logo"}/>
                                                                <br/>
                                                            </React.Fragment>
                                                        )}
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed", fontSize: "8px"}} cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                            <tr>
                                                                <td>&nbsp;</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.transactionDate}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>TID/MID:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.terminalId} / {templateData.TRANSACTION_DETAILS[0]?.merchantId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΑΡ.
                                                                    ΣΥΝ.: {templateData.TRANSACTION_DETAILS[0]?.transactionNumber}</td>
                                                                <td>ΕΓΚΡΙΣΗ: {templateData.TRANSACTION_DETAILS[0]?.authorizationCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>RRN.:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.rnn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΕΣΩ:
                                                                    {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "true" && `EPAY`}
                                                                    {templateData.TRANSACTION_DETAILS[0]?.isEuronet === "viva" && `VIVA`}
                                                                </td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.cardType} {templateData.TRANSACTION_DETAILS[0]?.aid} {templateData.TRANSACTION_DETAILS[0]?.panCard} - {templateData.TRANSACTION_DETAILS[0]?.finalCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.amountTotalString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.tipAmountString} €</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                <td style={{wordBreak: "break-all"}}>{templateData.TRANSACTION_DETAILS[0]?.signature}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΜΟΝ. ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.transactionId}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                        ...transactionDetailsBlock,
                                                        width: "100%",
                                                    }}>
                                                        <table className={"transactionDetailsTable"} style={{width: "100%", tableLayout: "fixed"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.paymentTypeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΗΜ/ΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.transactionDate ?? `${templateData.INVOICE_DATE} ${templateData.INVOICE_TIME}`}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>ΠΟΣΟ</td>
                                                                <td>{templateData.TRANSACTION_DETAILS[0]?.amount}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </td>
                                        ))}
                                        <td className="a5d-total_td_block">
                                            <div className="a5d-count_totals_container">
                                                <span>ΣΥΝΟΛΟ ΤΕΜΑΧΙΩΝ: </span> <span
                                                className="a5d-count_total_prods"> {index + 1 === totalPagesA.length ? templateData.TOTAL_QTY : ''}</span>
                                            </div>
                                            <div className="a5d-total_container">
                                                <div className="a5d-totals">
                                                    <table className="a5d-totals_table">
                                                        <tr>
                                                            <td className="a5d-text-left a5d-p">ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_NOVAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5d-text-left a5d-p">Φ.Π.Α.</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-total_withoutvat">{index + 1 === totalPagesA.length ? templateData.TOTAL_VAT : ''}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5d-text-left a5d-p">ΤΕΛΙΚΟ ΣΥΝΟΛΟ</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                        {templateData.TOTAL_WITHHOLD && (
                                                            <tr>
                                                                <td className="a5d-text-left a5d-p">ΣΥΝΟΛΟ ΠΑΡΑΚΡΑΤΗΣΕΩΝ</td>
                                                                <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-amounttotal">{index + 1 === totalPagesA.length ? templateData.TOTAL_WITHHOLD : ''}</span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr className="a5d-blank_row a5d-bordered" style={{borderTop: "2px solid", borderBottom: "2px solid"}}>
                                                        </tr>
                                                        <tr>
                                                            <td className="a5d-text-left a5d-finalprice a5d-p">ΠΛΗΡΩΤΕΟ ΠΟΣΟ</td>
                                                            <td className="a5d-text-right a5d-p">
                                                                <span
                                                                    className="a5d-totalpayment">{index + 1 === totalPagesA.length ? templateData.TOTAL_PRICE : ''}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="a5d-sender_sign">
                                    <span>www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                    <br/>
                                    <span>Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                </div>
                                {index + 1 === totalPagesA.length && (
                                    <div className="a5d-mydata_sign">
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                            <span className="a5d-uid_sign">Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                        {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && (templateData.MYDATADESTINATION === "Provider" || templateData.MYDATADESTINATION === "provider")) || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                            <span className="a5d-uid_sign">Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                        {(templateData.TRANSMISSION_FAILURE === "3" && templateData.MYDATADESTINATION === "MyData") && (
                                            <span>Απώλεια Διασύνδεσης ERP – Α.Α.Δ.Ε. - Transmission Failure_3<br/></span>
                                        )}
                                        {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                            <span className="a5d-uid_sign"> UID: {templateData.UID}</span>}
                                        {templateData.MARK && templateData.MARK !== "" &&
                                            <span> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                                        {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                            <span className="a5d-uid_sign"><br/> AUTHENTICATION CODE: {templateData.AUTHCODE}</span>}
                                    </div>
                                )}
                            </div>
                            {showWaterMark && <div className="a5d-cancel">ΑΚΥΡΟ</div>}
                        </div>
                        {index + 1 !== totalPagesA.length && (
                            <div className="page-break"></div>
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
})

export default SalesPrintA5DoubleGreek;
