import React from "react";
import {isEmpty, sanitizeHTML} from "../../Helpers/Helpers";

const ThermalPrint = React.forwardRef((props, ref) => {
    require("./thermal-print.css");

    let templateData = [];
    if (!props.data) return(<div></div>);
    templateData = props.data;

    return (
        <React.Fragment>
            <div ref={ref} className="receipt mx-auto">
                {templateData.TEST_ENVIRONMENT && <p style={{textAlign: "center", borderBottom: "thin dashed black"}}>
                    <h1>--- ΑΚΥΡΟ ΠΑΡΑΣΤΑΤΙΚΟ ---</h1>
                    <h2>Αυτή η απόδειξη είναι άκυρη και εκδίδεται μόνο για λόγους δοκιμής της εφαρμογής</h2>
                </p>}
                <h2>{templateData.ISSUER_NAME}</h2>
                <h2>{templateData.ISSUER_SMALL_NAME}</h2>
                <h2>{templateData.ISSUER_SUBJECTFIELD}</h2>
                <h2>ΔΟΥ: {templateData.ISSUER_DOY} <br/>Κατάστημα: {templateData.BRANCH}</h2>
                <h2>{templateData.ISSUER_ADDRESS ? `${templateData.ISSUER_ADDRESS}, ` : ""}{templateData.ISSUER_CITY ? `${templateData.ISSUER_CITY}, ` : ""}{templateData.ISSUER_TK ? `${templateData.ISSUER_TK}` : ""}</h2>
                <h2> ΑΦΜ: {templateData.ISSUER_VAT} &nbsp;ΤΗΛ.: {templateData.ISSUER_PHONE}</h2>
                <h2> Email: {templateData.ISSUER_EMAIL}</h2>
                <div style={{borderTop: "thin dashed black", marginBottom: "8px"}}></div>
                <h2 style={{fontWeight: "bold"}}>ΣΤΟΙΧΕΙΑ ΠΕΛΑΤΗ</h2>
                <h2>ΕΠΩΝΥΜΙΑ: {templateData.CUSTOMER_NAME}</h2>
                <h2>{templateData.CUSTOMER_ACTIVITY}</h2>
                {templateData.CUSTOMER_DOY && <h2>ΔΟΥ: {templateData.CUSTOMER_DOY}</h2>}
                <h2>{templateData.CUSTOMER_ADDRESS ? `${templateData.CUSTOMER_ADDRESS}, ` : ""}{templateData.CUSTOMER_CITY ? `${templateData.CUSTOMER_CITY}, ` : ""}{templateData.CUSTOMER_TK ? `${templateData.CUSTOMER_TK}` : ""}</h2>
                {(templateData.CUSTOMER_VAT || templateData.CUSTOMER_PHONE) && <h2>ΑΦΜ: {templateData.CUSTOMER_VAT} &nbsp;ΤΗΛ.: {templateData.CUSTOMER_PHONE}</h2>}
                {templateData.CUSTOMER_EMAIL && <h2>EMAIL: {templateData.CUSTOMER_EMAIL}</h2>}
                <div style={{borderTop: "thin dashed black", marginBottom: "8px"}}></div>
                <h1>
                    {templateData.INVOICE_TYPE}
                </h1>
                {templateData.INVOICE_SPECIAL_TYPE && <span style={{fontSize: "6pt"}}>{templateData.INVOICE_SPECIAL_TYPE}</span>}
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span style={{textAlign: "left"}}>
                        Σειρά-Αρ.Παρ: {templateData.SERIES} - {templateData.NUMBER}
                    </span>
                    <span style={{textAlign: "right"}}>
                                {templateData.INVOICE_DATE}
                        <br/>
                        {templateData.INVOICE_TIME}
                    </span>
                </div>
                {templateData.PURPOSE && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span style={{textAlign: "left"}}>
                        Σκ.Διακ: {templateData.PURPOSE}
                    </span>
                    <span style={{textAlign: "right"}}>
                        Παράδοση:
                        <br/>
                        {templateData.DISPATCH_TIME}
                        <br/>
                        {templateData.VEHICLE_NUMBER && <span>Όχημα: {templateData.VEHICLE_NUMBER}</span>}
                    </span>
                </div>}
                {templateData.LOCATION_NAME && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span style={{textAlign: "left"}}>
                        Τόπος Αποστ: {templateData.LOCATION_NAME}
                        <br/>
                        Διεύθυνση:
                        <br/>
                        {templateData.LOCATION_ADDRESS}
                    </span>
                    <span style={{textAlign: "right"}}>
                        {templateData.LOCATION_CITY}
                        <br/>
                        {templateData.LOCATION_ZIP}
                    </span>
                </div>}
                {templateData.TABLE_AA && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <span style={{textAlign: "left"}}>
                        Αρ.Τραπεζιού: {templateData.TABLE_AA}
                    </span>
                </div>}
                {templateData.SELF_PRICING && <div>{templateData.SELF_PRICING}</div>}
                {templateData.CORRELATION && <div style={{alignItems: "left"}}>
                    {templateData.CORRELATION}
                </div>}
                {templateData.TRANSACTION_DETAILS?.length > 0 &&
                    <div>
                        <div style={{borderTop: "thin dashed black", marginBottom: "8px"}}></div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left", fontWeight: "bold"}}>
                                Τρόπος Πληρωμής:
                            </span>
                            <span style={{textAlign: "right", fontWeight: "bold"}}>
                                {templateData.TRANSACTION_DETAILS[0].paymentTypeName}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Ημ/νία πληρωμής:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.TRANSACTION_DETAILS[0].transactionDate ?? `${templateData.INVOICE_DATE} ${templateData.INVOICE_TIME ?? ""}`}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Ποσό:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.TRANSACTION_DETAILS[0].amount}
                            </span>
                        </div>
                    </div>}
                {templateData.TRANSACTION_DETAILS?.length > 0 && templateData.TRANSACTION_DETAILS[0].signature &&
                    <div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Ψηφ. Υπογραφή:
                            </span>
                            <span style={{textAlign: "right", wordBreak: "break-all", padding: "2px 0 2px 10px"}}>
                                {templateData.TRANSACTION_DETAILS[0].signature}
                            </span>
                        </div>
                            {templateData.TRANSACTION_DETAILS[0].tid && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    tid:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].tid}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].transactionNumber && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    Αρ.Συν.:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].transactionNumber}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].authorizationCode && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    Έγκριση:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].authorizationCode}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].rnn && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    rnn:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].rnn}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].cardType && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    Κάρτα:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].cardType}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].aid && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    aid:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].aid}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].tipAmountString && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    Φιλοδώρημα:
                                </span>
                                <span style={{textAlign: "right"}}>
                                    {templateData.TRANSACTION_DETAILS[0].tipAmountString}
                                </span>
                            </div>}
                            {templateData.TRANSACTION_DETAILS[0].transactionId && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <span style={{textAlign: "left"}}>
                                    ΤΑΥΤ. ΠΛΗΡΩΜΗΣ:
                                </span>
                                <span style={{textAlign: "right", wordBreak: "break-all", padding: "2px 0 2px 2px"}}>
                                    {templateData.TRANSACTION_DETAILS[0].transactionId}
                                </span>
                            </div>}
                    </div>}
                    <div style={{borderTop: "thin dashed black", marginBottom: "8px"}}></div>
                <section>
                    <table className="totals_per_vat">
                        <tbody>
                        <tr>
                            <th colSpan="4" style={{textAlign: "left"}}>Είδος</th>
                            <th style={{textAlign: "center"}}>Ποσ.</th>
                            <th colSpan="1" style={{textAlign: "center"}}>Φ.Π.Α</th>
                            <th style={{textAlign: "center"}}>Αξία</th>
                        </tr>
                        {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[0].length > 0 && templateData.PRODUCT_DETAILS[0].map((product) => (
                            <tr>
                                <td colSpan="4" className="desc">{product.PRODUCT_DESCRIPTION}</td>
                                <td style={{textAlign: "center"}} className="tdtable">{product.QTY}</td>
                                <td colSpan="1" style={{textAlign: "center"}}
                                    className="tdtable">{product.VAT} ({product.VAT_ID})
                                </td>
                                <td style={{textAlign: "center"}} className="tdtable">{product.TOTAL}</td>
                            </tr>))}
                        </tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                    <table className="totals_per_vat">
                        <tbody>
                        <tr>
                            <td align="left" colSpan="1">
                                ΑΞΙΑ ΠΡΟ ΕΚΠΤ.
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_WITHOUT_DISCOUNT}
                            </td>
                        </tr>
                        <tr>
                            <td align="left" colSpan="1">
                                ΕΚΠΤΩΣΗ
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_DISCOUNT}
                            </td>
                        </tr>
                        <tr>
                            <td align="left" colSpan="1">
                                ΚΑΘΑΡΗ ΑΞΙΑ
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_NOVAT}
                            </td>
                        </tr>
                        <tr>
                            <td align="left">
                                ΑΞΙΑ Φ.Π.Α.
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_VAT}
                            </td>
                        </tr>
                        {(templateData.TOTAL_DEDUCTIONS && parseFloat(templateData.TOTAL_DEDUCTIONS) > 0) && <tr>
                            <td align="left">
                                ΚΡΑΤΗΣΕΙΣ
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_DEDUCTIONS}
                            </td>
                        </tr>}
                        {(templateData.TOTAL_WITHHOLD && parseFloat(templateData.TOTAL_WITHHOLD) > 0) && <tr>
                            <td align="left">
                                ΠΑΡΑΚΡΑΤΗΣΕΙΣ
                                <br/>
                                {templateData.WITHHOLD_CATEGORY}
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_WITHHOLD}
                            </td>
                        </tr>}
                        {(templateData.TOTAL_FEES && parseFloat(templateData.TOTAL_FEES) > 0) && <tr>
                            <td align="left">
                                ΤΕΛΗ
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_FEES}
                                <br/>
                                {templateData.FEES_CATEGORY}
                            </td>
                        </tr>}
                        {(templateData.TOTAL_STAMP_DUTY && parseFloat(templateData.TOTAL_STAMP_DUTY) > 0) && <tr>
                            <td align="left">
                                ΧΑΡΤΟΣΗΜΟ
                                <br/>
                                {templateData.STAMP_CATEGORY}
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_STAMP_DUTY}
                            </td>
                        </tr>}
                        {(templateData.TOTAL_OTHER_TAXES && parseFloat(templateData.TOTAL_OTHER_TAXES) > 0) && <tr>
                            <td align="left">
                                ΛΟΙΠΟΙ ΦΟΡΟΙ
                                <br/>
                                {templateData.OTHER_TAX_CATEGORY}
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.TOTAL_OTHER_TAXES}
                            </td>
                        </tr>}
                        <tr>
                            <td align="left">
                                ΑΞΙΑ ΜΕ Φ.Π.Α.
                            </td>
                            <td></td>
                            <td align="right">
                                {templateData.PRICE_WITHVAT}
                            </td>
                        </tr>


                        <tr className="total">
                            <td colSpan="1" align="left">
                                ΠΛΗΡΩΤΕΟ ΠΟΣΟ:
                            </td>
                            <td></td>
                            <td align="right" colSpan="1">
                                {templateData.TOTAL_PRICE}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <table className="totals_per_vat">
                        <tbody>
                        <tr>
                            <th className={"total_per_vat_text"}>Συντελεστής ΦΠΑ</th>
                            <th className={"total_per_vat_text"}>Καθαρή Αξία</th>
                            <th className={"total_per_vat_text"}>Αξία ΦΠΑ</th>
                            <th className={"total_per_vat_text"}>Τελική Αξία</th>
                        </tr>
                        {!isEmpty(templateData.VAT_TABLE) && templateData.VAT_TABLE.map((el) => (
                            <tr>
                                <td className="total_per_vat_text">{el.vatPercentage}</td>
                                <td className="total_per_vat_text">{el.amountNoVat}</td>
                                <td className="total_per_vat_text">{el.vatAmount}</td>
                                <td className="total_per_vat_text">{el.totalAmount}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="cont_notation">
                        <span className="bold">ΠΑΡΑΤΗΡΗΣΕΙΣ :</span>
                        <div className="cont_notation_inner">
                            {templateData.INVOICE_NOTES &&
                                <span className="notes">{sanitizeHTML(templateData.INVOICE_NOTES)}</span>}
                        </div>
                    </div>
                </section>

                {templateData.ENABLE_PEPPOL && <section>
                        <div style={{borderTop: "thin dashed black", marginBottom: "8px"}}></div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Αρ. Τιμολογίου:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.PEPPOL?.DOCUMENT_REFERENCE}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Α.Δ.Α.:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.PEPPOL?.PROJECT_REFERENCE}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Αναθέτουσα Αρχή:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.PEPPOL?.PARTY_IDENTIFICATION}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Α.Δ.Α.Μ.:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.PEPPOL?.CONTRACT_DOCUMENT_REFERENCE}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Στ. Αναφ. Αγοραστή:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.PEPPOL?.BUYER_REFERENCE}
                            </span>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span style={{textAlign: "left"}}>
                                Ημ/νία δρομόλογησης:
                            </span>
                            <span style={{textAlign: "right"}}>
                                {templateData.PEPPOL?.DUE_DATE}
                            </span>
                        </div>
                        <div style={{borderTop: "thin dashed black", marginBottom: "8px"}}></div>
                </section>}
                <div style={{textAlign: "center", fontSize: "8pt"}}>
                <img style={{margin: 0}} className="qr"
                         src={templateData.QR_CODE}
                         alt="QR Code"/>
                    <div style={{
                        // marginTop: "-10px",
                        paddingRight: "4mm",
                        wordBreak: "break-all",
                        textAlign: "center"
                    }}>Mark:{templateData.MARK}
                    </div>
                    <div style={{
                        paddingRight: "2mm",
                        wordBreak: "break-all",
                        textAlign: "left"
                    }}>Σ/A: {templateData.AUTHCODE}
                    </div>
                    <div style={{paddingRight: "2mm", wordBreak: "break-all", textAlign: "left"}}>
                        Μοναδικός Κωδικός: {templateData.UID}
                    </div>
                </div>
                <br/>
                <div style={{textAlign: "center"}}>Provided by Primer Software</div>
                <div style={{textAlign: "center"}}>www.primer.gr/mydatasearch</div>
                <div style={{textAlign: "center"}}>Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer
                    MyData_V1_21012021
                </div>
                <br/>
            </div>
        </React.Fragment>
    )
})

export default ThermalPrint;