import React from "react";
import {browserName} from 'react-device-detect';
import {epayLogoBase64, vivaLogoBase64} from "./logos";
import {isEmpty, sanitizeHTML} from "../../Helpers/Helpers";

const InvoicePrintTemplate = React.forwardRef((props, ref) => {
    if(browserName !== "Safari"){
        require ('./invoicePrint.css');
    } else {
        require ('./invoicePrint-safari.css');
    }
    let templateData = [];
    if (isEmpty(props.data)) return (<div></div>);
    templateData = props.data;

    templateData.TOO_MANY_CUSTOMER_DETAILS = templateData.TOO_MANY_CUSTOMER_DETAILS ?? false;
    let showWaterMark = templateData.TEST_ENVIRONMENT;

    const invSpecialTypeHeight = templateData.INVOICE_SPECIAL_TYPE ? 8 : 0;

    const waterMarkStyle = {
        position: "absolute",
        transform: "rotate(-45deg)",
        top: "30%",
        fontSize: "222px",
        margin: "0 0 -250px 0",
        color: "#cdcdcd",
        textTransform: "uppercase",
        opacity: "0.7",
    }

    const skinBoldStyle = {
        color: "#555",
        fontWeight: "bold",
        lineHeight: "1",
    }

    const customerInformationStyle = {
        fontSize: "12px",
        textDecoration: "underline",
        color: "#555",
        fontWeight: "bold",
        textAlign: "center",
    }

    const informationCustomerIdStyle = {
        width: "24%",
        color: "#555",
        fontWeight: "bold",
        lineHeight: 1,
    }
    const informationCustomerIdStyle2 = {
        width: "40%",
        color: "#555",
        fontWeight: "bold",
        lineHeight: 1,
    }

    const invoiceBoxStyle = {
        fontSize: "14px",
        fontFamily: "Arial",
        color: "#555",
        width: "100%",
        height: "100%",
    }

    const totalContainerStyle = {
        border: "1px solid transparent",
        borderRadius: "14px",
        overflow: "visible",
    }

    const totalContainerTotalsStyle = {
        border: "1px solid #ddd",
        boxShadow: "0 0 10px rgba(0, 0, 0, .15)",
        fontSize: "9px",
        backgroundColor: "#555",
    }

    const totalPaymentStyleTd = {
        textAlign: "right",
        marginRight: "20px",
        backgroundColor: "white",
    }

    const totalPaymentPStyle = {
        padding: "4px",
        margin: "4px",
        fontSize: "14px",
    }

    const totalPaymentStyle = {
        fontWeight: "700",
        fontSize: "14px",
    }

    const invoiceBoxTableTdStyle = {
        padding: "1px",
        border: "unset",
        lineHeight: 1
    }

    const logoContainerStyle = {
        marginTop: "10px",
        fontSize: "30px",
        width: "35%",
        // maxWidth: "35%",
        color: "#333",
        border: "none",
    }

    const totalTdBlock = {
        width: "35%",
        maxWidth: "35%",
        verticalAlign: "bottom"
    }

    const totalTdBlockTotalsTable = {
        width: "100%",
        borderSpacing: 0,
        borderCollapse: "collapse",
        border: 0,
    }

    const totalContainerHeadingStyle = {
        backgroundColor: "#555",
        color: "white",
    }

    const totalContainerHeadingPStyle = {
        padding: parseFloat(templateData.TOTAL_WITHHOLD) > 0 || parseFloat(templateData.TOTAL_DEDUCTIONS) > 0 ? "2px 0" : "5px 0",
        margin: "1px",
        fontSize: "14px",
        maxWidth: "max-content",
        lineHeight: parseFloat(templateData.TOTAL_TAXES_AMOUNT) > 0 ? 1.3 : "unset",
    }

    const mainInfoTableTr = {
        fontWeight: "700",
        textAlign: "center",
    }

    const productsStyle = {
        border: "1px solid black",
        textAlign: "center",
        fontSize: "14px",
    }

    const productsStyleTdStyle = {
        fontSize: "14px",
        textAlign: "center",
        // maxWidth: "270px",
        height: "31px",
        overflow: "hidden",
        borderRight: "1px solid black",
        lineHeight: templateData.ENABLE_PEPPOL ? 1.15 : 1,
    }

    const mainInfoTableHeadingPStyle = {
        margin: "2px",
        fontSize: "13px",
        lineHeight: 1,
    }

    const productContainerStyle = {
        marginTop: "2px",
        border: "2px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        height: templateData.ENABLE_PEPPOL ? "410px" : "420px",
        maxWidth: "754px",
        padding: "2px",
    }
    const productContainerStyleLastPage = {
        marginTop: "2px",
        border: "2px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        height: templateData.ENABLE_PEPPOL ? "410px" : "420px",
        maxWidth: "754px",
        padding: "2px",
    }

    const productTableHeadingStyle = {
        backgroundColor: "#555",
        color: "white",
        border: "1px solid black"
    }

    const headingStyle = {
        backgroundColor: "#555",
        color: "white",
        border: "1px solid #ddd",
        fontWeight: "700",
        textAlign: "center",
    }

    const headingTdStyle = {
        margin: "4px",
        borderTop: "unset",
        borderBottom: "unset",
        borderRight: "2px solid #fff",
        borderLeft: "2px solid #fff",
    }

    const mainInfoStyle = {
        border: "1px solid #555",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "3px 3px 0 3px",
    }

    const footerContainerStyle = {
        width: "754px",
        maxWidth: "754px",
        margin: "3px auto auto auto",
        paddingBottom: "10",
    }

    const topTableContainerStyle = {
        display: "block",
        maxHeight: "120px",
        overflowY: "clip",
    }

    const topTableStyle = {
        width: "100%",
        textAlign: "left",
    }

    const headerTableStyle = {
        width: "100%",
        maxWidth: "754px",
        textAlign: "left",
    }

    const issuerTdStyle = {
        verticalAlign: "top",
        padding: "2px 0 2px 10px",
        textAlign: "right",
    }

    const issuerContainerStyle = {
        textAlign: "center",
        border: "none",
        lineHeight: 1.2,
        verticalAlign: "top",
    }

    const IssuerName = {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerSubjectField = {
        fontWeight: "normal",
        fontStyle: "italic",
        fontSize: "14px",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerAddressStyle = {
        fontSize: "12px",
        color: "#555",
        lineHeight: 1.2,
    }

    const issuerContainerPStyle = {
        margin: "0px",
        fontSize: "14px",
        lineHeight: 1.2,
    }


    const informationTableStyle = {
        marginTop: "2px",
        width: "100%",
        minWidth: "754px",
        wordBreak: "break-all"
    }

    const quantityHeadTd = {
        textAlign: "center",
        borderRight: "1px solid #ddd",
    }

    const descriptionHeadTd = {
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "38%",
    }

    const priceHeadTdStyle = {
        textAlign: "center",
        borderRight: "1px solid black",
        width: "8%",
    }
    const vatPerHeadTdStyle = {
        textAlign: "center",
        borderRight: "1px solid #ddd",
        width: "4%",
    }

    const blankRowBorderedTdStyle = {
        borderTop: "1px solid white",
        backgroundColor: "white",
        maxHeight: "2px",
        height: "2px",
        lineHeight: "2px",
    }

    const infoValueStyle = {
        color: "#555",
        fontWeight: "normal",
        lineHeight: 1,
    }

    const contNotationStyle = {
        border: "1px solid #555",
        padding: "8px",
        borderRadius: "8px",
        fontSize: "13px",
        overflow: "hidden",
        marginTop: "5px",
        overflowWrap: "break-word",
    }

    const contSignsStyle = {
        border: "1px solid #555",
        borderRadius: "8px",
        margin: "5px auto 0 auto",
        boxSizing: "border-box",
        textAlign: "center",
        width: "150px",
        height: "150px",
        overflow: "hidden",
        padding: "10px",
    }

    const qrImgStyle = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    }

    const hideQrCodeStyle = {
        borderRadius: "8px",
        margin: "10px auto 0 auto",
        boxSizing: "border-box",
        textAlign: "center",
        width: "160px",
        height: "160px",
        overflow: "hidden",
        padding: "5px 0",
    }

    const footerLeftTdStyle = {
        verticalAlign: "bottom",
        width: "42%",
        maxWidth: "42%",
        height: templateData.TRANSMISSION_FAILURE ? `${320 - invSpecialTypeHeight}px` : `${330 - invSpecialTypeHeight}px`,
    }

    const footerMiddleTdStyle = {
        verticalAlign: "bottom",
        height: templateData.TRANSMISSION_FAILURE ? `${320 - invSpecialTypeHeight}px` : `${330 - invSpecialTypeHeight}px`,
    }

    const perVatTotalsContainerStyle = {
        border: "1px solid #555",
        borderRadius: "8px",
        overflow: "hidden",
        overflowWrap: "anywhere",
        marginTop: "5px",
        padding: "5px"
    }

    const totalsPerVatThStyle = {
        width: "10%",
        color: "#555",
        height: "5%",
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1,
        textAlign: "center",
    }

    const totalsPerVatValueStyle = {
        textAlign: "center",
    }

    const countTotalsContainerStyle = {
        padding: "4px",
        border: "4px solid #555",
        borderRadius: "8px",
        minHeight: "16px",
        overflow: "hidden",
        marginBottom: "4px"
    }

    const countTotalProdsStyle = {
        fontSize: "14px",
        fontWeight: "bold",
    }

    const finalPricePStyle = {
        fontWeight: "bold",
        fontSize: "14px",
        padding: "4px",
        margin: "4px",
    }

    const informationTdStyle = {
        fontSize: "14px",
        border: 'none',
        padding: "0",
        width: "50%",
    }

    const informationLeftStyle = {
        border: "1px solid #555",
        height: templateData.TOO_MANY_CUSTOMER_DETAILS ? "180px" : "150px",
        marginRight: "1.84px",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "1px",
    }

    const informationMiddleStyle = {
        border: "1px solid #555",
        height: templateData.TOO_MANY_CUSTOMER_DETAILS ? "180px" : "150px",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "1px",
    }

    const informationRightStyle = {
        border: "1px solid #555",
        height: templateData.TOO_MANY_CUSTOMER_DETAILS ? "180px" : "150px",
        marginLeft: "1.84px",
        borderRadius: "4px",
        overflow: "hidden",
        padding: "1px",
    }

    const balanceContainer = {
        width: "150px",
        border: "1px solid #555",
        borderRadius: "8px",
        margin: "1px auto",
        padding: "8px",
        fontWeight: "bold",
        textAlign: "center",
    }

    const sellerDetailsStyle = {
        color: "#555",
        fontWeight: "bold",
        fontSize: "14px",
        textDecoration: "underline",
        textAlign: "center",
    }

    const totalBoxTd2 = {
        textAlign: "right",
        backgroundColor: "white",
    }

    const totalsValueStyle = {
        padding: "1px",
        margin: "1px",
        fontSize: "15px",
        lineHeight: "16px",
        textAlign: "right",
    }

    const invoiceTableValues = {
        fontSize: "12px",
    }

    const descriptionValues = {
        fontSize: "12px",
    }

    const senderSignStyle = {
        left: "0px"
    }
    const marginzero = {
        margin: "0",
        overflowX: "auto",
        overflowY: "clip",
    }
    const transactionDetailsBlock = {
        border: "1px solid #555",
        padding: "4px",
        borderRadius: "8px",
        fontSize: "13px",
        overflow: "hidden",
        overflowWrap: "break-word",
        textAlign: "center",
        fontWeight: "500",
    }
    const myDataInfo = {
        position: "absolute",
        bottom: "2px",
        left: "5px",
        fontSize: "12px",
        lineHeight: 1
    }

    const totalPagesA = templateData.TOTAL_PAGES !== undefined ? templateData.TOTAL_PAGES : [];

    return (
        <React.Fragment>
            <div style={marginzero} className={"a4-print-container"} ref={ref}>
                {totalPagesA.length > 0 && totalPagesA.map((currentPage1, index) => (
                    <div className={"a4-page-container"}>
                        <div id={"page" + (index + 1)} className="a4-sales-print-template-show">
                            <div style={invoiceBoxStyle}>
                                {showWaterMark && <div style={waterMarkStyle}>ΑΚΥΡΟ</div>}
                                <div className="a4-top_table">
                                    <span style={{position: "absolute", bottom: "2px", right: "8px"}}>Σελίδα: {index + 1} από {totalPagesA.length}</span>
                                    <div style={topTableContainerStyle}>
                                        <table style={topTableStyle}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <table style={headerTableStyle}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={logoContainerStyle}>
                                                            <div>
                                                            {templateData.ISSUER_LOGO && templateData.ISSUER_LOGO !== "" &&
                                                                <img alt="Company_Logo" src={templateData.ISSUER_LOGO}></img>}
                                                            </div>
                                                        </td>
                                                        <td style={issuerTdStyle}>
                                                            <div style={issuerContainerStyle}>
                                                            {templateData.ISSUER_NAME && templateData.ISSUER_NAME !== "" &&
                                                                <span style={IssuerName}>{templateData.ISSUER_NAME}</span>}
                                                            {templateData.ISSUER_SMALL_NAME && templateData.ISSUER_SMALL_NAME !== "" &&
                                                                <p style={issuerContainerPStyle}>
                                                                    <span style={issuerSubjectField}>{templateData.ISSUER_SMALL_NAME}</span>
                                                                </p>}
                                                            {templateData.ISSUER_SUBJECTFIELD && templateData.ISSUER_SUBJECTFIELD !== "" &&
                                                                <p style={issuerContainerPStyle}>
                                                                    <span style={issuerSubjectField}>{templateData.ISSUER_SUBJECTFIELD}</span>
                                                                </p>}
                                                            {templateData.ISSUER_ADDRESS && templateData.ISSUER_ADDRESS !== "" &&
                                                                <p style={issuerContainerPStyle}>
                                                                    <span style={issuerAddressStyle}>{templateData.BRANCH !== "" && templateData.BRANCH !== "Κεντρικό" ? "Υποκατάστημα: " : ""}{templateData.ISSUER_ADDRESS}, {templateData.ISSUER_CITY}, {templateData.ISSUER_TK}, ΤΗΛ: {templateData.ISSUER_PHONE}</span>
                                                                </p>}
                                                            {templateData.ISSUER_VAT && templateData.ISSUER_VAT !== "" &&
                                                                <p style={issuerContainerPStyle}>
                                                                    <span style={issuerAddressStyle}>ΑΦΜ: {templateData.ISSUER_VAT}, ΔΟΥ: {templateData.ISSUER_DOY}, {!isEmpty(templateData.ISSUER_GEMH) && `ΓΕΜΗ: ${templateData.ISSUER_GEMH}, `} EMAIL: {templateData.ISSUER_EMAIL}</span>
                                                                </p>}
                                                            {!isEmpty(templateData.ISSUER_WEBPAGE) && (
                                                                <p style={issuerContainerPStyle}>
                                                                    <span style={issuerAddressStyle}>Ηλεκ. Ιστοσελίδα: {templateData.ISSUER_WEBPAGE}</span>
                                                                </p>
                                                            )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div style={mainInfoStyle}>
                                        <table style={{width: "100%"}}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr style={headingStyle}>
                                                            <td style={headingTdStyle}>
                                                                <p style={mainInfoTableHeadingPStyle}>ΕΙΔΟΣ ΠΑΡΑΣΤΑΤΙΚΟΥ</p>
                                                            </td>
                                                            <td style={headingTdStyle}>
                                                                <p style={mainInfoTableHeadingPStyle}>ΣΕΙΡΑ</p>
                                                            </td>
                                                            <td style={headingTdStyle}>
                                                                <p style={mainInfoTableHeadingPStyle}>ΑΡΙΘΜΟΣ</p>
                                                            </td>
                                                            <td style={headingTdStyle}>
                                                                <p style={mainInfoTableHeadingPStyle}>ΗΜΕΡ/ΝΙΑ</p>
                                                            </td>
                                                            <td style={headingTdStyle}>
                                                                <p style={mainInfoTableHeadingPStyle}>ΩΡΑ</p>
                                                            </td>
                                                        </tr>
                                                        <tr style={mainInfoTableTr}>
                                                            <td style={invoiceBoxTableTdStyle}>
                                                                <span style={invoiceTableValues}>{templateData.INVOICE_TYPE ? templateData.INVOICE_TYPE : '-'}</span>
                                                                {templateData.INVOICE_SPECIAL_TYPE && <span style={{...invoiceTableValues, display: "block", fontSize: "8px", lineHeight: 1}}>{templateData.INVOICE_SPECIAL_TYPE}</span>}
                                                            </td>
                                                            <td style={invoiceBoxTableTdStyle}>
                                                                <span style={invoiceTableValues}>{templateData.SERIES ? templateData.SERIES : '-'}</span>
                                                            </td>
                                                            <td style={invoiceBoxTableTdStyle}>
                                                                <span style={invoiceTableValues}>{templateData.NUMBER ? templateData.NUMBER : '-'}</span>
                                                            </td>
                                                            <td style={invoiceBoxTableTdStyle}>
                                                                <span style={invoiceTableValues}>{templateData.INVOICE_DATE ? templateData.INVOICE_DATE : '-'}</span>
                                                            </td>
                                                            <td style={invoiceBoxTableTdStyle}>
                                                                <span style={invoiceTableValues}>{templateData.INVOICE_TIME ? templateData.INVOICE_TIME : '-'}</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <table style={informationTableStyle} className="a4-information_table">
                                        <tbody>
                                        <tr>
                                            <td style={informationTdStyle}>
                                                <div style={informationLeftStyle}>
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="2" style={customerInformationStyle}>
                                                                <span>ΣΤΟΙΧΕΙΑ ΠΕΛΑΤΗ</span>
                                                            </td>
                                                        </tr>
                                                        {
                                                            templateData.CUSTOMER_CODE !== '' && templateData.CUSTOMER_CODE &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle}>
                                                                    <span style={descriptionValues}>ΚΩΔΙΚΟΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.CUSTOMER_CODE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_NAME !== '' && templateData.CUSTOMER_NAME &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle}>
                                                                    <span style={descriptionValues}>ΟΝΟΜΑ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.CUSTOMER_NAME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_COMPANY !== '' && templateData.CUSTOMER_COMPANY &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle}>
                                                                    <span style={descriptionValues}>ΕΠΩΝΥΜΙΑ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.CUSTOMER_COMPANY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_ACTIVITY !== '' && templateData.CUSTOMER_ACTIVITY &&
                                                            <tr>
                                                                <td style={skinBoldStyle}><span
                                                                    style={descriptionValues}>ΕΠΑΓΓΕΛΜΑ:</span></td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.CUSTOMER_ACTIVITY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_EMAIL !== '' && templateData.CUSTOMER_EMAIL &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>EMAIL:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.CUSTOMER_EMAIL}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_PHONE !== '' && templateData.CUSTOMER_PHONE &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΤΗΛΕΦΩΝΟ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.CUSTOMER_PHONE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_VAT !== '' && templateData.CUSTOMER_VAT &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>Α.Φ.Μ.:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.CUSTOMER_VAT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CUSTOMER_DOY !== '' && templateData.CUSTOMER_DOY &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>Δ.Ο.Υ.:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.CUSTOMER_DOY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            !isEmpty(templateData.CUSTOMER_ADDRESS) !== '' &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΔΙΕΥΘΥΝΣΗ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.CUSTOMER_ADDRESS}, </span>
                                                                    {!isEmpty(templateData.CUSTOMER_CITY) &&
                                                                        <span style={descriptionValues}>{templateData.CUSTOMER_CITY}, </span>
                                                                    }
                                                                    {!isEmpty(templateData.CUSTOMER_ZIP) &&
                                                                        <span style={descriptionValues}>{templateData.CUSTOMER_ZIP}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                            <td className="d-none" style={informationTdStyle}>
                                                <div style={informationMiddleStyle}>
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="2" style={sellerDetailsStyle}>
                                                                <span>ΣΤΟΙΧΕΙΑ ΠΩΛΗΤΗ</span>
                                                            </td>
                                                        </tr>
                                                        {
                                                            templateData.SALER_NAME !== '' && templateData.SALER_NAME &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΟΝΟΜΑ ΠΩΛΗΤΗ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.SALER_NAME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_COMPANY_NAME !== '' && templateData.SALER_COMPANY_NAME &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span
                                                                        style={descriptionValues}>ΕΜΠΟΡΙΚΗ ΟΝΟΜΑΣΙΑ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.SALER_COMPANY_NAME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_EMAIL !== '' && templateData.SALER_EMAIL &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>EMAIL:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.SALER_EMAIL}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_GEMH !== '' && templateData.SALER_GEMH &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΓΕΜΗ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.SALER_GEMH}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SALER_VAT !== '' && templateData.SALER_VAT &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΑΦΜ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.SALER_VAT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.TAX_REPRESENTATIVE !== '' && templateData.TAX_REPRESENTATIVE &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΟΝΟΜΑ ΦΟΡΟΛΟΓΙΚΟΥ ΑΝΤΙΠΡΩΣΟΠΟΥ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.TAX_REPRESENTATIVE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.TAX_REPRESENTATIVE_VAT !== '' && templateData.TAX_REPRESENTATIVE_VAT &&
                                                            <tr>
                                                                <td style={skinBoldStyle}>
                                                                    <span style={descriptionValues}>ΑΦΜ ΦΟΡΟΛΟΓΙΚΟΥ ΑΝΤΙΠΡΩΣΟΠΟΥ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.TAX_REPRESENTATIVE_VAT}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                            <td style={informationTdStyle} className="a4-information_td_left">
                                                <div style={informationRightStyle}>
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan="2" style={customerInformationStyle}>
                                                                <span>ΛΟΙΠΑ ΣΤΟΙΧΕΙΑ</span>
                                                            </td>
                                                        </tr>
                                                        {templateData.LOCATION_NAME !== '' && templateData.LOCATION_NAME &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>ΤΟΠΟΣ ΑΠΟΣΤΟΛΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.LOCATION_NAME}</span>
                                                                </td>
                                                            </tr>}
                                                        {
                                                            templateData.LOCATION_ADDRESS !== '' && templateData.LOCATION_ADDRESS &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>ΔΙΕ/ΝΣΗ ΠΑΡΑΔΟΣΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.LOCATION_ADDRESS}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.LOCATION_CITY !== '' && templateData.LOCATION_CITY &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>ΠΟΛΗ ΠΑΡΑΔΟΣΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.LOCATION_CITY}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.LOCATION_ZIP !== '' && templateData.LOCATION_ZIP &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>Τ.Κ. ΠΑΡΑΔΟΣΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.LOCATION_ZIP}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.PAYMENT_METHOD !== '' && templateData.PAYMENT_METHOD &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.PAYMENT_METHOD}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.PURPOSE !== '' && templateData.PURPOSE &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>ΣΚΟΠΟΣ ΔΙΑΚΙΝΗΣΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.PURPOSE}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.SELF_PRICING !== '' && templateData.SELF_PRICING &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΑΥΤΟΤΙΜΟΛΟΓΗΣΗ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.SELF_PRICING}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.CORRELATION !== '' && templateData.CORRELATION &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΣΧΕΤΙΚΑ ΠΑΡΑΣΤΑΤΙΚΑ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span style={descriptionValues}>{templateData.CORRELATION}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.DISPATCH_TIME !== '' && templateData.DISPATCH_TIME &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span
                                                                        style={descriptionValues}>ΕΝΑΡΞΗ ΠΑΡΑΔΟΣΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.DISPATCH_TIME}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.VEHICLE_NUMBER !== '' && templateData.VEHICLE_NUMBER &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΑΡ. ΟΧΗΜΑΤΟΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.VEHICLE_NUMBER}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.LOADING_ADDRESS !== '' && templateData.LOADING_ADDRESS &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΤΟΠΟΣ ΦΟΡΤΩΣΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.LOADING_ADDRESS}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.DELIVERY_ADDRESS !== '' && templateData.DELIVERY_ADDRESS &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΤΟΠΟΣ ΑΠΟΣΤΟΛΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.DELIVERY_ADDRESS}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.START_SHIPPING_BRANCH !== '' && templateData.START_SHIPPING_BRANCH &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΚΑΤ/ΜΑ ΑΠΟΣΤΟΛΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.START_SHIPPING_BRANCH}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.COMPLETE_SHIPPING_ADDRESS !== '' && templateData.COMPLETE_SHIPPING_ADDRESS &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΚΑΤ/ΜΑ ΠΑΡΑΛΑΒΗΣ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.COMPLETE_SHIPPING_ADDRESS}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        {
                                                            templateData.TABLE_AA !== '' && templateData.TABLE_AA &&
                                                            <tr>
                                                                <td style={informationCustomerIdStyle2}>
                                                                    <span style={descriptionValues}>ΑΡ. ΤΡΑΠΕΖΙΟΥ:</span>
                                                                </td>
                                                                <td style={infoValueStyle}>
                                                                    <span
                                                                        style={descriptionValues}>{templateData.TABLE_AA}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={index + 1 === totalPagesA.length ? productContainerStyleLastPage : productContainerStyle}>
                                    <table style={{width: "100%"}}>
                                        <tbody>
                                        <tr style={productTableHeadingStyle} key={Math.random()}>
                                            <td style={quantityHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΚΩΔΙΚΟΣ</p></td>
                                            <td style={descriptionHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΠΕΡΙΓΡΑΦΗ</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΠΟΣ</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto", width:"min-content"}}>ΤΙΜΗ ΜΟΝΑΔΑΣ</p></td>
                                            <td style={vatPerHeadTdStyle}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΦΠΑ %</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΕΚΠΤΩΣΗ</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "11px", lineHeight: "1", margin: "2px auto",}}>ΤΙΜΗ <span style={{whiteSpace: "nowrap"}}>ΠΡΟ ΦΠΑ</span></p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΑΞΙΑ ΦΠΑ</p></td>
                                            <td style={quantityHeadTd}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΦΟΡΟΙ/ΤΕΛΗ</p></td>
                                            <td style={priceHeadTdStyle}><p style={{fontSize: "12px", lineHeight: "1", margin: "2px auto",}}>ΤΕΛΙΚΗ ΑΞΙΑ</p></td>
                                        </tr>
                                        {templateData.PRODUCT_DETAILS && templateData.PRODUCT_DETAILS[index]?.length > 0 && templateData.PRODUCT_DETAILS[index].map((product) =>
                                            (
                                                <tr style={productsStyle} key={Math.random()}>
                                                    <td style={productsStyleTdStyle}><span>{product.CODE}</span></td>
                                                    <td style={productsStyleTdStyle} className={templateData.ENABLE_PEPPOL ? "" : "product-description"}>
                                                        <span>{sanitizeHTML(product.PRODUCT_DESCRIPTION)}</span><br/>
                                                        {product.REC_TYPE ?
                                                        <span style={{fontSize: "8px"}}>{product.REC_TYPE}</span>
                                                            :
                                                        <span style={{fontSize: "8px"}}>{product.VATEXPCAT}</span>}
                                                        {product.FUEL_NAME && <span style={{fontSize: "8px"}}>{product.FUEL_NAME}</span>}
                                                        {templateData.ENABLE_PEPPOL && (
                                                            <div style={{fontSize: "12px"}}>
                                                                <span>Κωδ. Κατηγορίας Φ.Π.Α.: {Number(product.VAT_ID) === 7 ? "Z" : Number(product.VAT_ID) === 8 ? "E" : "S"}</span>
                                                                <br/>
                                                                <span>Επιβαρύνσεις Μειώσεις Τιμής: {Number(product.TAXES_VALUE).toFixed(2)}</span>
                                                                {product.CPV_CODE && (
                                                                    <React.Fragment>
                                                                        <br/>
                                                                        <span>Κωδικός CPV είδους: {product.CPV_CODE}</span>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td style={productsStyleTdStyle}>
                                                        <span>{product.QTY}</span>
                                                        {templateData.ENABLE_PEPPOL && (
                                                            <React.Fragment>
                                                                {isEmpty(product.PEPPOL_MEASUREMENT_UNIT) ? (
                                                                    <React.Fragment>
                                                                        <br/>
                                                                        <span style={{fontSize: "12px"}}><i>H87</i></span>
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <br/>
                                                                        <span style={{fontSize: "12px"}}><i>{product.PEPPOL_MEASUREMENT_UNIT}</i></span>
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                    <td style={productsStyleTdStyle}><span>{product.PRICE_QTY ?? "0.00"}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.VAT}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.DISCOUNT ?? "0.00"}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.PRICE_NOVAT}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.VAT_PRICE}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.OTHER_TAXES}</span></td>
                                                    <td style={productsStyleTdStyle}><span>{product.TOTAL}</span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={footerContainerStyle}>
                                    <table style={{width: "100%"}}>
                                        <tbody>
                                        <tr>
                                            <td style={footerLeftTdStyle}>
                                                {(templateData.ENABLE_PEPPOL) && (
                                                    <React.Fragment>
                                                        <div style={{
                                                            textAlign: "center",
                                                            fontSize: "14",
                                                            marginBottom: "5px",
                                                            color: "white",
                                                            backgroundColor: "#555",
                                                            fontWeight: "bold"
                                                        }}>Πληροφορίες Ηλεκτρονικής Σύμβασης
                                                        </div>
                                                        <div style={transactionDetailsBlock}>
                                                            <table className={"a4-transactionDetailsTable"}
                                                                   style={{width: "100%", tableLayout: "fixed"}}>
                                                                <tbody>
                                                                <tr>
                                                                    <td><strong>ΑΡ. ΤΙΜΟΛΟΓΙΟΥ:</strong></td>
                                                                    <td>{templateData.PEPPOL?.DOCUMENT_REFERENCE}</td>
                                                                </tr>
                                                                {templateData.PEPPOL?.PROJECT_REFERENCE && (
                                                                    <tr>
                                                                        <td><strong>Α.Δ.Α. ή Ενάριθμος:</strong></td>
                                                                        <td>{templateData.PEPPOL?.PROJECT_REFERENCE}</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td><strong>Αναθέτουσα Αρχή:</strong></td>
                                                                    <td>{templateData.PEPPOL?.PARTY_IDENTIFICATION}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Α.Δ.Α.Μ.:</strong></td>
                                                                    <td>{templateData.PEPPOL?.CONTRACT_DOCUMENT_REFERENCE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2}><strong>Στοιχειο Ανφοράς Αγοραστή:</strong> {templateData.PEPPOL?.BUYER_REFERENCE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2}><strong>Ημ/νία δρομόλογησης:</strong> {templateData.PEPPOL?.DUE_DATE}</td>
                                                                </tr>
                                                                {/*<tr>*/}
                                                                {/*    <td colSpan={2}><strong>Ηλεκτρονική Διεύθυνση Πωλητή:</strong> {templateData.PEPPOL?.BT_34_CODE}</td>*/}
                                                                {/*</tr>*/}
                                                                {/*<tr style={{borderTop: "1px solid gray"}}>*/}
                                                                {/*    <td>Κωδικός Τιμολογίου: {templateData.PEPPOL?.BT_3_CODE}</td>*/}
                                                                {/*    <td>Κωδικός Νομίσματος: {templateData.PEPPOL?.BT_5_CODE}</td>*/}
                                                                {/*</tr>*/}
                                                                {/*<tr>*/}
                                                                {/*    <td>Κωδικός Χώρας Πωλητή: {templateData.PEPPOL?.BT_40_CODE}</td>*/}
                                                                {/*    <td>Κωδικός Χώρας Αγοραστή: {templateData.PEPPOL?.BT_55_CODE}</td>*/}
                                                                {/*</tr>*/}
                                                                {/*<tr>*/}
                                                                {/*    <td colSpan={2}>Κωδικός Χώρας Παράδοσης: {templateData.PEPPOL?.BT_80_CODE}</td>*/}
                                                                {/*</tr>*/}
                                                                {/*<tr>*/}
                                                                {/*    <td colSpan={2}>Αναγνωριστικό Προδιαγραφής: <i>{templateData.PEPPOL?.BT_24_CODE}</i></td>*/}
                                                                {/*</tr>*/}
                                                                {/*<tr>*/}
                                                                {/*    <td colSpan={2}>Ηλεκτρονική Διεύθυνση Αγοραστή: {templateData.PEPPOL?.BT_49_CODE}</td>*/}
                                                                {/*</tr>*/}
                                                                {/*{(templateData.PEPPOL.BT_25_CODE) && (*/}
                                                                {/*    <tr>*/}
                                                                {/*        <td colSpan={2}>Αναφορά Προγενέστερου*/}
                                                                {/*            Τιμολογίου: {templateData.PEPPOL.BT_25_CODE}</td>*/}
                                                                {/*    </tr>*/}
                                                                {/*)}*/}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                                {((templateData.TRANSACTION_DETAILS?.length > 0 && index + 1 === totalPagesA.length) && !templateData?.ENABLE_PEPPOL && (
                                                    <React.Fragment>
                                                        <div style={{
                                                            textAlign: "center",
                                                            fontSize: "14",
                                                            marginBottom: "2px",
                                                            color: "white",
                                                            backgroundColor: "#555",
                                                            fontWeight: "bold",
                                                        }}>ΣΤΟΙΧΕΙΑ ΠΛΗΡΩΜΗΣ
                                                        </div>
                                                        <div style={transactionDetailsBlock}>
                                                            {templateData.TRANSACTION_DETAILS.map((transaction, idx) => {
                                                                if (transaction.signature && idx > 0) return;
                                                                return ((transaction.signature) ? (
                                                                        <div style={{borderTop: idx !== 0 ? "1px solid black" : "0", lineHeight: "1"}}>
                                                                            {transaction.isEuronet === "true" && (
                                                                                <React.Fragment>
                                                                                    <img style={{
                                                                                        margin: "0",
                                                                                        width: "20mm"
                                                                                    }} className={"mb-1"}
                                                                                         src={epayLogoBase64}
                                                                                         alt={"ePay Logo"}/>
                                                                                    <br/>
                                                                                    <span style={{fontSize: "12px"}}>EURONET MERCHANT SERVICES A.E. GEMH: 159277401000</span>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {transaction.isEuronet === "viva" && (
                                                                                <React.Fragment>
                                                                                    <img style={{
                                                                                        margin: "0",
                                                                                        width: "15mm"
                                                                                    }} src={vivaLogoBase64}
                                                                                         alt={"Viva Logo"}/>
                                                                                    <br/>
                                                                                </React.Fragment>
                                                                            )}
                                                                            <table className={"a4-transactionDetailsTable"}
                                                                                   style={{
                                                                                       width: "100%",
                                                                                       tableLayout: "fixed",
                                                                                       fontSize: "12px"
                                                                                   }}>
                                                                                <tbody>
                                                                                 <tr>
                                                                                 {transaction.transactionDate && <td>{transaction.transactionDate}</td>}
                                                                                 {transaction.tid && <td>TID/MID: {transaction.tid} / {transaction.merchantId ?? ""}</td>}
                                                                                </tr>
                                                                                {transaction.transactionNumber && <tr>
                                                                                    <td>ΑΡ. ΣΥΝ.: {transaction.transactionNumber}</td>
                                                                                    <td>ΕΓΚΡΙΣΗ: {transaction.authorizationCode}</td>
                                                                                </tr>}
                                                                                <tr>
                                                                                    <td>RRN.: {transaction.rnn ?? "-"}</td>
                                                                                    <td>
                                                                                        ΜΕΣΩ:&nbsp;
                                                                                        {transaction.isEuronet === "true" && `EPAY`}
                                                                                        {transaction.isEuronet === "viva" && `VIVA`}
                                                                                        {!transaction.isEuronet && "-"}
                                                                                    </td>
                                                                                </tr>
                                                                                {transaction.cardType && <tr>
                                                                                    <td colSpan={2}>Κάρτα: {transaction.cardType}</td>
                                                                                </tr>}
                                                                                {transaction.aid && <tr>
                                                                                    <td>AID: {transaction.aid}</td>
                                                                                    {transaction.panCard && <td>{transaction.panCard} - {transaction.finalCode}</td>}
                                                                                </tr>}
                                                                                {transaction.amountTotalString && <tr>
                                                                                    <td>ΠΟΣΟ:</td>
                                                                                    <td>{transaction.amountTotalString}</td>
                                                                                </tr>}
                                                                                {transaction.tipAmountString && <tr>
                                                                                    <td>ΦΙΛΟΔΩΡΗΜΑ:</td>
                                                                                    <td>{transaction.tipAmountString}</td>
                                                                                </tr>}
                                                                                {transaction.transactionId && <tr>
                                                                                    <td colSpan={2}>ΤΑΥΤ. ΠΛΗΡΩΜΗΣ: {transaction.transactionId}</td>
                                                                                </tr>}
                                                                                <tr>
                                                                                    <td>ΥΠΟΓΡΑΦΗ ΠΑΡΟΧΟΥ:</td>
                                                                                    <td style={{wordBreak: "break-all", fontSize: "10px"}}>{transaction.signature}</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{borderTop: idx !== 0 ? "1px solid black" : "0"}}>
                                                                            <table className={"a4-transactionDetailsTable"}
                                                                                   style={{
                                                                                       width: "100%",
                                                                                       tableLayout: "fixed"
                                                                                   }}>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</td>
                                                                                    <td>{transaction.paymentTypeName}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>ΗΜ/ΝΙΑ ΠΛΗΡΩΜΗΣ</td>
                                                                                    <td>{transaction.transactionDate ?? `${templateData.INVOICE_DATE} ${templateData.INVOICE_TIME ?? ""}`}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>ΠΟΣΟ</td>
                                                                                    <td>{transaction.amount}</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    )
                                                                )
                                                            })}
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                                <div style={perVatTotalsContainerStyle}>
                                                    <table style={{width: "100%"}}>
                                                        <tbody>
                                                        <tr>
                                                            <th style={totalsPerVatThStyle}>Συντ. ΦΠΑ</th>
                                                            <th style={totalsPerVatThStyle}>Καθαρή Αξία</th>
                                                            <th style={totalsPerVatThStyle}>Αξία ΦΠΑ</th>
                                                            {/*<th style={totalsPerVatThStyle}>Φόροι/Τέλη</th>*/}
                                                            <th style={totalsPerVatThStyle}>Τελική Αξία</th>
                                                        </tr>
                                                        {!isEmpty(templateData.VAT_TABLE) && index + 1 === totalPagesA.length && templateData.VAT_TABLE.map((el) => {
                                                            return <tr key={Math.random()}>
                                                                <td style={totalsPerVatValueStyle}>{el.vatPercentage}
                                                                    {templateData.ENABLE_PEPPOL && (
                                                                        <span>
                                                                          {(parseFloat(el.vatPercentage) === 0) ? el.TAX_CODE ? "(Z)" : "(E)" : "(S)"}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td style={totalsPerVatValueStyle}>{el.amountNoVat}</td>
                                                                <td style={totalsPerVatValueStyle}>{el.vatAmount}</td>
                                                                {/*<td style={totalsPerVatValueStyle}>{el.OTHER_TAXES}</td>*/}
                                                                <td style={totalsPerVatValueStyle}>{el.totalAmount}</td>
                                                            </tr>
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div style={contNotationStyle}>
                                                    {templateData.VATEXPCAT !== null && templateData.VATEXPCAT?.length > 0 && templateData.VATEXPCAT.map((reason) => {
                                                        return (
                                                            <div className="a4-exception-block">
                                                                <span
                                                                    style={skinBoldStyle}>ΑΠΑΛΛΑΓΗ ΑΠΟ ΤΟ Φ.Π.Α. :</span>
                                                                <div className="cont_notation_inner">
                                                                    <span>{reason.vat} {reason.description} <br/></span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="a4-notes-block">
                                                        <div className="a4-cont_notation_inner">
                                                            <span style={skinBoldStyle}>ΠΑΡΑΤΗΡΗΣΕΙΣ:&nbsp;</span>
                                                            {templateData.INVOICE_NOTES && <span>{sanitizeHTML(templateData.INVOICE_NOTES)}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{...footerMiddleTdStyle, maxWidth: "150px"}}>
                                                {(templateData.OLD_BALANCE && templateData.NEW_BALANCE && !templateData.ENABLE_PEPPOL) &&
                                                    <div style={balanceContainer}>
                                                        <div style={{margin: "auto"}}>Προηγ. Υπόλοιπο</div>
                                                        <div style={{
                                                            margin: "auto",
                                                            fontWeight: "normal"
                                                        }}>{templateData.OLD_BALANCE}</div>
                                                        <div style={{margin: "auto", marginTop: "5px"}}>Νέο Υπόλοιπο
                                                        </div>
                                                        <div style={{
                                                            margin: "auto",
                                                            fontWeight: "normal"
                                                        }}>{templateData.NEW_BALANCE}</div>
                                                    </div>
                                                }
                                                {(() => {
                                                    if (index + 1 === totalPagesA.length && templateData.UID !== "" && templateData.QR_CODE?.length > 0) {
                                                        return (
                                                            <div style={contSignsStyle}>
                                                                <img src={templateData.QR_CODE} style={qrImgStyle} alt="Qr code"/>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (<div style={hideQrCodeStyle}></div>)
                                                    }
                                                })()}
                                            </td>
                                            <td style={totalTdBlock}>
                                                <div style={countTotalsContainerStyle}>
                                                    <span>ΣΥΝΟΛΟ ΠΟΣΟΤΗΤΑΣ: </span>
                                                    {index + 1 === totalPagesA.length && (
                                                        <span
                                                            style={countTotalProdsStyle}> {templateData.TOTAL_QTY ? templateData.TOTAL_QTY : '0'}</span>
                                                    )}
                                                </div>
                                                <div style={totalContainerStyle}>
                                                    <div style={totalContainerTotalsStyle}>
                                                        <table style={totalTdBlockTotalsTable}>
                                                            <tbody>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>ΑΞΙΑ ΠΡΟ ΕΚΠΤ.</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_WITHOUT_DISCOUNT ? templateData.TOTAL_WITHOUT_DISCOUNT : "0.00"}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>ΕΚΠΤΩΣΗ</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_DISCOUNT ? templateData.TOTAL_DISCOUNT : "0.00"}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>ΣΥΝΟΛΟ ΧΩΡΙΣ ΦΠΑ</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_NOVAT ? templateData.TOTAL_NOVAT : "0.00"}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>Φ.Π.Α.</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_VAT ? templateData.TOTAL_VAT : "0.00"}</div>
                                                                </td>
                                                            </tr>
                                                            {(templateData.TOTAL_DEDUCTIONS !== null && parseFloat(templateData.TOTAL_DEDUCTIONS) > 0) && (
                                                                <tr>
                                                                    <td style={totalContainerHeadingStyle}>
                                                                        <p style={totalContainerHeadingPStyle}>ΚΡΑΤΗΣΕΙΣ</p>
                                                                    </td>
                                                                    <td style={totalBoxTd2}>
                                                                        <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_DEDUCTIONS ? templateData.TOTAL_DEDUCTIONS : "0.00"}</div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {(templateData.TOTAL_WITHHOLD !== null && parseFloat(templateData.TOTAL_WITHHOLD) > 0) && (
                                                                <tr>
                                                                    <td style={totalContainerHeadingStyle}>
                                                                        <p style={totalContainerHeadingPStyle}>ΠΑΡΑΚΡΑΤ. ΦΟΡΟΙ</p>
                                                                        <span>{templateData.WITHHOLD_CATEGORY}</span>
                                                                    </td>
                                                                    <td style={totalBoxTd2}>
                                                                        <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_WITHHOLD ? templateData.TOTAL_WITHHOLD : "0.00"}</div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {(templateData.TOTAL_FEES !== null && parseFloat(templateData.TOTAL_FEES) > 0) && (
                                                                <tr>
                                                                    <td style={totalContainerHeadingStyle}>
                                                                        <p style={totalContainerHeadingPStyle}>ΣΥΝΟΛΙΚΑ ΤΕΛΗ</p>
                                                                        <span>{templateData.FEES_CATEGORY}</span>
                                                                    </td>
                                                                    <td style={totalBoxTd2}>
                                                                        <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_FEES ? templateData.TOTAL_FEES : "0.00"}</div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {(templateData.TOTAL_STAMP_DUTY !== null && parseFloat(templateData.TOTAL_STAMP_DUTY) > 0) && (
                                                                <tr>
                                                                    <td style={totalContainerHeadingStyle}>
                                                                        <p style={totalContainerHeadingPStyle}>ΧΑΡΤΟΣΗΜΟ</p>
                                                                        <span>{templateData.STAMP_CATEGORY}</span>
                                                                    </td>
                                                                    <td style={totalBoxTd2}>
                                                                        <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_STAMP_DUTY ? templateData.TOTAL_STAMP_DUTY : "0.00"}</div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            {(templateData.TOTAL_OTHER_TAXES !== null && parseFloat(templateData.TOTAL_OTHER_TAXES) > 0) && (
                                                                <tr>
                                                                    <td style={totalContainerHeadingStyle}>
                                                                        <p style={totalContainerHeadingPStyle}>ΛΟΙΠΟΙ ΦΟΡΟΙ</p>
                                                                        <span>{templateData.OTHER_TAX_CATEGORY}</span>
                                                                    </td>
                                                                    <td style={totalBoxTd2}>
                                                                        <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_OTHER_TAXES ? templateData.TOTAL_OTHER_TAXES : "0.00"}</div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={totalContainerHeadingPStyle}>ΤΕΛΙΚΟ ΣΥΝΟΛΟ</p>
                                                                </td>
                                                                <td style={totalBoxTd2}>
                                                                    <div style={totalsValueStyle}>{index + 1 === totalPagesA.length && templateData.PRICE_WITHVAT ? templateData.PRICE_WITHVAT : "0.00"}</div>
                                                                </td>
                                                            </tr>
                                                            <tr style={blankRowBorderedTdStyle}>
                                                                <td style={totalContainerHeadingStyle}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={totalContainerHeadingStyle}>
                                                                    <p style={finalPricePStyle}>ΠΛΗΡΩΤΕΟ ΠΟΣΟ</p>
                                                                </td>
                                                                <td style={totalPaymentStyleTd}>
                                                                    <p style={totalPaymentPStyle}>
                                                                        <span style={totalPaymentStyle}>{index + 1 === totalPagesA.length && templateData.TOTAL_PRICE ? templateData.TOTAL_PRICE : "0.00"}</span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    {index + 1 === totalPagesA.length && (
                                        <div style={myDataInfo}>
                                            {(templateData.TRANSMISSION_FAILURE && (templateData.TRANSMISSION_FAILURE === "1" || templateData.TRANSMISSION_FAILURE === "2") || (templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                <div>
                                                    <span style={senderSignStyle}>www.primer.gr/search-invoice Provided by Primer Software P.C.</span>
                                                    <br/>
                                                    <span>Άδεια ΥΠΑΗΕΣ: 2021_01_107Primer Software ΙΚΕ_001_ Primer MyData_V1_21012021</span>
                                                </div>
                                            }
                                            {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1") || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "1" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                <span>Απώλεια Διασύνδεσης Οντότητας - Παρόχου - Transmission Failure_1<br/></span>}
                                            {((templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2") || (templateData.TRANSMISSION_FAILURE && templateData.TRANSMISSION_FAILURE === "2" && templateData.AUTHCODE && templateData.AUTHCODE !== "")) &&
                                                <span>Απώλεια Διασύνδεσης Παρόχου - ΑΑΔΕ - Transmission Failure_2<br/></span>}
                                            {templateData.UID && templateData.UID !== "" && templateData.AUTHCODE !== "" &&
                                                <span> UID: {templateData.UID}</span>}
                                            {templateData.MARK && templateData.MARK !== "" &&
                                                <span> Μ.ΑΡ.Κ.: {templateData.MARK}</span>}
                                            {templateData.AUTHCODE && templateData.AUTHCODE !== "" &&
                                                <span> <br/>AUTH CODE: {templateData.AUTHCODE}</span>}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {(index + 1 !== totalPagesA.length && totalPagesA.length !== 1) &&
                            <div className={(index + 1 !== totalPagesA.length && totalPagesA.length !== 1) ? "a4-page-break" : null}></div>
                        }
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
})


export default InvoicePrintTemplate;
